import UserModel from './UserModel'
import DomainModel from './DomainModel'

export default class InstitutionModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.address = data.address
    this.city = data.city
    this.origin = data.origin ? new DomainModel(data.origin) : undefined
    this.company = data.company
    this.is_active = data.is_active
    this.is_trial = data.is_trial
    this.register = data.register
    this.state = data.state
    this.telephone = data.telephone
    this.owner = data.owner ? new UserModel(data.owner) : null
  }
}
