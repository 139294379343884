import { extendObservable } from 'mobx'
import InstitutionConfigModel from '../models/InstitutionConfigModel'
import InstitutionItemConfigModel from '../models/InstitutionItemConfigModel'
import InstitutionConfigRequests from '../services/requests/InstitutionConfigRequests'

class InstitutionConfigStore {
  constructor () {
    extendObservable(this, {
      loading: false,
      config: {},
      singleConfig: {},
      page: {
        count: 0,
        num_pages: 1,
        current_page: 1
      }
    })
  }

  /**
   * Carrega a lista de configurações de integração
   */
  getConfigList (page = 1, limit) {
    this.loading = true
    return InstitutionConfigRequests.getConfigList(page, limit)
      .then(response => {
        if (response) {
          this.config = new InstitutionConfigModel(response)
          this.page = response.page
          this.page.current_page = 1
        }
      })
      .finally(() => { this.loading = false })
  }

  /**
   * Obtém configuração de integração de uma instituição
   * @param {Integer} institution ID da instituição para obter a configuração
   */
  getIndividualConfig (institution) {
    this.loading = true
    return InstitutionConfigRequests.getIndividualConfig(institution)
      .then(response => {
        this.singleConfig = new InstitutionItemConfigModel(response)
        return response
      })
      .finally(() => { this.loading = false })
  }

  toggleIntegrationConfig (institution, activate) {
    this.loading = true
    const config = {
      institution,
      activate
    }
    return InstitutionConfigRequests.update(config)
      .finally(() => { this.loading = false })
  }
}

export default new InstitutionConfigStore()
