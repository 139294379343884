import { themeConfig } from "../Util"

export default class VirtualRoomConfigModel {
  constructor (data) {
    if (!data) return
    this.id = data.id
    this.name = data.name
    this.active = data.active
    this.isDefault = data.is_default
    this.config = new Config(data.config)
  }

  get forumConfig () {
    return this.config.forum
  }
}

/** Classe de configurações da virtualroom */
class Config {
  forum = []

  forum_notify = []

  constructor (data) {
    if (data.forum) {
      this.loadForumConfig(data.forum)
    }
    if (data.forum_notify) {
      this.loadForumNotify(data.forum_notify)
    }
  }

  /** Extrai as chaves do json e cria o array de configurações do forum. */
  loadForumConfig (data) {
    const keys = Object.keys(data)
    for (let i = 0; i < keys.length; i++) {
      this.forum.push(new ForumConfig(keys[i], data[keys[i]]))
    }
  }

  /** Extrai as chaves do json e cria o array de configurações de notificações do forum. */
  loadForumNotify (data) {
    const keys = Object.keys(data)
    for (let i = 0; i < keys.length; i++) {
      this.forum_notify.push(new Notify(keys[i], data[keys[i]]))
    }
  }
}

class Notify {
  constructor (key, active) {
    const values = this.values
    this.active = active
    this.key = key
    this.description = values[key].description
    this.info = values[key].info
  }

  get values () {
    const notify = 'forum_notify'
    const data = {}
    data[`${notify}_01`] = {
      description: `${themeConfig.terms.tutorC} recebe alertas sobre postagens no Fórum?`,
      info: `Quando ${themeConfig.terms.umAluno} fizer um post.`
    }
    data[`${notify}_02`] = {
      description: `${themeConfig.terms.oAlunoIC} recebe alertas sobre comentário marcados com estrela?`,
      info: `Quando ${themeConfig.terms.oTutor} marcar seu comentário com estrela.`
    }
    data[`${notify}_03`] = {
      description: `${themeConfig.terms.oAlunoIC} recebe alertas sobre comentários ou curtidas na sua publicação?`,
      info: 'Quando um colega clicar em curtir ou comentar seu post.'
    }
    data[`${notify}_04`] = {
      description: `${themeConfig.terms.oTutorIC} recebe alertas sobre denúncias?`,
      info: `Quando ${themeConfig.terms.umAluno} clicar sobre DENUNCIA.`
    }
    return data
  }
}

/** Configurações de forum. */
class ForumConfig {
  constructor (key, active) {
    const values = this.values
    this.key = key
    this.active = active
    this.description = values[key].description
    this.info = values[key].info
  }

  get values () {
    const config = 'forum_config'
    const data = {}
    data[`${config}_01`] = {
      description: `É necessário a aprovação da publicação ${themeConfig.terms.doAluno}?`,
      info: `${themeConfig.terms.oTutorIC} deve aprovar o conteúdo postado ${themeConfig.terms.peloAluno}.`
    }
    data[`${config}_02`] = {
      description: 'É possível compartilhar um comentário?',
      info: `${themeConfig.terms.oAlunoIC} pode compartilhar seu comentário no ambiente do Social Learn.`
    }
    data[`${config}_03`] = {
      description: 'Dar destaque nos comentários com estrela?',
      info: `Os comentários marcados com estrela ${themeConfig.terms.peloTutor} devem aparecer nas primeiras posições.`
    }
    return data
  }
}
