import { extendObservable } from 'mobx'
import moment from 'moment'
import 'moment/locale/pt-br'

export default class ForumModel {
  constructor (data) {
    this.id = data.id
    this.active = data.active
    this.classroom = data.class
    this.comment_approval = data.comment_approval
    this.highlight_starred = data.highlight_starred
    this.comments = data.comments
      ? data.comments.map(comment => new Comment(comment, this))
      : []
    this.comments_count = data.comments_count
    this.components = data.components
    this.description = data.description
    this.duration_suggestion = data.duration_suggestion
    this.end_date = data.end_date
    this.group_id = data.group_id
    this.lesson_id = data.lesson_id
    this.lesson_name = data.lesson_name
    this.publisher_created = data.publisher_created
    this.start_date = data.start_date
    this.topic = data.topic
    this.owner = data.owner
    this.status_time = data.status_time
    extendObservable(this, {
      attachments: data.attachments.map(attachment => new Attachment(attachment)),
      attachments_child: []
    })
  }

  removeAttachment = (attachment) => {
    this.attachments = this.attachments.filter(_attachment => _attachment.id !== attachment.id)
    this.attachments_child = this.attachments_child.filter(_attachment => _attachment.id !== attachment.id)
  }

  static getForum (comment) {
    if (!comment) return null
    return comment.parent instanceof ForumModel
      ? comment.parent
      : ForumModel.getForum(comment.parent)
  }

  hasDate () {
    return this.start_date || this.end_date
  }

  toBegin () {
    return this.status_time === 'to_begin'
  }

  inTime () {
    return this.status_time === 'in_time'
  }

  isExpired () {
    return this.status_time === 'expired'
  }

  statusTime () {
    const status = {}
    if (this.isExpired()) {
      const expired_date = moment(new Date(this.end_date).valueOf())
      status.class = 'expired'
      status.message = `Expirado ${expired_date.fromNow()}`
    } else if (this.toBegin()) {
      const begin_date = moment(new Date(this.start_date).valueOf())
      status.class = 'tobegin'
      status.message = `Começa ${begin_date.from()}`
    } else if (this.inTime() && this.end_date) {
      const expired_date = moment(new Date(this.end_date).valueOf())
      status.class = ''
      status.message = `Expira ${expired_date.fromNow()}`
    }
    return status
  }

  getCommentById (id) {
    for (const comment of this.comments) {
      const temp = comment.getCommentById(id)
      if (temp) return temp
    }
    return null
  }
}

// attachments_child
export class Comment {
  constructor (data, parent) {
    this.id = data.id
    this.comments = data.comments
      ? data.comments.map(comment => new Comment(comment, this))
      : []
    this.comments_count = data.comments_count
    this.created = data.created
    this.forum_id = data.forum_id
    this.liked = data.liked
    this.likes = data.likes
    this.owner = data.owner
    this.starred = data.starred
    this.parent = parent
    this.role_comment = data.role_comment
    this.removed = data.removed || false
    this.removed_by = data.removed_by || undefined
    this.date_removed = data.date_removed || undefined
    this.date_reported = data.date_reported || undefined
    this.had_report = data.had_report || false
    this.parent_comment = data.parent_comment
    extendObservable(this, {
      visibleChildren: false,
      edit: false,
      content: data.content,
      reported: data.reported,
      approved: data.approved,
      attachments_child: [],
      attachments: data.attachments.map(attachment => new Attachment(attachment)) || []
    })
  }

  removeAttachment = (attachment) => {
    this.attachments = this.attachments.filter(_attachment => _attachment.id !== attachment.id)
    this.attachments_child = this.attachments_child.filter(_attachment => _attachment.id !== attachment.id)
  }

  getCommentById (id) {
    if (id === this.id) return this
    for (const comment of this.comments) {
      if (comment.id === id) {
        return comment
      }
    }
  }
}
class Attachment {
  constructor (attachment) {
    this.id = attachment.id
    this.name = attachment.name
    this.url = attachment.url
    this.size = attachment.size
    this.created = attachment.created
  }
}
