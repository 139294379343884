// import ClassModel from './ClassModel'
// import DisciplineModel from './DisciplineModel'
// import LessonsGroupModel from './LessonsGroupModel'
import CategoryModel from './CategoryModel'

export default class KnowareaModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.categories = data.categories ? data.categories.map(category => new CategoryModel(category)) : null
    // this.class = new ClassModel(data.class)
    // this.discipline = new DisciplineModel(data.discipline)
    // this.lessons_group = new LessonsGroupModel(data.lessons_group)
  }
}
