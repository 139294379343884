import moment from 'moment'
import History, { Location } from './models/History'
import axios from 'axios'

export const ITEMS_LIST_PER_PAGE = 16 // Quantidade de itens de lista exibidos por pagina
export const ITEMS_BOX_PER_PAGE = 12 // Quantidade de itens de boxes exibidos por pagina
export const ITEMS_BOX_PER_MODAL = 6 // Quantidade de itens de boxes exibidos por modal
export const USERS_PER_ROOM = 75 // Students allowed to enter Jitsi

/**
 * Validação de email
 */
export function validateEmail (email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}

// Timer de atividade.
export function startTimer (minutesTotal, callback, interval = 1000) {
  let timer = minutesTotal
  let setinterval = setInterval(() => {
    const minutes = parseInt(timer / 60, 10)
    const seconds = parseInt(timer % 60, 10)

    callback(minutes, seconds)

    if (--timer < 0) {
      clearInterval(this.interval)
      setinterval = undefined
    }
  }, interval)
  return setinterval
}

/**
 * Gerador de número pseudo aleatório dentro de um dado intervalo
 */
export function randomNumber (min, max) {
  return Math.floor(min + (Math.random() * (max - min)))
}

/**
 *
 * Recebe um parametro e usa a biblioteca moment-js para formatar o valor da data.
 */
export function momentDate (date) {
  return moment(date).format('L')
}

/*
* Transforma uma data em uma string no formato YYYY-MM-DD
*/
export function dateToText (value, reverse = false) {
  const date = new Date(value)
  if (reverse) return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

/*
* Dado uma string válida, retorna uma data.
*/
export function textToDate (texto) {
  return new Date(...texto.split('-').map((item, indice) => item - indice % 2))
}

/**
 *  Transforma valor segundos para HH:MM:SS
*/
export function secondsToTime (seconds) {
  return `${new Date(seconds * 1000).toISOString().substr(11, 8)}`
}

/**
 * Scroll para um ponto determinado na página ( ancora )
 */
export function scrollToSmooth (elementDom, ignoreTopBar = true) {
  let top = elementDom.getBoundingClientRect().top + window.scrollY
  if (ignoreTopBar) {
    top = top - 88
  }
  window.scrollTo({ top: top, behavior: 'smooth' })
}

// embaralha o array informado.
export function shuffleArray (array) {
  array = array.map(arg => arg)
  var temp = []
  var len = array.length
  while (len) {
    temp.push(array.splice(Math.floor(Math.random() * array.length), 1)[0])
    len--
  }
  return temp
}

// cria um array de números apenas informando os números de início e fim.
export function range (start, end) {
  if (start === end) return [start]
  return [start, ...range(start + 1, end)]
}

// retorna o estilo pelo tipo do recurso.
export function getClassNameResource (resource) {
  if (resource.type === 'GA') {
    return 'fas fa-star '
  }
  if (resource.type === 'AC') {
    return 'fas fa-play-circle '
  }
  if (resource.type === 'TE') {
    return 'fas fa-pencil-alt'
  }
  return 'fas fa-file-alt '
}

// retorna o estilo pelo tipo do recurso.
export function getMiniClassNameResource (resource) {
  if (resource.type === 'GA') {
    return 'fas fa-star'
  }
  if (resource.type === 'AC') {
    return 'fas fa-play-circle'
  }
  if (resource.type === 'TE') {
    return 'fas fa-pencil-alt'
  }
  return 'fas fa-file-alt'
}

// retorna o rótulo pelo tipo do recurso.
export function getLabelNameResource (resource) {
  if (resource.type === 'GA') return 'Gamificada'
  if (resource.type === 'TE') return 'Avaliação'
  if (resource.type === 'AC') return 'Exercícios'
  if (resource.type === 'MA') return 'Material de Apoio'
  if (resource.type === 'SI') return 'Simulado'
  if (resource.type === 'PS') return 'Pacote Scorm'
  return 'Conteúdo'
}

export function formatBytes (bytes, decimals) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals || 2
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Ordena um mapObject por uma determinada propriedade
 * @param object MapObject a ser ordenado
 * @param prop propriedade que o objectMap será ordenado, enviar uma string 'prop'
 * */
export function sortMapByProp (object, prop) {
  return object.sort((a, b) => {
    if (a[prop] > b[prop]) return 1
    if (a[prop] < b[prop]) return -1
    // a must be equal to b
    return 0
  })
}

/*
    list: lista de objetos.
    filter: valor do filtro.
    properties: propriedades de filtro para o objeto.
        Exemplo. lista de usuários e cada usuário possui uma disciplina favorita.
        [{user: {id: 0, name: 'Matheus Henrique', disciplineFavorite: {id: 0, name: 'Matemática Aplicada'}}}]
        Para filtrar pela disciplina favorita é necessário informar em properties. 'disciplineFavorite.name'
*/
export function filterList (list, filter, properties, exact = false) {
  let results = list.filter(item => {
    if (!Array.isArray(properties)) properties = [properties]
    // extrai do objeto a propriedade que deseja aplicar o filtro.
    let value = properties.map(p => {
      try {
        return p.split('.').reduce((accum, val) => accum && accum[val], item).trim()
      } catch (e) {
        return e
      }
    })

    if (exact) return value.indexOf(filter) !== -1

    let _filter = typeof filter !== 'string' ? '' : filter

    if (!_filter) return value

    value = value.join(' ').split(' ').map(v => v.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    _filter = filter.split(' ').map(f => f.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))

    const occurences = value.filter(v => _filter.indexOf(v) !== -1)
    item.occurences = occurences.length

    return value.some(v => _filter.includes(v))
  })

  results = results.sort((a, b) => b.occurences - a.occurences)
  return results
}

/*
  cria lista de labels para facilitar pesquisa utilizando o @filterList;
*/

// fazer filter com getproperty para economizar processamento.
export function createListLabelsMap (list, prefix, sufix) {
  return list.map((o, index) => `${prefix}.${index}.${sufix}`)
}

/*
  cria lista de labels para facilitar pesquisa utilizando o @filterList;
*/
export function createListLabelsReduce (list, subList, prefix, sufix) {
  return list.reduce(
    (acc, object, i) =>
      (acc[i] = createListLabelsMap(object[subList], prefix, sufix)),
    []
  )
}

// Returns if valid month for "LiveClass Monthly Pagination" feat
export function getValidMonth (month = moment().get('month')) {
  const parseMonth = moment({ month })
  const isValid = moment(parseMonth).isValid()
  return (isValid ? parseMonth.get('month') : moment().get('month'))
}

/*
  Faz calculo do progresso sugerido.
*/
export function calcSuggestedProgress (start_date, end_date) {
  const today = moment()

  if (today.isAfter(end_date)) return 100
  if (today.isBefore(start_date)) return 0
  if (today.isBefore(start_date)) return 0

  const startDate = moment(start_date)
  const daysPassed = startDate.diff(today, 'days')
  const daysCount = startDate.diff(end_date, 'days')

  return Number(((100 * daysPassed) / daysCount).toFixed())
}

/** Recebe uma string e verifica se é uma url, retorna true or false */
export function isURL (str) {
  // eslint-disable-next-line
  const pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  return pattern.test(str)
}

// retorna se está em desenvolvimento ou produção.
export function isDev () {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

// return last item of list.
export function last (array) {
  return array[array.length - 1]
}

// return valor válido.
// example: object: VirtualRoomStore,
//         properties:'resource.config.limit_repeat.on'
export function getValidProperty (object, properties) {
  return properties
    .split('.')
    .reduce((acc, value) => (acc ? acc[value] : null), object)
}

// cancela a propagação do clique e o envio do formulário.
export function cancelClick (e) {
  e.preventDefault()
  e.stopPropagation()
}

// retorna imagem padrão padrão caso não exista uma válida
export function getBackgroundImageTutor (urlImage) {
  if (urlImage) return urlImage
  return getImageByTheme('banner_small_tutor.jpg')
}

// retorna imagem padrão padrão caso não exista uma válida
export function getBackgroundImageStudent (urlImage) {
  if (urlImage) return urlImage
  return getImageByTheme('banner_room.jpg')
}

/**
 * Remove duplicates from an array of objects in javascript
 * @param arr - Array of objects
 * @param prop - Property of each object to compare
 * @returns {Array}
 */
export function removeDuplicates (arr, prop) {
  var obj = {}
  for (var i = 0, len = arr.length; i < len; i++) {
    if (!obj[arr[i][prop]]) obj[arr[i][prop]] = arr[i]
  }
  var newArr = []
  for (var key in obj) newArr.push(obj[key])
  return newArr
}

/*
  Método responsável por faver uma área de objetos e retornar o path válido indicado para cada nó.

  object: pai do nó de objetos. Ex. this.
  path: informação que serve de mapa para pesquisar dentro da arvore de objetos. Ex.: 'panelClasses.discipline.knowareas.courses.name'

  return [ "panelClasses.0.discipline.knowareas.0.courses.0.name"
           "panelClasses.1.discipline.knowareas.0.courses.0.name"
           "panelClasses.2.discipline.knowareas.0.courses.0.name"
           "panelClasses.0.discipline.knowareas.1.courses.0.name"
           "panelClasses.0.discipline.knowareas.1.courses.1.name"];

  Estes valores indicam os objetos que podem ser acessados para validar itens como filtro.
  OBS: No inicial precisa ser um objet não lista.

  PRECISA MUDAR ESTRUTURA PARA ACEITAR LISTA DE OBJETOS INICIAL.
*/
export function createListLabelsFilter (object, path, array = [], parent = 0) {
  const split = path.split('.')
  const first = split[0]
  const isList = Array.isArray(object[first])
  const listIndex = []

  if (isList) {
    // array
    if (!array.length) {
      object[first]
        .map((obj, index) => `${first}.${index}`)
        .forEach((obj, index) => {
          array.push(obj)
          listIndex.push(index)
        })
    } else {
      const objectRef = array[parent]
      object[first].forEach((obj, index) => {
        if (index === 0) {
          array[parent] = `${objectRef}.${first}.${index}`
          listIndex.push(parent)
        } else {
          array.push(`${objectRef}.${first}.${index}`)
          listIndex.push(array.length - 1)
        }
      })
    }
  } else {
    // nao array
    array[parent]
      ? (array[parent] = `${array[parent]}.${first}`)
      : array.push(`${first}`)
  }

  // metodo recursivo: enquanto houver um path válido continua chamando.
  if (split.length > 1) {
    const newPath = split.slice(1, split.length).join('.')

    if (isList) {
      object[first].forEach((obj, index) =>
        createListLabelsFilter(
          object[first][index],
          newPath,
          array,
          listIndex[index]
        )
      )
    } else {
      createListLabelsFilter(object[first], newPath, array, parent)
    }
  }
  return array
}

export function querySearchParse (querySearch) {
  if (querySearch.startsWith('?')) {
    var list1 = querySearch.split('?').filter(x => x)
    var list2 = list1[0].split('&').filter(x => x)
    var dict = {}
    for (var i = 0; i < list2.length; i++) {
      var item = list2[i].split('=').filter(x => x)
      dict[item[0]] = item[1]
    }
    return dict
  }
  return {}
}

// remove tag html e retorna texto limpo.
export function removeTagHTML (value) {
  return value.replace(/<.*?>/g, ' ')
}

export function getImageByTheme (name) {
  const domain = getThemeDomain()
  return `${process.env.PUBLIC_URL}/themes/${domain}/imgs/${name}`
}

export const themeConfig = getThemeConfig()

/* Navigate TO */
export function navigateTo (pathname, history) {
  // custom
  if (history instanceof History) {
    history.location = new Location(pathname)
  } else {
    // default
    history.push(pathname)
  }
}

export function split (string, divider = '/') {
  return string.split(divider).filter(x => x)
}

export function lastPathname (pathname) {
  const array = split(pathname)
  return `/${array.slice(0, array.length - 1).join('/')}/`
}

/***
 * *Retorna o nome do tema de acordo ao domínio
 * */
export function getThemeDomain () {
  let domain = window.location.hostname.split('.')[0]
  if (domain.endsWith('dev') || domain.endsWith('uat')) {
    domain = domain.substring(0, domain.length - 3)
  }
  switch (domain) {
  case 'aulapp':
  case 'eumebanco':
  case 'cursinhopoli':
  case 'cejam':
  case 'calafiori':
  case 'englishpractice':
  case 'mentoringhub':
  case 'medhubedu':
  case 'avance':
  case 'florestanfernandes':
  case 'canaldoeditor':
  case 'proconcursado':
  case 'pardinho':
  case 'corporativo':
  case 'santaadelia':
    return domain
  case 'learn':
    return 'aulapp'
  default:
    return 'candy'
  }
}

export function getDomainTagManager () {
  const domain = window.location.hostname.split('.')[0]
  switch (domain) {
  case 'aulapp':
  case 'eumebanco':
  case 'cursinhopoli':
    return domain
  case 'cejam':
  case 'calafiori':
  case 'englishpractice':
  case 'medhubedu':
  case 'mentoringhub':
  case 'avance':
  case 'florestanfernandes':
  case 'canaldoeditor':
  case 'proconcursado':
  case 'pardinho':
  case 'corporativo':
  case 'appsys':
  case 'santaadelia' :
    return domain
  default:
    return 'debug'
  }
}

/**
 * Load the configuration vars for the theme
 * @returns
 * {
 *   helmet: variables for the <Helmet/> component
 *   favicon: favicon
 *   login: variables for the custom code on the login page
 *   slogan: texts of the slogan
 * }
 */
export function getThemeConfig () {
  const domain = getThemeDomain()
  return require(`./config/themes/${domain}`)
}

export function getFileBuffer (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => resolve(reader.result)
    reader.onerror = error => reject(error)

    reader.readAsArrayBuffer(file)
  })
}

// vim stands for vimeo
export const previableTypes = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'mp3', 'mp4', 'vim']

/**
 * Faz download de um arquivo (biblioteca utilizada para renomear arquivos independente do cabeçalho Content-Disposition)
 * @param {*} url URL do arquivo
 * @param {*} name Nome do arquivo
 */
export function downloadFile (url, name, callback) {
  axios.get(`${url}?hash=${Math.trunc(Math.random() * (9999999999 - 0) + 0)}`, { responseType: 'blob' }).then(response => {
    const cUrl = window.URL.createObjectURL(new Blob([response.data]))
    const cLink = document.createElement('a')
    cLink.href = cUrl
    cLink.setAttribute('download', name)
    document.body.appendChild(cLink)
    cLink.click()
  }).finally(() => { callback && callback() })
}

/**
 * @function randomString Generates a random string
 */
export function randomString () {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}
