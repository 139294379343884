import DomainModel from './DomainModel'
import DisciplineModel from './DisciplineModel'

export default class CourseModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.knowarea = data.knowarea ? new DomainModel(data.knowarea) : null
    this.institution = data.institution ? data.institution : null
    this.disciplines = data.disciplines ? data.disciplines.map(dis => new DisciplineModel(dis)) : null
  }
}
