export default class SupportMaterialModel {
  constructor (data) {
    if (!data) return
    this.id = data.id
    this.filename = data.name
    this.title = data.title
    this.reference = data.reference
    this.url = data.url
    this.size = data.size
  }
}
