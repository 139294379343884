import { extendObservable } from 'mobx'

export default class TrailModel {
  constructor (data) {
    extendObservable(this, {
      id: data.id,
      created_by: data.created_by,
      disciplines: data.disciplines || [],
      name: data.name,
      integration_code: data.integration_code,
      issues_certificate: data.issues_certificate,
      start_date: data.start_date,
      completed: data.completed,
      institution_name: data.institution_name,
      producer: data.producer,
      author: data.author,
      created_at: data.created_at,
      updated_at: data.updated_at,
      deleted_at: data.deleted_at,
      institution: data.institution
    })
  }
}
