export default class ConfigActivitiesList {
  /*
    jump_activitie:true
    limit_repeat: {on: false, limit: 1}
    randomize:true
    time_control:{on: true, limit: "00:01"}
    type:"GA"
    what_attr:{type: "vidas", lifes: "0", max: "0"}
  */
  constructor (data) {
    this.jump_activitie = data.jump_activitie
    this.limit_repeat = data.limit_repeat
    this.randomize = data.randomize
    this.time_control = data.time_control
    this.type = data.type
    this.what_attr = data.what_attr
    // inicialmente a variavel pode vir undefined, por isso é feito a verificação para setar o primeiro valor como false para embaralhar as alternativas.
    // this.randomize_alternatives = data.randomize_alternatives !== undefined ? data.randomize_alternatives : false
    this.randomize_alternatives = true
    this.question_term = data.question_term || 'Questão'
  }
}
