import BaseRequests, { URLS } from './BaseRequests'

class GamificationRequests {
  // recuperando ranking
  static getRanking (institution_id) {
    const data = {
      institution: institution_id
    }

    return BaseRequests.post(URLS.GET_RANKING, data)
      .then(response => response.data)
  }

  // recuperando conquistas
  static getConquest (institution_id) {
    const data = {
      institution: institution_id
    }

    return BaseRequests.post(URLS.GET_CONQUEST, data)
      .then(response => response.data)
  }

  // recuperando configs
  static getConfig (institution_id) {
    // criando uma nova promise para capturar as configurações
    return new Promise((resolve, reject) => {
      const config = { ranking: null, conquest: null }
      // buscando configurações do ranking
      this.getRanking(institution_id)
      // quando busca for concluida
        .then(ranking => {
          config.ranking = ranking.ranking
          // buscando configurações de conquistas
          this.getConquest(institution_id)
          // quando a busca for concluida
            .then(conquest => {
              config.conquest = conquest.conquest_conf
              // resolvendo promise
              resolve(config)
            })
        })
    })
  }

  // atualizando ranking
  static updateRanking (institution_id, config) {
    const data = {
      institution: institution_id,
      activate: config.active
    }

    if ('resources' in config) {
      data.resources = config.resources
    }

    return BaseRequests.post(URLS.UPDATE_RANKING, data)
      .then(response => response.data.success)
  }

  // atualizando conquistas
  static updateConquest (institution_id, config) {
    const data = {
      institution: institution_id,
      activate: config.active
    }

    if ('conquestGroups' in config) {
      data.conquest_groups = config.conquestGroups
    } else if ('conquestGroupItens' in config) {
      data.group_id = config.groupId
      data.group_items = config.conquestGroupItens
    }

    return BaseRequests.post(URLS.UPDATE_CONQUEST, data)
      .then(response => response.data.success)
  }
}

export default GamificationRequests
