import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getTaskRequest: ['params'],
  getTaskSuccess: ['data'],
  getTaskFailure: ['error'],
  createTaskRequest: ['params'],
  createTaskSuccess: ['data'],
  createTaskFailure: ['error'],
  updateTaskRequest: ['params'],
  updateTaskSuccess: ['data'],
  updateTaskFailure: ['error'],
  deleteTaskRequest: ['params'],
  deleteTaskSuccess: ['data'],
  deleteTaskFailure: ['error'],
  deleteFileRequest: ['params'],
  deleteFileSuccess: ['data'],
  deleteFileFailure: ['error'],
  getStudentTaskRequest: ['params'],
  getStudentTaskSuccess: ['data'],
  getStudentTaskFailure: ['error'],
  createStudentTaskRequest: ['params'],
  createStudentTaskSuccess: ['data'],
  createStudentTaskFailure: ['error'],
  gradedWorkRequest: ['params'],
  gradedWorkSuccess: ['data'],
  gradedWorkFailure: ['error']
})

const INITIAL_STATE = {
  isLoading: true,
  error: false
}

// Get form data
export const getTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const getTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  tasks: action.data,
  taskDeleted: undefined,
  taskCreated: undefined,
  taskChanged: undefined
})
export const getTaskFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Create task
export const createTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const createTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  taskCreated: action.data
})
export const createTaskFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// editar
export const updateTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const updateTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  taskChanged: true
})
export const updateTaskFailure = (state = INITIAL_STATE, action) => ({ ...state, isLoading: false, taskChanged: false })

// deletar
export const deleteTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const deleteTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  taskDeleted: true
})
export const deleteTaskFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// deletar arquivo
export const deleteFileRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const deleteFileSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  fileDeleted: true
})
export const deleteFileFailure = (state = INITIAL_STATE, action) => (state.isLoading = false)

// Student Requests
export const getStudentTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const getStudentTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  tasks: action.data
})
export const getStudentTaskFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Teacher graded work
export const gradedWorkRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const gradedWorkSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  gradedWork: action.data
})
export const gradedWorkFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

export const createStudentTaskRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const createStudentTaskSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  taskCreated: action.data
})
export const createStudentTaskFailure = (state = INITIAL_STATE, action) => (state.isLoading = false)

export default createReducer(INITIAL_STATE, {
  [Types.GET_TASK_REQUEST]: getTaskRequest,
  [Types.GET_TASK_SUCCESS]: getTaskSuccess,
  [Types.GET_TASK_FAILURE]: getTaskFailure,
  [Types.CREATE_TASK_REQUEST]: createTaskRequest,
  [Types.CREATE_TASK_SUCCESS]: createTaskSuccess,
  [Types.CREATE_TASK_FAILURE]: createTaskFailure,
  [Types.UPDATE_TASK_REQUEST]: updateTaskRequest,
  [Types.UPDATE_TASK_SUCCESS]: updateTaskSuccess,
  [Types.UPDATE_TASK_FAILURE]: updateTaskFailure,
  [Types.DELETE_TASK_REQUEST]: deleteTaskRequest,
  [Types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [Types.DELETE_TASK_FAILURE]: deleteTaskFailure,
  // Student
  [Types.GET_STUDENT_TASK_REQUEST]: getStudentTaskRequest,
  [Types.GET_STUDENT_TASK_SUCCESS]: getStudentTaskSuccess,
  [Types.GET_STUDENT_TASK_FAILURE]: getStudentTaskFailure,
  [Types.CREATE_STUDENT_TASK_REQUEST]: createStudentTaskRequest,
  [Types.CREATE_STUDENT_TASK_SUCCESS]: createStudentTaskSuccess,
  [Types.CREATE_STUDENT_TASK_FAILURE]: createStudentTaskFailure,
  [Types.DELETE_FILE_REQUEST]: deleteFileRequest,
  [Types.DELETE_FILE_SUCCESS]: deleteFileSuccess,
  [Types.DELETE_FILE_FAILURE]: deleteFileFailure,
  [Types.GRADED_WORK_REQUEST]: gradedWorkRequest,
  [Types.GRADED_WORK_SUCCESS]: gradedWorkSuccess,
  [Types.GRADED_WORK_FAILURE]: gradedWorkFailure
})
