import { put, all, takeLatest } from 'redux-saga/effects'
import { Types, Creators } from './index'
import TaskDeliveryRequests from '../../../services/requests/TaskDeliveryRequests'

// Teacher Requests
function * getTasks (action) {
  try {
    const { data } = yield TaskDeliveryRequests.getTasks(action.params)
    yield put(Creators.getTaskSuccess(data))
  } catch (error) {
    yield put(Creators.getTaskFailure(error))
  }
}

function * gradedWork (action) {
  try {
    const { data } = yield TaskDeliveryRequests.gradedWork(action.params)
    yield put(Creators.getTaskSuccess(data))
  } catch (error) {
    yield put(Creators.getTaskFailure(error))
  }
}

function * createTaskDelivery (action) {
  try {
    const { data } = yield TaskDeliveryRequests.createTaskDelivery(action.params)
    yield put(Creators.createTaskSuccess(data))
  } catch (error) {
    yield put(Creators.createTaskFailure(error))
  }
}

function * updateTaskDelivery (action) {
  try {
    const { data } = yield TaskDeliveryRequests.updateTaskDelivery(action.params)
    yield put(Creators.updateTaskSuccess(data))
  } catch (error) {
    yield put(Creators.updateTaskFailure(error))
  }
}

function * deleteTaskDelivery (action) {
  try {
    const { data } = yield TaskDeliveryRequests.deleteTaskDelivery(action.params)
    yield put(Creators.deleteTaskSuccess(data))
  } catch (error) {
    yield put(Creators.deleteTaskFailure(error))
  }
}

function * deleteFile (action) {
  try {
    const { data } = yield TaskDeliveryRequests.deleteFile(action.params)
    yield put(Creators.deleteFileSuccess(data))
  } catch (error) {
    yield put(Creators.deleteFileFailure(error))
  }
}

// Student Requests

function * getStudentTask (action, room) {
  try {
    const { data } = yield TaskDeliveryRequests.getStudentTask(action.params, room)
    yield put(Creators.getStudentTaskSuccess(data))
  } catch (error) {
    yield put(Creators.getStudentTaskFailure(error))
  }
}

function * createStudentTask (action) {
  try {
    const { data } = yield TaskDeliveryRequests.createStudentTaskDelivery(action.data)
    yield put(Creators.createStudentTaskSuccess(data))
  } catch (error) {
    yield put(Creators.createStudentTaskFailure(error))
  }
}

export default all([
  takeLatest(Types.GET_TASK_REQUEST, getTasks),
  takeLatest(Types.CREATE_TASK_REQUEST, createTaskDelivery),
  takeLatest(Types.DELETE_TASK_REQUEST, deleteTaskDelivery),
  takeLatest(Types.UPDATE_TASK_REQUEST, updateTaskDelivery),
  takeLatest(Types.GET_STUDENT_TASK_REQUEST, getStudentTask),
  takeLatest(Types.CREATE_STUDENT_TASK_REQUEST, createStudentTask),
  takeLatest(Types.DELETE_FILE_REQUEST, deleteFile),
  takeLatest(Types.GRADED_WORK_REQUEST, gradedWork)
])
