import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getDisciplinesRequest: ['params'],
  getDisciplinesSuccess: ['data'],
  getDisciplinesFailure: ['error']
})

const INITIAL_STATE = {
  isLoading: false,
  error: false
}

// Get form data
export const getDisciplinesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const getDisciplinesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    disciplines: action.data.disciplines,
    page: action.data.page
  }
}
export const getDisciplinesFailure = (state = INITIAL_STATE, action) => (state.isLoading = false)

export default createReducer(INITIAL_STATE, {
  [Types.GET_DISCIPLINES_REQUEST]: getDisciplinesRequest,
  [Types.GET_DISCIPLINES_SUCCESS]: getDisciplinesSuccess,
  [Types.GET_DISCIPLINES_FAILURE]: getDisciplinesFailure
})
