import ClassModel from './ClassModel'
import DisciplineModel from './DisciplineModel'
import LessonsGroupModel from './LessonsGroupModel'

export default class VirtualRoomModel {
  constructor (data) {
    this.class = new ClassModel(data.class)
    this.discipline = new DisciplineModel(data.discipline)
    this.lessons_group = new LessonsGroupModel(data.lessons_group)
  }
}
