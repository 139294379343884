import BaseRequests, { API } from './BaseRequests'

class TaskDeliveryRequests {
  static createTaskDelivery (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution}/work`, params)
      .then(response => {
        return response
      })
  }

  static createStudentTaskDelivery (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution_id}/new-work-delivery`, params)
      .then(response => {
        return response
      })
  }

  static updateTaskDelivery (params) {
    return BaseRequests.put(`${API}/classroom/${params.institution}/work/${params.id}`, params)
      .then(response => {
        return response
      })
  }

  static getStudentTask (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/students/work-delivery`, params)
      .then(response => {
        return response
      })
  }

  static getTasks (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/work/disciplines/${params.discipline}?search=${params.search}&page=${params.page}`)
      .then(response => {
        return response
      })
  }

  static gradedWork (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/disciplines/${params.discipline}/graded-work?search=${params.search}&page=${params.page}`, params)
      .then(response => {
        return response
      })
  }

  static deleteTaskDelivery (params) {
    return BaseRequests.delete(`${API}/classroom/${params.institution_id}/work/${params.id}/classes/${params.classId}`)
      .then(response => {
        return response
      })
  }

  static deleteWholeTaskDelivery (params) {
    return BaseRequests.delete(`${API}/classroom/${params.institution_id}/work/${params.id}`)
      .then(response => {
        return response
      })
  }

  static correctWorkDelivery (params) {
    return BaseRequests.put(`${API}/classroom/${params.institution_id}/work-delivery/${params.id}`, params)
      .then(response => {
        return response
      })
  }

  static deleteFile (params) {
    return BaseRequests.delete(`${API}/classroom/${params.institution}/work-attachments`, {
      attachments: params.filesToDelete
    })
      .then(response => {
        return response
      })
  }
}

export default TaskDeliveryRequests
