import { extendObservable } from 'mobx'
import InstitutionModel from '../models/InstitutionModel'
import UserModel from '../models/UserModel'
import AuthRequests from '../services/requests/AuthRequests'
import FirebaseRequest from '../services/requests/FirebaseRequest'
import RoadBarStore from '../stores/RoadBarStore'
import { toast } from 'react-toastify'
import { themeConfig } from '../Util'

class AuthStore {
  constructor () {
    extendObservable(this, {
      user: undefined,
      institution_current: undefined,
      permissions: undefined,
      roles: undefined,
      is_authenticated: undefined,
      loading: false,
      admin: false
      // logoutMessage: ''
    })
  }

  reset () {
    this.is_authenticated = undefined
    this.permissions = undefined
    this.institution_current = undefined
    this.user = undefined
  }

  login (email, password) {
    if (this.is_authenticated) return
    localStorage.removeItem('selectedKnowareaIndex')
    return AuthRequests.login(email, password)
  }

  logout (message = '') {
    localStorage.setItem('logoutMessage', message)
    return AuthRequests.logout()
      .then(() => {
        this.is_authenticated = false
        this.permissions = undefined
        this.roles = undefined
        this.institution_current = undefined
        this.user = undefined
        // this.logoutMessage = message
      })
  }

  signup (name, email, password) {
    return AuthRequests.signup(name, email, password)
  }

  loginGuest () {
    if (this.is_authenticated) return
    AuthRequests.loginGuest().then(response => {
      if (response.data.success) {
        this.updateInfo()
        this.loading = false
      }
    })
  }

  passwordReset (email) {
    return AuthRequests.passwordReset(email)
  }

  passwordChange (old_password, new_password1, new_password2) {
    this.loading = true
    return AuthRequests.passwordChange(old_password, new_password1, new_password2)
      .then(response => {
        if (response.data.success) {
          toast.success('✅ Senha alterada com sucesso!')
        } else {
          toast.error('⛔\n' + response.data.message || ' Não foi possível alterar sua senha!')
        }
        return response.data
      })
      .catch(e => {
        toast.error('⛔ Não foi possível alterar sua senha!')
        console.error(e)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setCpf (cpf) {
    this.loading = true
    return AuthRequests.setCpf(cpf)
      .then(response => {
        if (response.data.cpf && !response.data.message) {
          toast.success('✅ CPF alterado com sucesso!')
        } else {
          toast.error('⛔\n' + response.data.message || ' Não foi possível cadastrar seu CPF')
        }
        return response.data
      })
      .catch(e => {
        toast.error('⛔ Não foi possível cadastrar seu CPF!')
        console.error(e)
      })
      .finally(() => {
        this.loading = false
      })
  }

  getCpf () {
    return AuthRequests.getCpf()
      .then(response => {
        return response.data
      }).catch(e => {

      })
  }

  hasPermission (value) {
    if (!(value in this.permissions)) return false
    return this.permissions ? this.permissions[value] : false
  }

  setName (name) {
    this.user._name = name
    // adaptação até conversarmos melhor sobre eject e flux.
    this.user = new UserModel(this.user)
    return AuthRequests.setName(name)
      .then(response => {
        if (response.data.success) {
          toast.success('✅ Nome alterado com sucesso!')
        }
        return response.data
      })
      .catch(e => {
        toast.error('⛔ Não foi possível alterar o nome!')
        console.error(e)
      })
  }

  setCurrentInstitution (institution) {
    return AuthRequests.setCurrentInstitution(institution.id)
      .then(() => this.updateInfo())
  }

  updateInfo (reset = true) {
    if (reset) this.reset()
    return AuthRequests.getInfo()
      .then(data => {
        if (data) {
          this.user = new UserModel(data)
          RoadBarStore.update()
          AuthRequests.getInstitutions()
            .then(institutions => {
              this.user.institutions = institutions.map(institution => new InstitutionModel(institution))

              // se o usuario não estiver instituição realiza o logout;
              if (this.user.institutions.length === 0) return this.logout(`Você não está ativo ou não tem conta ${themeConfig.terms.nessaInstituicao}.`)
              localStorage.removeItem('logoutMessage')

              AuthRequests.getCurrentInstitution()
                .then(id => {
                  if (!id) {
                    this.institution_current = this.user.institutions[0]
                    this.setCurrentInstitution(this.institution_current)
                  } else {
                    this.institution_current = this.user.institutions.find(institution => institution.id === id)
                  }
                })
                .then(() => FirebaseRequest.logaUsuarioFirebase(this.user))
                .then(() => {
                  AuthRequests.getPermissions(this.institution_current.id)
                    .then(data => {
                      this.permissions = data.permissions
                      this.roles = data.roles
                      this.is_authenticated = true
                    })
                })
            })
        }
      }).catch(e => e)
      .finally(() => {
        if (this.user === undefined) this.is_authenticated = false
      })
  }
}

export default new AuthStore()
