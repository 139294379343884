import InstitutionItemConfigModel from './InstitutionItemConfigModel'
/**
 * Classe pai da configuração de integração
 * @attribute {Array} institutions Lista de configurações de integração
 */
export default class InstitutionConfigModel {
  constructor (data) {
    this.institutions = data.institutions.map(institution => new InstitutionItemConfigModel(institution))
  }
}
