import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { getDomainTagManager } from './Util'

export const Envs = Object.freeze({
  local: 0,
  develop: 1,
  uat: 2,
  prod: 3
})

export const ENV = Object.freeze(Envs[process.env.REACT_APP_ENV])

/********************************/
/** ********** CONFIG ************/
/********************************/

export const FeatureToggles = Object.freeze({
  /** declare paths to exclude view by environments
   ******* Sample: "/disciplines/": ["local", "develop", "uat", "prod"]*****/
  // '/import/': ['prod', 'uat']
  // '/questions-database/': ['prod']
})

const EnvSettings = [
  { // LOCAL
    functions: 'https://us-central1-aulapp-local.cloudfunctions.net/',
    config: {
      apiKey: 'AIzaSyCr4TzWLQolhk4mndWxbOilzPKVlv_NJJs',
      authDomain: 'aulapp-local.firebaseapp.com',
      databaseURL: 'https://aulapp-local.firebaseio.com',
      projectId: 'aulapp-local',
      storageBucket: 'aulapp-local.appspot.com',
      messagingSenderId: '468162568110'
    }
  },
  { // DEVELOP
    functions: 'https://us-central1-aulapp-development.cloudfunctions.net/',
    config: {
      apiKey: 'AIzaSyCxdLWP1oHz7v3Y9Ph-i295007ZPcAY-iw',
      authDomain: 'aulapp-development.firebaseapp.com',
      databaseURL: 'https://aulapp-development.firebaseio.com',
      projectId: 'aulapp-development',
      storageBucket: 'aulapp-development.appspot.com',
      messagingSenderId: '977937338214'
    }
  },
  { // UAT
    functions: 'https://us-central1-aulapp-homologation.cloudfunctions.net/',
    config: {
      apiKey: 'AIzaSyDxjDMVZl3jcmiIfQwz5KvGJwUq10a3DHU',
      authDomain: 'aulapp-homologation.firebaseapp.com',
      databaseURL: 'https://aulapp-homologation.firebaseio.com',
      projectId: 'aulapp-homologation',
      storageBucket: 'aulapp-homologation.appspot.com',
      messagingSenderId: '1046416574946'
    }
  },
  { // PROD
    functions: 'https://us-central1-appsys-d56e1.cloudfunctions.net/',
    config: {
      apiKey: 'AIzaSyDPepe8mWs9NjmpPdUYLR246I49dvk6xt8',
      authDomain: 'appsys-d56e1.firebaseapp.com',
      databaseURL: 'https://appsys-d56e1.firebaseio.com',
      projectId: 'appsys-d56e1',
      storageBucket: 'appsys-d56e1.appspot.com',
      messagingSenderId: '823795764218'
    }
  }
]

/** Develve o tagmanager de acordo com o tema apresentado... */
const tagManager = Object.freeze({
  cursinhopoli: 'G-33B3F4S8VL',
  proconcursado: 'GTM-PLTNPBMR',
  pardinho: 'G-DK5BZ9725P',
  eumebanco: 'GTM-T75VG46',
  florestan: 'G-9N2W13BE25',
  medhubedu: 'G-RNRJTB3F9G',
  santaadelia: 'G-Q3E8D1B6CX',
  mentoringhub: 'GTM-59SLBRWL',
  colegioub: 'GTM-P9TKFH9',
  appsys: 'GTM-P68WZDH',
  contatrial: 'GTM-WPLCM6V',
  desafioead: 'GTM-WCBCPBF',
  desafioescoladigital: 'GTM-WKQMJRW',
  debug: 'GTM-P68WZDH'
})

export const ENV_VARS = Object.freeze(EnvSettings[ENV])
export const DOMAIN = window.location.hostname
export const PORT = ENV_VARS.PORT || '8000'
export const IGNORE_REVERSE_PROXY = ENV_VARS.IGNORE_REVERSE_PROXY || false
export const TAG_MANAGER = tagManager[getDomainTagManager()]
export const tagManagerArgs = { gtmId: TAG_MANAGER }
export const urlFunctions = ENV_VARS.functions
export const firebaseImpl = firebase.initializeApp(ENV_VARS.config)
export const firebaseDatabase = firebase.database()
