import { extendObservable } from 'mobx'

import AuthStore from './AuthStore'
import VirtualRoomStore from './VirtualRoomStore'
import DisciplineStore from './DisciplineStore'
import ReportStore from './ReportsStore'
import CourseStore from './CourseStore'
import ForumStore from './ForumStore'
import { themeConfig } from '../Util'
import TrailStore from './TrailStore'

class RoadBarStore {
  constructor () {
    this.initialSubtitle = 'Área Inicial'
    extendObservable(this, {
      title: '',
      links: [],
      icon: ['', '']
    })
    this.update()
  }

  static stores () {
    return {
      institutions: undefined,
      students: undefined,
      users: undefined,
      'lessons-group': undefined,
      disciplines: DisciplineStore,
      'tutor-room': RoadBarStore.mapVirtualRoomStore(),
      'edit-profile': undefined,
      'student-room': RoadBarStore.mapVirtualRoomStore(),
      reports: ReportStore,
      'client-reports': ReportStore,
      'staff-reports': ReportStore,
      domains: undefined,
      import: undefined,
      courses: CourseStore,
      'questions-database': undefined,
      'generate-links': undefined,
      announcements: undefined,
      'scorm-management': undefined,
      trails: TrailStore
    }
  }

  static titles () {
    return {
      institutions: [`Gestão ${themeConfig.terms.deInstituicoesC}`],
      students: [`Gestão de ${themeConfig.terms.alunosC}`],
      users: [`Gestão de Usuários`],
      'lessons-group': [`Gestão ${themeConfig.terms.deGruposDeAulaICC}`],
      'edit-profile': ['Configurações de perfil'],
      disciplines: [`Gestão ${themeConfig.terms.deDisciplinasC} e ${themeConfig.terms.turmasC}`, themeConfig.terms.disciplinaC, `${themeConfig.terms.tutoresC} e ${themeConfig.terms.alunosC} ${themeConfig.terms.daTurmaC}`],
      'tutor-room': ['Sala Virtual do Tutor', themeConfig.terms.grupoDeAulasICC, themeConfig.terms.aulaC],
      'student-room': [`Sala Virtual ${themeConfig.terms.doAlunoC}`, themeConfig.terms.grupoDeAulasICC, themeConfig.terms.aulaC],
      'questions-database': ['Banco de Questões', 'Detalhes do banco'],
      reports: ['Relatórios'],
      'client-reports': ['Relatórios'],
      'staff-reports': ['Relatórios'],
      'generate-links': ['Criação de Links - Magalu'],
      domains: ['Domínios'],
      import: ['Importação de Alunos'],
      courses: ['Cursos'],
      forum: ['Fórum'],
      liveclass: ['Aula ao vivo'],
      taskdelivery: ['Entrega de Trabalho'],
      announcements: ['Comunicados'],
      'scorm-management': ['Biblioteca SCORM'],
      trails: ['Trilha de Conhecimento']
    }
  }

  static subtitles () {
    return {
      institutions: [themeConfig.terms.instituicoesC],
      students: [themeConfig.terms.alunosC],
      users: ['Usuários'],
      'lessons-group': [`Gestão ${themeConfig.terms.deGruposDeAulaICC}`],
      'edit-profile': ['Configurações de perfil'],
      disciplines: [themeConfig.terms.disciplinasC, 'Turmas', `${themeConfig.terms.tutoresCs} e ${themeConfig.terms.alunosC}`],
      'tutor-room': [themeConfig.terms.disciplinasC, themeConfig.terms.grupoDeAulasICC, themeConfig.terms.aulaC],
      'student-room': [themeConfig.terms.disciplinasC, themeConfig.terms.grupoDeAulasICC, themeConfig.terms.aulaC],
      'questions-database': ['Banco de Questões', 'Detalhes'],
      reports: ['Relatórios'],
      'client-reports': ['Relatórios'],
      'staff-reports': ['Relatórios'],
      domains: ['Domínios'],
      import: ['Importação de Alunos'],
      courses: ['Cursos'],
      forum: ['Fórum'],
      'generate-links': ['Criação de Links - Magalu'],
      liveclass: ['Aula ao vivo'],
      taskdelivery: ['Entrega de Trabalho'],
      announcements: ['Comunicados'],
      'scorm-management': ['Biblioteca SCORM'],
      trails: ['Trilha de Conhecimento']
    }
  }

  static icons () {
    return {
      institutions: ['color_bg_institution', 'fas fa-building'],
      students: ['color_bg_manager', 'fas fa-users'],
      users: ['color_bg_manager', 'fas fa-id-card'],
      disciplines: ['color_bg_manager', 'fas fa-clipboard-list'],
      'tutor-room': ['color_bg_teacher', 'fas fa-graduation-cap'],
      'edit-profile': ['color_bg_publisher', 'fas fa-cog'],
      'student-room': ['color_bg_learn', 'fas fa-user'],
      reports: ['color_bg_manager', 'fas fa-id-card'],
      'client-reports': ['color_bg_manager', 'fas fa-id-card'],
      'staff-reports': ['color_bg_institution', 'fas fa-id-card'],
      domains: ['color_bg_manager', 'fas fa-building'],
      import: ['color_bg_manager', 'fas fa-users'],
      courses: ['color_bg_manager', 'fas fa-clipboard-list'],
      trails: ['color_bg_manager', 'fas fa-book-open'],
      forum: ['color_bg_manager', 'fas fa-clipboard-list'],
      liveclass: ['color_bg_manager', 'fas fa-clipboard-list'],
      taskdelivery: ['color_bg_manager', 'fas fa-clipboard-list'],
      'questions-database': ['color_bg_manager', 'fas fa-list-alt'],
      'generate-links': ['color_bg_manager', 'fas fa-id-card'],
      'lessons-group': ['color_bg_manager', 'fas fa-clone'],
      announcements: ['color_bg_manager', 'fas fa-bell'],
      'scorm-management': ['color_bg_manager', 'fas fa-file-archive']
    }
  }

  // VirtualRoom é uma área que possui mais de uma Store.
  static mapVirtualRoomStore () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) return VirtualRoomStore
    if (pathList[1] === 'forum') return ForumStore
    return VirtualRoomStore
  }

  update () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length > 0 && !(pathList[0] in RoadBarStore.stores())) return
    const store = pathList.length > 0 ? RoadBarStore.stores()[pathList[0]] : null

    if (store) {
      this.title = store.getTitle()
      this.links = store.getLinks(this.links || [])
    } else {
      let address
      const links = []
      for (let i = 0; i < pathList.length; i++) {
        if (i === pathList.length - 1 || !this.links[i]) {
          address = `/${pathList.slice(0, i + 1).join('/')}/${window.location.search}`
          links.push({ title: this.getSubtitle(pathList[0], i), address })
        } else {
          links.push(this.links[i])
        }
      }
      this.title = this.getTitle(pathList[0], pathList.length - 1)
      this.links = links
    }
    this.icon = this.getIcon(pathList[0], pathList.length - 1)
  }

  getTitle (area, index) {
    if (index === -1 && AuthStore && AuthStore.user) {
      return `Bem-vindo, ${AuthStore.user.name}!`
    }
    if (index === -1) {
      return 'Bem-vindo!'
    }
    return RoadBarStore.titles()[area][index]
  }

  getSubtitle (area, index) {
    return RoadBarStore.subtitles()[area][index]
  }

  getIcon (area, index) {
    if (index === -1) {
      return ['', 'fa fa-lg fa-th']
    } else {
      return RoadBarStore.icons()[area]
    }
  }
}

export default new RoadBarStore()
