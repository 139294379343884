import { all } from 'redux-saga/effects'

import liveClasses from './ducks/liveclass/sagas'
import activation from './ducks/activation/sagas'
import discipline from './ducks/discipline/sagas'
import taskdelivery from './ducks/taskdelivery/sagas'

export default function * rootSaga () {
  return yield all([
    liveClasses,
    activation,
    taskdelivery,
    discipline
  ])
}
