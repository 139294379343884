import BaseRequests, { URLS } from './BaseRequests'

class UsersRequests {
  static getUser (institution, id) {
    const data = { id }
    if (institution) data.institution = institution
    return BaseRequests.post(URLS.GET_USERS, data)
      .then(response => response.data.user)
  }

  static updateUser (institution, id, update) {
    const data = { institution, id, ...update }
    return BaseRequests.post(URLS.UPDATE_USER, data)
  }

  static updateInvite (institution, id, update, trails = null) {
    const data = { institution, id, trails, ...update }
    if (data.trails === null) delete data.trails
    return BaseRequests.post(URLS.UPDATE_INVITE, data)
  }

  static getStudents (institution, search, discipline, page) {
    const data = { discipline }
    data.limit = 10
    if (search) data.search = search
    if (page) data.page = page
    return BaseRequests.get(`/api/classroom/${institution}/students`, data)
      .then(response => response.data.results)
  }

  static getUsers (institution, roles, not_in_discipline, not_in_class, search, page, full_data) {
    const data = { search, page }
    if (institution) data.institution = institution
    if (roles) data.roles = roles
    data.limit = 10
    if (not_in_discipline) data.not_in_discipline = not_in_discipline
    if (not_in_class) data.not_in_class = not_in_class
    return BaseRequests.post(URLS.GET_USERS, data)
      .then(response => {
        if (full_data) return response.data
        const users = response.data.users
        if (users.page) {
          users.page = response.data.page
        }
        return users
      })
  }

  static getUsersClass (institution, class_id, get_tutors, search, page) {
    const data = {
      institution,
      class: class_id,
      get_tutors: get_tutors,
      search,
      limit: 10,
      page
    }
    return BaseRequests.post(URLS.GET_USERS, data)
      .then(response => response.data)
  }

  static getUsersAndInvites (institution, roles, pageNumber, filter, filterList = {}) {
    const data = { ...filterList, institution }

    if (roles) data.roles = roles
    data.search = filter
    data.page = pageNumber
    return BaseRequests.post(URLS.GET_USERS_AND_INVITES, data)
      .then(response => response.data)
  }

  static invite (institution, name, email, roles, re_invite, classes, registry, trails) {
    const data = { institution, name, email, roles, re_invite, classes, registry, trails }
    return BaseRequests.post(URLS.NEW_INVITE, data)
  }

  static importStudents (institution_id, file) {
    const payload = {
      institution_id: institution_id,
      file: file
    }
    return BaseRequests.post(URLS.IMPORT_PERSONS, payload)
  }

  static getImportations (institution_id, importsQty, pageIndex, details) {
    const payload = {
      institution_id: institution_id,
      importsQty: importsQty,
      pageIndex: pageIndex,
      details: details
    }
    return BaseRequests.post(URLS.GET_IMPORTS, payload)
      .then(response => JSON.parse(response.data.imports))
  }

  static deleteInvite (institution_id, id) {
    const data = {
      institution: institution_id,
      id: id
    }
    return BaseRequests.post(URLS.DELETE_INVITE, data)
  }

  static delete (institution_id, id, student_only) {
    const data = {
      institution: institution_id,
      users: [id]
    }
    if (student_only === true) data.students = true
    if (student_only === false) data.team = true
    return BaseRequests.post(URLS.DELETE_USER, data)
  }

  static getStudentLinks (institutionId, userId) {
    return BaseRequests.get(URLS.GET_STUDENT_LINKS, {
      institution_id: institutionId,
      user_id: userId
    })
  }

  static getinviteLinks (institutionId, inviteId) {
    return BaseRequests.get(URLS.GET_INVITE_LINKS, {
      institution_id: institutionId,
      invite_id: inviteId
    })
  }

  static addStudentLinks (institutionId, studentId, classes, trails) {
    const data = {
      user_id: studentId,
      institution_id: institutionId,
      classes,
      trails
    }

    if (!classes) delete data.classes
    if (!trails) delete data.trails

    return BaseRequests.post(URLS.ADD_STUDENT_LINK, data)
  }

  static deleteStudentLinks (institutionId, studentId, classes, trails) {
    const data = {
      user_id: studentId,
      institution_id: institutionId,
      classes,
      trails
    }

    if (!classes) delete data.classes
    if (!trails) delete data.trails

    return BaseRequests.post(URLS.DELETE_STUDENT_LINK, data)
  }

  static validateStudentData (institutionId, email, registry) {
    const body = { institution_id: institutionId, email, registry }
    if (!registry) delete body.registry

    return BaseRequests.post(URLS.VALIDATE_STUDENT_DATA, body)
  }

  static updateUserStatus (institution_id, user_id, active) {
    return BaseRequests.post(URLS.UPDATE_USER_STATUS, { institution_id, user_id, active })
  }
}

export default UsersRequests
