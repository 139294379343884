import { extendObservable } from 'mobx'

import ReportRequest from '../services/requests/ReportRequests'
import { formatBytes, momentDate, themeConfig } from '../Util'

class ReportsStore {
  static titles = {
    institution: themeConfig.terms.instituicoesC,
    'lesson-groups': themeConfig.terms.gruposDeAulaICC,
    courses: 'Cursos',
    disciplines: themeConfig.terms.disciplinasC,
    classes: themeConfig.terms.turmasC,
    curators: 'Curadores',
    tutors: themeConfig.terms.professoresC,
    'content-managers': themeConfig.terms.conteudistasC,
    students: themeConfig.terms.alunosC,
    reports: 'Relatórios',
    'client-reports': 'Relatórios Gerenciais',
    'staff-reports': 'Relatórios Gerais'
  }

  constructor () {
    extendObservable(this, {
      reports_array: [],
      filter: '',
      statusFilter: '',
      dashboard: [],
      loading: false,
      institution_list: undefined,
      selectedInstitution: undefined,
      currentPage: 1,
      numPages: 0,
      pageSize: 10
    })
  }

  /**
   * Busca a URL para exportação de um report
   * @param {String} reportType Path da página de report a obter exportação dos dados
   * @param {Integer} institution ID da instituição
   * @param {Object} filters Objeto com filtros para exportação
   */
  getExportationURL (reportType, institution, filters) {
    return ReportRequest.getExportationURL(reportType, institution, filters)
  }

  getStaffDashboardReportData () {
    this.loading = true
    ReportRequest.getStaffDashboardReport()
      .then(dashboard => {
        return (this.dashboard = dashboard)
      })
      .finally(this.loading = false)
  }

  getDashboardReportData (institution_id) {
    this.loading = true
    ReportRequest.getDashboardReport(institution_id).then(
      data => {
        this.dashboard = data.dashboard
        this.institution_list = data.institution_list
      }
    ).finally(this.loading = false)
  }

  getIntitutionReportData (institution_id) {
    this.reports_array = []
    this.loading = true

    ReportRequest.getInstitutionReport(institution_id).then(institutions => {
      if (institutions.constructor === Array) {
        this.reports_array = institutions.map(institution => this.institution_to_array(institution))
      } else {
        this.reports_array = [this.institution_to_array(institutions)]
      }
    }).finally(() => (this.loading = false))
  }

  getLessonGroupReportData (data) {
    this.reports_array = []
    this.loading = true
    ReportRequest.getLessonGroupReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.lesson_groups.map(lessonGroup => {
        const disciplines_names = lessonGroup.disciplines.join() || 'Sem Vínculo'
        const storage = `${formatBytes(lessonGroup.group_storage)} / ${formatBytes(lessonGroup.owner_storage)}`
        const object = {
          name: lessonGroup.name,
          disciplines: disciplines_names,
          content_manager: lessonGroup.content_manager,
          storage: storage,
          lessons: lessonGroup.lessons,
          status: lessonGroup.status
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getCourseReportData (data) {
    this.reports_array = []
    this.loading = true
    ReportRequest.getCourseReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.courses.map(course => {
        const object = {
          name: course.name,
          disciplines: course.disciplines,
          category: course.category,
          modality: course.modality,
          lessons: course.lessons,
          tutors: course.tutors,
          students: course.students
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getDisciplinesReportData (data) {
    this.reports_array = []
    this.loading = true
    return ReportRequest.getDisciplineReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.disciplines.map(discipline => {
        const object = {
          name: discipline.name,
          lesson_group: discipline.lesson_group || 'Sem Vínculo',
          classes: discipline.classes,
          students: discipline.students,
          tutors: discipline.tutors,
          courses: discipline.courses.join() || `Sem ${themeConfig.terms.cursoC}`
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getStudentsReportData (data) {
    this.reports_array = []
    this.loading = true
    data.students = true
    return ReportRequest.getPeopleReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.students.map(students => {
        const disciplines_classes = (students.disciplines_classes && students.disciplines_classes.join(', ')) || ''
        const last_access = students.last_access ? momentDate(students.last_access) : 'Sem acesso'
        const object = {
          registry: students.registry,
          name: students.name,
          email: students.email,
          status: students.status,
          classes: disciplines_classes,
          last_access: last_access,
          account: students.account
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getCuratorsReportData (data) {
    this.reports_array = []
    this.loading = true
    data.curators = true
    ReportRequest.getPeopleReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.curators.map(curator => {
        const is_content_manager = curator.is_content_manager ? 'Sim' : 'Não'
        const last_access = curator.last_access ? momentDate(curator.last_access) : 'Sem acesso'
        const object = {
          name: curator.name,
          registry: curator.registry || 'Não Informado',
          email: curator.email,
          status: curator.status,
          disciplines: curator.disciplines || 0,
          last_access: last_access,
          is_content_manager: is_content_manager
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getContentManagerReportData (data) {
    this.reports_array = []
    this.loading = true
    data.content_managers = true
    ReportRequest.getPeopleReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.content_managers.map(contentManager => {
        const is_curator = contentManager.is_curator ? 'Sim' : 'Não'
        const last_access = contentManager.last_access ? momentDate(contentManager.last_access) : 'Sem acesso'
        const object = {
          name: contentManager.name,
          registry: contentManager.registry || 'Não informado',
          email: contentManager.email,
          status: contentManager.status,
          lesson_groups: contentManager.lesson_groups || 0,
          last_access: last_access,
          is_curator: is_curator
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getTutorsReportData (data) {
    this.reports_array = []
    this.loading = true
    data.tutors = true
    ReportRequest.getPeopleReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.tutors.map(tutor => {
        const disciplines_names = tutor.disciplines && tutor.disciplines.length > 0
          ? tutor.disciplines.join()
          : 'Sem Vínculo'
        const last_access = tutor.last_access ? momentDate(tutor.last_access) : 'Sem acesso'
        const object = {
          name: tutor.name,
          registry: tutor.registry || 'Não Informada',
          email: tutor.email,
          status: tutor.status,
          classes: tutor.classes,
          last_access: last_access,
          disciplines: [disciplines_names] // Foi trocado para disciplinas porque não existe tutor para curso
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  getClassesReportData (data) {
    this.reports_array = []
    this.loading = true
    ReportRequest.getClassesReport(data).then(res => {
      this.institution_list = res.institution_list
      this.numPages = res.page.num_pages
      this.reports_array = res.classes.map(cls => {
        const period = `${momentDate(cls.begin_date)} - ${momentDate(cls.end_date)}`
        const object = {
          id: cls.id,
          discipline: cls.discipline,
          name: cls.name,
          period: period,
          status: cls.status,
          students: cls.students,
          tutors: cls.tutors
        }
        return object
      })
    }).finally(() => (this.loading = false))
  }

  // ##### Utils #####

  institution_to_array (institution) {
    const object = {
      name: institution.name,
      owner: institution.owner,
      lessons_group: institution.lessons_group,
      storage: formatBytes(institution.storage),
      disciplines: institution.disciplines,
      classes: institution.classes,
      students: institution.students
    }
    return object
  }

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) { // Nível 1
      if (pathList[0] === 'reports') return 'Relatórios'
      if (pathList[0] === 'client-reports') return 'Relatórios Gerenciais'
      if (pathList[0] === 'staff-reports') return 'Relatórios Gerais'
    }
    if (pathList.length === 2 || pathList.length === 3) { // Nível 2 e 3
      return ReportsStore.titles[pathList[1]]
    }
    throw new Error(`${ReportsStore.name} não está configurado para o caminho ${window.location.pathname}`)
  }

  getLinks (links) {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) { // Nível 1
      if (pathList[0] === 'reports') {
        return [
          { title: 'Relatórios', address: `/${pathList[0]}/${window.location.search}` }
        ]
      }
    }
    if (pathList.length === 2) { // Nível 2
      if (pathList[0] === 'client-reports') {
        return [
          { title: 'Relatórios Gerenciais', address: links[0] ? links[0].address : `/${pathList[0]}/` },
          { title: ReportsStore.titles[pathList[1]], address: `/${pathList[0]}/${pathList[1]}/${window.location.search}` }
        ]
      }
      if (pathList[0] === 'staff-reports') {
        return [
          { title: 'Relatórios Gerais', address: links[0] ? links[0].address : `/${pathList[0]}/` },
          { title: ReportsStore.titles[pathList[1]], address: `/${pathList[0]}/${pathList[1]}/${window.location.search}` }
        ]
      }
    }
    if (pathList.length === 3) { // Nível 3
      if (pathList[0] === 'client-reports') {
        return [
          { title: 'Relatórios Gerenciais', address: links[0] ? links[0].address : `/${pathList[0]}/` },
          { title: ReportsStore.titles[pathList[1]], address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${window.location.search}` }
        ]
      }
      if (pathList[0] === 'staff-reports') {
        return [
          { title: 'Relatórios Gerais', address: links[0] ? links[0].address : `/${pathList[0]}/` },
          { title: ReportsStore.titles[pathList[1]], address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${window.location.search}` }
        ]
      }
    }
    throw new Error(`${ReportsStore.name} não está configurado para o caminho ${window.location.pathname}`)
  }

  setSelectedInstitution (institution) {
    this.selectedInstitution = institution
  }
}

export default new ReportsStore()
