import { extendObservable } from 'mobx'

export default class RankingModel {
  constructor (data) {
    this.aluno = data.aluno // Dado vindo do firebase.
    this.turmas = data.turmas // Dado vindo do firebase.
    this.disciplines = data.disciplinas // Dado vindo do firebase.
    extendObservable(this, {
      studentRoom: undefined
    })
  }
}
