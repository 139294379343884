import UserModel from './UserModel'

export default class LessonsGroupModel {
  lessonsModel = []

  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.panel = data.panel
    this.owner = data.owner ? new UserModel(data.owner) : null
    // this.curatorship_user = data.curatorship_user
  }

  get percentage_complete () {
    let result = 0
    if (this.lessonsModel) {
      result = this.lessonsModel.reduce((accum, less, index, array) => accum + less.percentage_complete, 0) / this.lessonsModel.length
    }
    return result
  }
}
