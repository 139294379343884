import BaseRequests, { URLS } from './BaseRequests'

class CourseRequests {
  static getCourses (institution, disciplines = false, tutor = false) {
    const data = { institution }
    if (disciplines) data.disciplines = true
    if (tutor) data.tutor = true
    return BaseRequests.post(URLS.GET_COURSE, data)
  }

  static get (institution, id, disciplines = false, students = false, tutor = false) {
    const data = { institution, id }
    if (disciplines) data.disciplines = true
    if (students) data.students = true
    if (tutor) data.tutor = true
    return BaseRequests.post(URLS.GET_COURSE, data)
      .then(response => response.data)
  }

  static getDomains (institution) {
    const data = { institution, knowarea: 'KNOWAREA' }
    return BaseRequests.post(URLS.GET_DOMAIN, data)
  }

  static update (institution, values) {
    const data = { institution, ...values }
    return BaseRequests.post(URLS.UPDATE_COURSE, data)
  }

  static delete (institution, id) {
    const data = { id, institution }
    return BaseRequests.post(URLS.DELETE_COURSE, data)
  }

  static save (institution, name, knowarea) {
    const data = { institution, name, knowarea }
    return BaseRequests.post(URLS.NEW_COURSE, data)
  }
}

export default CourseRequests
