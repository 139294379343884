import { decorate, observable } from 'mobx'

export default class GamificationConfigModel {
  constructor (data) {
    this.ranking = new Ranking(data.ranking)
    this.conquest = new Conquest(data.conquest)
  }
};

class Ranking {
  constructor (rankingConfig) {
    this.id = rankingConfig.id
    this.active = rankingConfig.active
    this.resources = rankingConfig.resources.map(resource => new RankingResource(resource))
  }
}

class RankingResource {
  constructor (resource) {
    this.id = resource.id
    this.name = resource.name
    this.cod = resource.cod
    this.points = resource.points
    this.active = resource.active
  }
}

class Conquest {
  constructor (conquestConfig) {
    this.id = conquestConfig.id
    this.active = conquestConfig.active
    this.groups = conquestConfig.groups.map(group => new ConquestGroup(group))
  }
}

class ConquestGroup {
  constructor (group) {
    this.id = group.id
    this.name = group.name
    this.icon = group.icon
    this.active = group.active
    this.items = group.items.map(item => new GroupItem(item))
  }
}

class GroupItem {
  constructor (item) {
    this.id = item.id
    this.name = item.name
    this.description = item.description
    this.badge_code = item.badge_code
    this.points = item.points
    this.cod = item.cod
    this.criterion = item.criterion
    this.multiplier = item.multiplier
    this.active = item.active
  }
}

decorate(Ranking, {
  active: observable
})

decorate(RankingResource, {
  active: observable
})

decorate(Conquest, {
  active: observable
})

decorate(ConquestGroup, {
  active: observable
})

decorate(GroupItem, {
  active: observable
})
