import BaseRequests, { API } from './BaseRequests'
import UserStore from '../../stores/UsersStore'

class TrailsRequests {
  static createTrail (params) {
    const institution = UserStore.institutionId
    const data = { ...params, institution }
    return BaseRequests.post(`${API}/trails`, data)
  }

  static updateTrail (trailToEdit, newData) {
    const institution = UserStore.institutionId
    const queryString = `?institution=${institution}`
    const trailId = trailToEdit.id
    return BaseRequests.put(`${API}/trails/${trailId}${queryString}`, newData)
  }

  static deleteTrail (trailId) {
    const institution = UserStore.institutionId
    const queryString = `?institution=${institution}`
    return BaseRequests.delete(`${API}/trails/${trailId}${queryString}`)
  }

  static getTrails (search, page = 1, limit = 10, userId) {
    let queryString = ''
    const institution = UserStore.institutionId

    if (search !== '' && search !== undefined) {
      queryString += `${queryString ? '&' : '?'}search=${search}`
    }
    if (page) {
      queryString += `${queryString ? '&' : '?'}page=${page}`
    }
    if (limit) {
      queryString += `${queryString ? '&' : '?'}limit=${limit}`
    }
    if (institution) {
      queryString += `${queryString ? '&' : '?'}institution=${institution}`
    }
    if (userId) {
      queryString += `${queryString ? '&' : '?'}user=${userId}`
    }

    return BaseRequests.get(`${API}/trails/${queryString}`)
  }

  static getTrail (trailId) {
    const institution = UserStore.institutionId
    const queryString = `?institution=${institution}`
    return BaseRequests.get(`${API}/trails/${trailId}${queryString}`)
  }

  static linkTrailToStudent (students, trailId, method) {
    const institution = UserStore.institutionId
    const queryString = `?institution=${institution}`
    const add = method === 'add'
    const remove = method === 'remove'
    const data = {
      students,
      add,
      remove
    }

    if (!add) delete data.add
    if (!remove) delete data.remove
    return BaseRequests.post(`${API}/trails/${trailId}/enrollment${queryString}`, data)
  }
}

export default TrailsRequests
