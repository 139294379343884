import React from 'react'
import { observer } from 'mobx-react'

/**
 * @Author: Michel
 * @Date: 2018-07-14
 * @Desc: feedback to load
 * @props:
 *        visible: show or not component
 */
const LoaderComponent = props =>
  <div className="profile-main-loader" id="temp_load_icon" onClick={e => e.stopPropagation()} hidden={props.hidden}>
    <div className="loader">
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#ff0000" strokeWidth="7"/>
      </svg>
    </div>
    {/* componente para bloquear a tela */}
    <div className="block-loader" hidden={!props.rejectClicks}></div>
  </div>
export default observer(LoaderComponent)
