import { extendObservable, action, runInAction } from 'mobx'
import TrailsRequests from '../services/requests/TrailsRequests'
import TrailModel from '../models/TrailModel'

class TrailStore {
  constructor () {
    extendObservable(this, {
      selectedTrail: null,
      loading: false,
      error: null,
      fetchTrail: action(this.fetchTrail.bind(this)),
      getTitle: action(this.getTitle.bind(this)),
      getLinks: action(this.getLinks.bind(this))
    })
  }

  async fetchTrail (trailId) {
    this.loading = true
    try {
      const response = await TrailsRequests.getTrail(trailId)
      runInAction(() => {
        this.selectedTrail = new TrailModel(response.data)
      })
      return response
    } catch (error) {
      runInAction(() => {
        this.error = error
      })
      throw error
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1 && pathList[0] === 'trails') {
      return 'Trilha de Conhecimento'
    }
    if (this.selectedTrail) {
      return this.selectedTrail.name
    }
    return 'Carregando...'
  }

  getLinks (links) {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1 && pathList[0] === 'trails') {
      return [
        { title: 'Trilha de Conhecimento', address: `/${pathList[0]}/${window.location.search}` }
      ]
    }
    if (this.selectedTrail) {
      return [
        { title: 'Trilhas', address: links[0] ? links[0].address : `/${pathList[0]}/` },
        { title: this.selectedTrail.name, address: `/${pathList[0]}/${this.selectedTrail.id}/${window.location.search}` }
      ]
    }
    return links
  }
}

export default new TrailStore()
