import axios from 'axios'
import Cookies from 'js-cookie'
import { DOMAIN, PORT, IGNORE_REVERSE_PROXY } from '../../settings'
class BaseRequests {
  static async customPost (url, data) {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'dSuCvy7J1iay3Qt69qEd5vg7aBTNCcU3IfXeHA6h'
      }
    })
    return response
  }

  static getConfig (fileName) {
    const url = BaseRequests.getBucketConfigURL(fileName)
    return axios.get(url)
  }

  static getWithoutCookies (url) {
    return axios.get(BaseRequests.getFullURL(url), { withCredentials: true })
  }

  static get (url, data = {}, ptbr = false) {
    const config = { params: data, ...BaseRequests.configRequest(ptbr) }
    return axios.get(BaseRequests.getFullURL(url), config)
  }

  static post (url, data = {}) {
    if (Cookies.get('csrftoken')) {
      return axios.post(
        BaseRequests.getFullURL(url),
        data,
        BaseRequests.configRequest()
      )
    } else {
      return BaseRequests.getWithoutCookies(URLS.LOGIN).then(() =>
        BaseRequests.post(url, data)
      )
    }
  }

  static put (url, data = {}) {
    if (Cookies.get('csrftoken')) {
      return axios.put(
        BaseRequests.getFullURL(url),
        data,
        BaseRequests.configRequest()
      )
    } else {
      throw new Error('Cookies is required')
    }
  }

  static delete (url, data = {}) {
    const config = { data, ...BaseRequests.configRequest() }
    return axios.delete(BaseRequests.getFullURL(url), config)
  }

  static configRequest (ptbr = false) {
    return {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': Cookies.get('csrftoken'),
        ...(ptbr) && { 'Accept-Encoding': 'br' }
      }
    }
  }

  static getProtocol () {
    return window.location.href.split(':')[0]
  }

  static getFullURL (url) {
    return BaseRequests.getProtocol() + '://' + DOMAIN + (IGNORE_REVERSE_PROXY && PORT ? `:${PORT}` : '') + url
  }

  static getBucketConfigURL (fileName) {
    let name
    switch (DOMAIN) {
    case 'uat':
    case 'prod':
      name = DOMAIN
      break
    default:
      name = 'dev'
    }
    return (
      'https://s3.amazonaws.com/aulapp-' + name + '-static/config/' + fileName
    )
  }

  static uploadAws (formData, url, uploadItem) {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        uploadItem.percentCompleted = percentCompleted
      }
    }
    return axios.post(url, formData, config)
  }
}

export default BaseRequests

const getData = (promise, msgError = 'Error!!') => {
  return promise.then(response => {
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(msgError)
    }
  })
}

export { getData }

export const MANAGER = '/api/webmanager/api'
export const API = '/api'
const PUBLISHER = '/api/publisher/api'
const NOTIFY = '/api/notify/api'
const VIRTUAL_ROOM = '/api/virtual-room'
const HELPER = '/api/helper'
const DISCIPLNES = '/api/disciplines'
const QUESTIONSDB = '/api/questiondb/'
const API_VIDEO_PATH = '/api/video'

export const URLS = {
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  SIGNUP: '/api/signup',
  LOGIN_GUEST: '/api/login-guest',
  PASSWORD_RESET: '/api/password-reset',
  PASSWORD_CHANGE: '/api/password-change',
  SET_CPF: MANAGER + '/user-profile',
  IMPORT_PERSONS: '/api/import-persons',
  GET_IMPORTS: '/api/get-imports',

  DATABASES: '/databases',
  QUESTIONSDB: QUESTIONSDB,
  QUESTIONS_VALIDATE_AND_SAVE: '/validate-questions-file-and-save',
  QUESTIONS_INCORPORE: '/import-questions-file',

  GET_CONTENT_MANAGERS: MANAGER + '/list-content-managers',
  GET_LESSONS_GROUP_USER: MANAGER + '/list-lesson-groups',
  FIND_INSTITUTIONS: MANAGER + '/institutions',
  CLONE_GROUP_TO_INSTITUTION: PUBLISHER + '/clone-group-to-institution',

  GET_VIRTUALROOM_CONFIG: VIRTUAL_ROOM + '/get-config',
  SET_VIRTUALROOM_CONFIG: VIRTUAL_ROOM + '/set-config',
  SET_ACTIVITY_RESULT: MANAGER + '/answers',
  UPDATE_VR_CONFIG: VIRTUAL_ROOM + '/update-vr-config',
  UPDATE_FORUM_CONFIG: VIRTUAL_ROOM + '/update-forum-config',
  UPDATE_FORUM_NOTIFY: VIRTUAL_ROOM + '/update-forum-notify',
  NEW_VIRTUALROOM_CONFIG: VIRTUAL_ROOM + '/new-vr-config',
  DELETE_VR_CONFIG: VIRTUAL_ROOM + '/delete-vr-config',
  GET_VR_CONFIGS_TYPES: VIRTUAL_ROOM + '/get-vr-config',
  GET_TUTOR_CLASSES: VIRTUAL_ROOM + '/get-tutor-classes-alt',
  GET_STUDENT_CLASSES: VIRTUAL_ROOM + '/get-student-classes-alt',
  GET_KNOWAREAS_CATEGORIES: VIRTUAL_ROOM + '/get-knowareas-categories',

  GET_LESSONS: PUBLISHER + '/get-lessons',
  GET_PAGES: PUBLISHER + '/get-pages',

  GET_SUPPORT_MATERIAL: PUBLISHER + '/my-support-material',
  UPDATE_SUPPORT_MATERIAL: PUBLISHER + '/update-support-material-web',
  ADD_SUPPORT_MATERIAL: PUBLISHER + '/new-support-material-web',
  DELETE_SUPPORT_MATERIAL: PUBLISHER + '/delete-support-material',

  USER_INFO: '/api/get-user-info',
  SET_USER_NAME: '/api/set-user-name',
  USER_PERMISSIONS: '/api/get-user-permissions',
  USER_INSTITUTIONS: '/api/get-user-institutions',
  GET_CURRENT_INSTITUTION: '/api/get-current-institution',
  SET_CURRENT_INSTITUTION: '/api/set-current-institution',

  NEW_USER: MANAGER + '/new-user',
  GET_USERS: MANAGER + '/get-users',
  GET_STUDENTS: API + '/classroom/4/students',
  GET_USERS_AND_INVITES: MANAGER + '/get-users-and-invites',
  UPDATE_USER: MANAGER + '/update-user',
  DELETE_USER: MANAGER + '/delete-user',
  GET_STUDENT_LINKS: MANAGER + '/get-student-links',
  GET_INVITE_LINKS: MANAGER + '/get-invite-links',
  ADD_STUDENT_LINK: MANAGER + '/add-student-links',
  DELETE_STUDENT_LINK: MANAGER + '/delete-student-links',
  VALIDATE_STUDENT_DATA: MANAGER + '/validate-student-data',
  UPDATE_USER_STATUS: MANAGER + '/set-user-status',

  NEW_DISCIPLINE: MANAGER + '/new-discipline',
  GET_DISCIPLINES: MANAGER + '/get-disciplines',
  DELETE_DISCIPLINE: MANAGER + '/delete-discipline',
  UPDATE_DISCIPLINE: MANAGER + '/update-discipline',
  SET_TEACHING_PLAN: MANAGER + '/set-teaching-plan',
  SET_TECHNICAL_SHEET: MANAGER + '/set-technical-sheet',

  GET_LESSONS_GROUP: DISCIPLNES + '/get-lessons-groups',
  SET_LESSONS_GROUP: DISCIPLNES + '/set-lessons-group',

  NEW_INVITE: MANAGER + '/new-invite',
  GET_INVITES: MANAGER + '/get-invites',
  UPDATE_INVITE: MANAGER + '/update-invite',
  DELETE_INVITE: MANAGER + '/delete-invite',

  NEW_CLASS: MANAGER + '/new-class',
  GET_CLASS: MANAGER + '/get-classes',
  UPDATE_CLASS: MANAGER + '/update-class',
  DELETE_CLASS: MANAGER + '/delete-class',

  NEW_ANNOUNCEMENT: MANAGER + '/communique/',
  // GET_ANNOUNCEMENTS: MANAGER + '/get-classes',

  NEW_INSTITUTION: MANAGER + '/new-institution',
  GET_INSTITUTION: MANAGER + '/get-institutions',
  DELETE_INSTITUTIONS: MANAGER + '/delete-institution',
  UPDATE_INSTITUTION: MANAGER + '/update-institution',
  NEW_INSTITUTION_EXPRESS: MANAGER + '/new-institution-express',

  GET_CURATORSHIP: MANAGER + '/get-curatorship-deeply',
  UPDATE_CURATORSHIP: MANAGER + '/update-curatorship',
  GET_CURATORSHIP_TYPE: MANAGER + '/get-curatorship-type',
  UPDATE_CURATORSHIP_TYPE: MANAGER + '/update-curatorship-type',
  NEW_CURATORSHIP_TYPE: MANAGER + '/new-curatorship-type',
  DELETE_CURATORSHP_TYPE: MANAGER + '/delete-curatorship-type',

  GET_CERTIFICATE: MANAGER + '/certificate',
  VALIDATE_CERTIFICATE: MANAGER + '/validate-certificate',

  GET_RANKING: MANAGER + '/get-ranking-deeply',
  UPDATE_RANKING: MANAGER + '/update-ranking',

  GET_CONQUEST: MANAGER + '/get-conquest-deeply',
  UPDATE_CONQUEST: MANAGER + '/update-conquest',

  GET_INSTITUTION_REPORT: MANAGER + '/get-institution-report',
  GET_LESSON_GROUP_REPORT: MANAGER + '/get-lessongroup-report',
  GET_COURSE_REPORT: MANAGER + '/get-courses-report',
  GET_DISCIPLINES_REPORT: MANAGER + '/get-discipline-report',
  GET_CLASSES_REPORT: MANAGER + '/get-classes-report',
  GET_PEOPLE_REPORT: MANAGER + '/get-people-report',
  GET_DASHBOARD_REPORT: MANAGER + '/get-dashboard-report',
  GET_STAFF_DASHBOARD_REPORT: MANAGER + '/get-staff-dashboard-report',

  /* URIs de exportação de relatórios */
  EXPORT_INSTITUTION_REPORT: MANAGER + '/export-institution-report',
  EXPORT_LESSON_GROUP_REPORT: MANAGER + '/export-lessongroup-report',
  EXPORT_DISCIPLINES_REPORT: MANAGER + '/export-discipline-report',
  EXPORT_CLASSES_REPORT: MANAGER + '/export-classes-report',
  EXPORT_PEOPLE_REPORT: MANAGER + '/export-people-report',

  NEW_COURSE: MANAGER + '/new-course',
  GET_COURSE: MANAGER + '/get-courses',
  DELETE_COURSE: MANAGER + '/delete-course',
  UPDATE_COURSE: MANAGER + '/update-course',

  NEW_DOMAIN: MANAGER + '/new-domain',
  GET_DOMAIN: MANAGER + '/get-domain',
  DELETE_DOMAIN: MANAGER + '/delete-domain',
  UPDATE_DOMAIN: MANAGER + '/update-domain',

  // Instituição e Integração
  GET_INSTITUTION_CONFIG: MANAGER + '/get-institution-config',
  UPDATE_INTEGRATION_CONFIG: MANAGER + '/update-integration',

  SEND_EMAIL: HELPER + '/send-email',
  GET_HELPER_GROUPS: HELPER + '/get-groups',
  GET_HELPER_TOPIC: HELPER + '/get-content',
  GET_SUBJECTS: HELPER + '/get-subjects',

  GET_NOTIFICATIONS: NOTIFY + '/get-notifications',
  UPDATE_NOTIFICATIONS: NOTIFY + '/update-notifications',
  SEND_NOTIFICATION: NOTIFY + '/new-register',

  VALIDATE_UPLOADED_FILE: '/api/validate-uploaded-file',

  GET_FORUMS_TUTOR: PUBLISHER + '/get-forums-tutor',
  GET_FORUMS_STUDENT: PUBLISHER + '/get-forums-student',
  NEW_TOPIC: PUBLISHER + '/new-forum',
  UPDATE_TOPIC: PUBLISHER + '/update-forum',
  NEW_COMMENT: PUBLISHER + '/new-comment',
  GET_COMMENTS: PUBLISHER + '/get-comments',
  EDIT_COMMENT: PUBLISHER + '/edit-comment',
  DELETE_COMMENT: PUBLISHER + '/delete-comment',
  RECOVER_COMMENT: PUBLISHER + '/recover-deleted-comment',
  LIKE_COMMENT: PUBLISHER + '/like-comment',
  STAR_COMMENT: PUBLISHER + '/star-comment',
  SCHEDULE_FORM: PUBLISHER + '/schedule-forum',
  VALIDATE_COMMENT: PUBLISHER + '/validate-comment',
  REPORT_COMMENT: PUBLISHER + '/report-comment',
  APPROVE_COMMENT: PUBLISHER + '/approve-comment',
  REPROVE_COMMENT: PUBLISHER + '/reprove-comment',
  LESSONS_SET_FORUM_ACTIVE: PUBLISHER + '/set-forum-active-status',
  UPLOAD_FORUM_ATTACHMENT: PUBLISHER + '/new-attachment-web',
  UPLOAD_VIMEO: API_VIDEO_PATH + '/create-video',
  GET_VIMEO_VIDEO_STATUS: API_VIDEO_PATH + '/verify-video/:id',

  GET_EXTERNAL_VIDEO_URL: 'https://externallinks.aulapp.com.br/'

}
