import ResourceModel from './ResourceModel'

export default class LessonsModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.objective = data.objective
    this.resources = data.resources.map(res => new ResourceModel(res))
  }

  get percentage_complete () {
    let result = 0
    // if (this.resources) {
    //   result = this.resources.reduce((accum, res, index, array) => accum + res.percentage_complete, 0) / this.resources.length
    // }

    if (this.resources) {
      const resourceFilter = this.resources.filter(resource => resource.type !== 'MA' && resource.pages_count !== 0)
      result = resourceFilter.reduce((accum, res, index, array) => accum + res.percentage_complete, 0) / resourceFilter.length
    }

    return result
  }
}
