import axios from 'axios'
import moment from 'moment'
import { firebaseDatabase, firebaseImpl, urlFunctions } from '../../settings'

export default class FirebaseRequest {
  // todas as referencias abertas em listener.
  static refsMessages = []

  static post (url, data = {}) {
    data.version_code = 3
    return axios.post(`${urlFunctions}${url}`, data)
  }

  static getToken () {
    return firebaseImpl.auth().currentUser.getIdToken(false)
  }

  static create_base_lessons (
    institution,
    user,
    id_discipline,
    id_turma,
    lessons
  ) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_instituicao: institution.id,
        id_discipline: id_discipline,
        id_turma: id_turma,
        lessons
      }
      return FirebaseRequest.post(`create_base_lessons`, data)
    })
  }

  // url: criar_config_logs
  static createRankingStructure (institution, user, rankingResources) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_instituicao: institution.id,
        logs: rankingResources
      }
      return FirebaseRequest.post(`criar_config_logs`, data)
    })
  }

  // url: criar_config_logs
  static createBaseConquest (institution, user, conquestGroups) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_instituicao: institution.id,
        conquests: conquestGroups,
        id_aluno: user.id
      }
      return FirebaseRequest.post(`create_base_conquista`, data)
    })
  }

  // url: SET_PAGINAS_COMUMIDAS
  static setPaginasConsumidas (
    institution,
    user,
    discipline,
    class_id,
    lesson,
    resource,
    page
  ) {
    // call create lesson when page equals 0
    return FirebaseRequest.getToken().then(token => {
      const data = {
        pagina: page,
        numeroPaginas: resource.pages_count,
        recurso: resource.id,
        // params antigos ver com o jhon quais mandar
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_institution: institution.id,
        user: user.id,
        turma: class_id,
        discipline: discipline.id,
        aula: lesson.id,
        ...resource.progress_measure && { progress_percentage: resource.progress_measure }
      }
      return FirebaseRequest.post(`set_paginas_consumidas`, data)
    })
  }

  // url: GET_PAGINAS_COMUMIDAS
  static getPaginasConsumidas (institution, user, discipline, class_id) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: user.id,
        id_instituicao: institution.id,
        id_turma: class_id,
        id_disciplina: discipline.id
      }
      return FirebaseRequest.post(`get_paginas_consumidas`, data)
    })
  }

  // faz login do usuário no firebase.
  static logaUsuarioFirebase (user) {
    const data = {
      email: user.email,
      institutions: user.institutions,
      id_user: user.id,
      nome: user.name
    }
    return FirebaseRequest.post('/check_user', data)
      .then(response =>
        response.data.exist
          ? firebaseImpl
            .auth()
            .signInWithEmailAndPassword(user.email, user.hash)
          : firebaseImpl
            .auth()
            .createUserWithEmailAndPassword(user.email, user.hash)
      )
      .then(auth => (user.uid = auth.user.uid))
  }

  // url: GET_CONQUISTAS
  static getConquests (institution, user) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: user.id,
        id_instituicao: institution.id
      }
      return FirebaseRequest.post(`get_conquistas`, data).then(
        result => result.data
      )
    })
  }

  // url: msg_to_all
  static sendMessageClass (institution_id, user, class_id, receivers, message) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        sender: { id: user.id, name: user.name },
        instituicao_id: institution_id,
        turma_id: class_id,
        message: message,
        receivers: receivers.map(({ name, id }) => ({ name, id }))
      }
      return FirebaseRequest.post(`msg_to_all`, data).then(
        result => result.data
      )
    })
  }

  // url: GET_CONQUISTAS NOVAS
  static getNewConquests (institution, user) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: user.id,
        id_instituicao: institution.id
      }
      return FirebaseRequest.post(`get_conquistas_novas`, data).then(
        result => result.data
      )
    })
  }

  // url: GET_RANKING
  static getRanking (institution, user, panelClasses) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: user.id,
        id_instituicao: institution.id,
        disciplines: panelClasses.map(panel_class => panel_class.discipline.id)
      }
      return FirebaseRequest.post(`get_ranking`, data).then(
        result => result.data
      )
    })
  }

  // url: GET_PORCENTAGEM_DISCIPLINA
  static getPercentageCompleteDiscipline (institution, user, disciplines, trailId) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: user.id,
        id_instituicao: institution.id,
        list_turma_disciplina: disciplines.map(discipline => {
          return {
            id_turma: trailId ? discipline.clas : discipline.classes[0].id,
            id_disciplina: trailId ? discipline.discipline : discipline.id
          }
        }
        )
      }
      return FirebaseRequest.post(
        `get_porcentagem_concluida_disciplina`,
        data
      ).then(result => result.data)
    })
  }

  // url: GET_PORCENTAGEM_DISCIPLINA
  static calculateDisciplineProgress (institution, student, disciplines, trailId) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: student.uid,
        user_fire_ui_token: token,
        student: student.id,
        institution: institution.id,
        disciplines: disciplines.map(discipline => {
          return {
            class: trailId ? discipline.clas : discipline.classes[0].id,
            discipline: trailId ? discipline.discipline : discipline.id
          }
        })
      }
      return FirebaseRequest.post(`calculate_discipline_progress`, data)
        .then(result => result.data)
    })
  }

  // url: GET_PROGRESSO_DESEMPENHO_COMPARACAO_DISCIPLINA
  static getProgressPerformanceDiscipline (
    institution,
    user,
    list_turma_disciplina,
    id_student = 0
  ) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_aluno: id_student,
        id_instituicao: institution.id,
        list_turma_disciplina: list_turma_disciplina
      }
      return FirebaseRequest.post(
        `get_progresso_desempenho_comparacao_disciplina`,
        data
      ).then(result => result.data)
    })
  }

  static getStudentsByDiscipline (
    institution,
    user,
    id_discipline,
    id_class,
    students
  ) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_instituicao: institution.id,
        id_discipline: id_discipline,
        id_turma: id_class,
        students: students
      }
      return FirebaseRequest.post(`get_alunos_por_aula`, data).then(
        result => result.data
      )
    })
  }

  // url: SET_ATIVIDADES
  static setActivities (
    institution,
    user,
    class_id,
    discipline_id,
    lesson_id,
    resource_id,
    questoes,
    acertos,
    tentativa,
    life,
    tempo_gasto
  ) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_institution: institution.id,
        id_user: user.id,
        id_recurso: resource_id,
        id_turma: class_id,
        id_discipline: discipline_id,
        id_aula: lesson_id,
        questoes: questoes,
        life: life,
        tentativa: tentativa,
        tempo_gasto: tempo_gasto
      }
      return FirebaseRequest.post(`set_atividades`, data)
    })
  }

  // url: GET_ATIVIDADES
  static getActivities (
    institution,
    user,
    class_id,
    discipline_id,
    lesson_id,
    resource_id
  ) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        id_institution: institution.id,
        id_user: user.id,
        id_recurso: resource_id,
        id_turma: class_id,
        id_discipline: discipline_id,
        id_aula: lesson_id
      }
      return FirebaseRequest.post(`get_atividades`, data)
    })
  }

  // url: SET_LOG
  static setLog (institution, user, target_id, class_id, discipline_id, type) {
    return FirebaseRequest.getToken().then(token => {
      const data = {
        user_fire_ui_uid: user.uid,
        user_fire_ui_token: token,
        type: type,
        id_instituicao: institution.id,
        id_aluno: user.id,
        id_turma: class_id,
        id_discipline: discipline_id,
        id_target: target_id
      }
      return FirebaseRequest.post(`set_log`, data)
    })
  }

  /**
   * Envia msg.
   *
   * userSender: Quem envia.
   * userReceiver: Quem recebe.
   * classId: Id da turma do usuário.
   * message: Texto da mensagem.
   */
  static sendMessage (
    institutionId,
    userSender,
    userReceiver,
    classId,
    message,
    timestamp,
    flow
  ) {
    let path

    if (flow === 'receiverSender') {
      path = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${userReceiver.id}_chat_com_id_${userSender.id}/${timestamp}`
    } else {
      path = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${userSender.id}_chat_com_id_${userReceiver.id}/${timestamp}`
    }
    return firebaseDatabase
      .ref(path)
      .set({
        lida: false,
        message,
        receiver: userReceiver.name,
        receiverIdBack: userReceiver.id,
        sender: userSender.name,
        senderIdBack: userSender.id,
        timestamp,
        type: 'CHAT'
      })
      .then(() => timestamp)
  }

  /**
   *
   * @param {*institutionId que o usuário está logado* }
   * @param {*sender enviou a msg*}
   * @param {*receiver recebeu a msg*}
   * @param {*classId relacionada a conversa*}
   */
  static readMessages (institutionId, sender, receiver, classId) {
    const ref1 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${sender}_chat_com_id_${receiver}/`
    const ref2 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${receiver}_chat_com_id_${sender}/`

    firebaseDatabase
      .ref(ref1)
      .once('value')
      .then(datasnpshot => {
        if (datasnpshot.exists()) {
          datasnpshot.forEach(child => {
            const message = child.val()
            if (message.receiverIdBack === receiver && !message.lida) {
              message.lida = true
              firebaseDatabase.ref(`${ref1}/${message.timestamp}`).set(message)
            }
          })
        }
      })
    firebaseDatabase
      .ref(ref2)
      .once('value')
      .then(datasnpshot => {
        if (datasnpshot.exists()) {
          datasnpshot.forEach(child => {
            const message = child.val()
            if (message.receiverIdBack === receiver && !message.lida) {
              message.lida = true
              firebaseDatabase.ref(`${ref2}/${message.timestamp}`).set(message)
            }
          })
        }
      })
  }

  /**
   *
   * @param {*institutionId que o usuário está logado* }
   * @param {*sender enviou a msg*}
   * @param {*receiver recebeu a msg*}
   * @param {*classId relacionada a conversa*}
   */
  static readMessage (institutionId, sender, receiver, classId, keyMessage) {
    const ref1 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${sender}_chat_com_id_${receiver}/${keyMessage}`
    const ref2 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${receiver}_chat_com_id_${sender}/${keyMessage}`

    firebaseDatabase
      .ref(ref1)
      .once('value')
      .then(datasnpshot => {
        if (datasnpshot.val()) {
          const message = datasnpshot.val()
          message.lida = true
          firebaseDatabase.ref(ref1).set(message)
        }
      })
    firebaseDatabase
      .ref(ref2)
      .once('value')
      .then(datasnpshot => {
        if (datasnpshot.val()) {
          const message = datasnpshot.val()
          message.lida = true
          firebaseDatabase.ref(ref2).set(message)
        }
      })
  }

  /**
   * Lista msgs.
   *
   * user1: Quem envia.
   * user2: Quem recebe.
   */
  static listMessages (institutionId, user1, user2, classId, callback) {
    const ref1 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${user1.id}_chat_com_id_${user2.id}/`
    const ref2 = `instituicao_${institutionId}/tutoria/turma_${classId}/id_${user2.id}_chat_com_id_${user1.id}/`

    // verifica na ordem de user1 para user2
    // o REF1 possui os dados da conversa
    FirebaseRequest.refsMessages.push(
      firebaseDatabase.ref(ref1).on('value', snapshot => {
        if (snapshot.val()) {
          callback({
            messages: snapshot.val(),
            classId,
            ref: ref1,
            flow: 'senderReceiver'
          })
        }
      })
    )

    FirebaseRequest.refsMessages.push(
      firebaseDatabase.ref(ref2).on('value', snapshot => {
        if (snapshot.val()) {
          callback({
            messages: snapshot.val(),
            classId,
            ref: ref2,
            flow: 'receiverSender'
          })
        }
      })
    )
  }

  // remove o listener de todo o array de itens.
  static removeListenerMessages () {
    FirebaseRequest.refsMessages.forEach(ref => ref.off(ref))
  }

  static readNotification (userEmail, institutionId, notificationId) {
    const ref = `notifications/${userEmail}/institution_${institutionId}/records/${notificationId}`.replace(
      /\./g,
      '_'
    )

    firebaseDatabase.ref(ref).update({
      read: true
    })
  }

  static getNotifications (userEmail, institutionId, limit, callback) {
    const ref = `notifications/${userEmail}/institution_${institutionId}/records`.replace(
      /\./g,
      '_'
    )

    firebaseDatabase
      .ref(ref)
      .limitToLast(limit + 1)
      .on('value', snapshot => {
        if (snapshot.numChildren() > 0) {
          callback(
            snapshot.val(),
            !(snapshot.numChildren() <= limit)
          )
        } else {
          callback(false)
        }
      })
  }

  static sendNotification (userEmail, institutionId, message) {
    const ref = `notifications/${userEmail}/institution_${institutionId}/records`.replace(
      /\./g,
      '_'
    )

    firebaseDatabase.ref(ref).push({
      created_at: moment().format(),
      read: false,
      message
    })
  }
}
