import EnrollmentModel from './EnrollmentModel'
import LessonsGroupModel from './LessonsGroupModel'
import { extendObservable } from 'mobx'

export default class UserModel {
  institutions

  constructor (data) {
    this.id = data.id
    this.uid = data.uid
    this._name = data.name
    this.email = data.email
    this.hash = data.hash
    this.profile_permissions = data.profile_permissions
    this.institutions = data.institutions || []
    this.institution = data.institution ? new EnrollmentModel(data.institution) : null
    this.lessons_groups = data.lessons_groups ? data.lessons_groups.map(lessons_group => new LessonsGroupModel(lessons_group)) : []
    this.activeEnrollment = data.active_enrollment
    if (data.class) {
      this.endDate = data.class.end_date
      this.startDate = data.class.start_date
    }
    extendObservable(this, { progress: 0, performance: 0 })
  }

  get name () {
    if (!this._name) {
      return this.email.split('@', 1)[0]
    }
    return this._name
  };
}
