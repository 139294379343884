import { extendObservable } from 'mobx'

import GamificationConfigModel from '../models/GamificationConfigModel'
import GamificationRequests from '../services/requests/GamificationRequests'

class GamificationStore {
  constructor () {
    extendObservable(this, {
      loading: false,
      ready: undefined,
      config: undefined
    })
  }

  getConfig (institution) {
    this.loading = true
    return GamificationRequests.getConfig(institution)
      .then(response => this.config = new GamificationConfigModel(response))
      .then(() => this.ready = true)
      .finally(() => this.loading = false)
  }

  toggleRanking (institution) {
    this.loading = true
    this.config.ranking.active = !this.config.ranking.active
    const config = {
      active: this.config.ranking.active
    }
    return GamificationRequests.updateRanking(institution, config)
      .finally(() => this.loading = false)
  }

  toggleRankingResource (institution, key) {
    this.loading = true
    this.config.ranking.resources[key].active = !this.config.ranking.resources[key].active
    const config = {
      active: this.config.ranking.resources[key].active,
      resources: [this.config.ranking.resources[key].id]
    }
    return GamificationRequests.updateRanking(institution, config)
      .finally(() => this.loading = false)
  }

  toggleConquest (institution) {
    this.loading = true
    this.config.conquest.active = !this.config.conquest.active
    const config = {
      active: this.config.conquest.active
    }
    return GamificationRequests.updateConquest(institution, config)
      .finally(() => this.loading = false)
  }

  toggleConquestGroup (institution, key) {
    this.loading = true
    this.config.conquest.groups[key].active = !this.config.conquest.groups[key].active
    const config = {
      active: this.config.conquest.groups[key].active,
      conquestGroups: [this.config.conquest.groups[key].id]
    }
    return GamificationRequests.updateConquest(institution, config)
      .finally(() => this.loading = false)
  }

  toggleConquestGroupItem (institution, key, keyItem) {
    this.loading = true
    this.config.conquest.groups[key].items[keyItem].active = !this.config.conquest.groups[key].items[keyItem].active
    const config = {
      active: this.config.conquest.groups[key].items[keyItem].active,
      groupId: this.config.conquest.groups[key].id,
      conquestGroupItens: [this.config.conquest.groups[key].items[keyItem].id]
    }
    return GamificationRequests.updateConquest(institution, config)
      .finally(() => this.loading = false)
  }
}

export default new GamificationStore()
