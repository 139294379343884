import BaseRequests, { getData, URLS } from './BaseRequests'
import { themeConfig } from '../../Util'

export default class ForumRequests {
  static getLessons (discipline_id) {
    const data = { discipline_id }
    return getData(BaseRequests.post(URLS.GET_LESSONS, data), `Erro ao buscar ${themeConfig.terms.aulas}.`)
  }

  static newTopic (data) {
    return getData(BaseRequests.post(URLS.NEW_TOPIC, data), 'Erro ao criar fórum.')
  }

  static updateTopic (data) {
    return getData(BaseRequests.post(URLS.UPDATE_TOPIC, data), 'Erro ao editar fórum.')
  }

  static getForumsTutor (institution_id, user_id, group_id, discipline_id, classId) {
    const data = { institution_id, user_id, group_id, discipline_id, class_id: classId }
    return getData(BaseRequests.post(URLS.GET_FORUMS_TUTOR, data), 'Erro ao buscar fóruns.')
  }

  static getForumsStudent (institution_id, user_id, group_id) {
    const data = { institution_id, user_id, group_id }
    return getData(BaseRequests.post(URLS.GET_FORUMS_STUDENT, data), 'Erro ao buscar fóruns.')
  }

  static newComment (forum_id, parent_id, content, attachments, role_comment, institution_id) {
    const data = { forum_id, parent_id, content, role_comment, institution_id }
    if (attachments && attachments.length) data.attachment_id = attachments
    return getData(BaseRequests.post(URLS.NEW_COMMENT, data), 'Erro ao criar novo comentário.')
  }

  static getComments (forum_id, parent_id) {
    const data = forum_id ? { forum_id } : { parent_id }
    return getData(BaseRequests.post(URLS.GET_COMMENTS, data), 'Erro ao obter comentários.')
  }

  static editComment (id, content, attachment_id) {
    const data = { id, content, attachment_id }
    return getData(BaseRequests.post(URLS.EDIT_COMMENT, data), 'Erro ao editar comentário.')
  }

  static deleteComment (id, user_id) {
    const data = { id, user_id }
    return getData(BaseRequests.post(URLS.DELETE_COMMENT, data), 'Erro ao excluir comentário.')
  }

  static recoverComment (id) {
    const data = { id }
    return getData(BaseRequests.post(URLS.RECOVER_COMMENT, data), 'Erro ao recuperar comentário.')
  }

  // institution with notification
  static likeComment (id, institution) {
    const data = { id }
    return getData(BaseRequests.post(URLS.LIKE_COMMENT, data), 'Erro ao dar like ao comentário.')
  }

  // institution with notification
  static starComment (id, star, institution) {
    const data = { id, star }
    return getData(BaseRequests.post(URLS.STAR_COMMENT, data), 'Erro ao dar estrela ao comentário.')
  }

  static scheduleForum (id, start_date, end_date) {
    const data = { id, start_date, end_date }
    return getData(BaseRequests.post(URLS.SCHEDULE_FORM, data), 'Erro ao agendar fórum.')
  }

  static validateComment (id) {
    const data = { id }
    return getData(BaseRequests.post(URLS.VALIDATE_COMMENT, data), 'Erro ao validar comentário.')
  }

  // institution with notification
  static reportComment (id, institution) {
    const data = { id }
    return getData(BaseRequests.post(URLS.REPORT_COMMENT, data), 'Erro ao reportar comentário.')
  }

  static approveComment (id) {
    const data = { id }
    return getData(BaseRequests.post(URLS.APPROVE_COMMENT, data), 'Erro ao aprovar comentário.')
  }

  static reproveComment (id, user_id) {
    const data = { id, user_id }
    return getData(BaseRequests.post(URLS.REPROVE_COMMENT, data), 'Erro ao reprovar comentário.')
  }

  static setActiveTopic (id, active) {
    const data = { id, active }
    return getData(BaseRequests.post(URLS.LESSONS_SET_FORUM_ACTIVE, data), 'Erro ao ativar/desativar tópico de fórum.')
  }
}
