import React from 'react'
import logoAulapp from './crop/images/logo-aulapp.svg'
import imgModel from './crop/images/image-model.jpg'
import Script from 'react-load-script'
import './crop/style.css'
import MetaTags from 'react-meta-tags'
import { themeConfig } from '../Util'

export default function Crop () {
  return (
    <>
      <Script url={`${process.env.PUBLIC_URL}/js/crop-scripts.js`} />
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </MetaTags>
      <header className="header container-fluid p-0 mb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar p-0 py-4">
                <img
                  className="navbar-brand"
                  width="120px"
                  src={logoAulapp}
                  alt="Aulapp"
                />
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="d-flex-inline --d-color-dark-gray --d-bold">
              Carregue uma imagem aqui clicando no botão de upload “
              <span className="--d-icon-box">
                <span className="--d-icon-align material-icons">publish </span>
              </span>
              ” depois faça o ajuste preservando a área útil.
            </p>
          </div>

          <div className="col-12 col-xl-8 col-xxxl-9">
            <div className="img-container">
              <img src={imgModel} alt="Model and others" />
            </div>
          </div>

          <div className="col-12 col-xl-4 col-xxxl-3 d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-start flex-xl-column justify-content-xl-start align-items-xl-end">
            <div className="docs-preview d-flex flex-column mb-3 justify-content-center">
              <div className="img-preview preview-lg mb-2"></div>
              <span className="preview-text text-right">Pré-visualização</span>
            </div>

            <div id="actions">
              <div className="docs-buttons d-flex justify-content-center justify-content-md-end">
                <div className="btn-group mr-1">
                  <label
                    className="btn btn-primary btn-upload"
                    htmlFor="inputImage"
                    title="Upload image file"
                  >
                    <input
                      type="file"
                      className="sr-only"
                      id="inputImage"
                      name="file"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                    />
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title="Importar Capa"
                    >
                      publish
                    </span>
                  </label>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-method="getCroppedCanvas"
                    data-option='{ "width": 400, "height": 125 }'
                  >
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Baixar Capa"
                    >
                      get_app
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-method="reset"
                    title="Reset"
                  >
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title="Resetar"
                    >
                      history
                    </span>
                  </button>
                </div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="material-icons btn btn-primary"
                    data-method="zoom"
                    data-option="0.1"
                    title="Zoom In"
                  >
                    <span
                      className="docs-tooltip"
                      data-toggle="tooltip"
                      title="Aumentar Zoom"
                    >
                      zoom_in
                    </span>
                  </button>
                  <button
                    type="button"
                    className="material-icons btn btn-primary"
                    data-method="zoom"
                    data-option="-0.1"
                    title="Zoom Out"
                  >
                    <span
                      className="docs-tooltip"
                      data-toggle="tooltip"
                      title="Diminuir Zoom"
                    >
                      zoom_out
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-method="scaleX"
                    data-option="-1"
                    title="Flip Horizontal"
                  >
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title="Inverter Capa"
                    >
                      compare_arrows
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-method="rotate"
                    data-option="-1"
                    title="Rotate Left"
                  >
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title="Girar para Direita"
                    >
                      rotate_right
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-method="rotate"
                    data-option="1"
                    title="Rotate Right"
                  >
                    <span
                      className="material-icons docs-tooltip"
                      data-toggle="tooltip"
                      title="Girar para Esquerda"
                    >
                      rotate_left
                    </span>
                  </button>
                </div>

                <div
                  className="modal fade docs-cropped"
                  id="getCroppedCanvasModal"
                  role="dialog"
                  aria-hidden="true"
                  aria-labelledby="getCroppedCanvasTitle"
                  tabIndex="-1"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="getCroppedCanvasTitle">
                          Capa do {themeConfig.terms.curso}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body"></div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>

                        <a
                          className="btn btn-primary"
                          id="download"
                          href="#!"
                          download="cropped.jpg"
                        >
                          Baixar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="d-flex-inline --d-color-dark-gray mt-3 mt-xl-4 text-left text-md-right --d-bold">
                Salve a imagem em seu computador clicando no botão de download “
                <span className="--d-icon-box">
                  <span className="--d-icon-align material-icons">
                    get_app{' '}
                  </span>
                </span>
                ”, volte {themeConfig.terms.aoSeuGrupoDeAula} e faça o Upload da imagem salva.
              </p>

              <div className="docs-toggles"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
