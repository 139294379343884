import BaseRequests, { MANAGER, API } from './BaseRequests'

class LiveClassRequests {
  static getData ({ institution_id, discipline }) {
    return BaseRequests.get(`${MANAGER}/${institution_id}/${discipline}/classes`)
      .then(response => {
        return response
      })
  }

  static createLiveClass (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution}/tutor/rooms`, params)
      .then(response => {
        return response
      })
  }

  static getLiveClasses (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/${params.role}/lessons?page=${params.page}&limit=${params.limit}&before=${params.before}&after=${params.after}&discipline=${params.discipline}`)
      .then(response => {
        return response
      })
  }

  static getLiveClass (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/tutor/lessons/${params.id}`)
      .then(response => {
        return response
      })
  }

  static getRoom (params, room) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/${params.tutor ? 'tutor' : 'student'}/rooms/${room}`)
      .then(response => {
        return response
      })
  }

  static updateLiveClasses (params) {
    return BaseRequests.put(`${API}/classroom/${params.institution_id}/tutor/${(params.edit === '1' && !params.recurrences) ? 'lessons' : 'rooms'}/${(params.edit === '1' && !params.recurrences) ? params.id : params.room}`, params)
      .then(response => {
        return response
      })
  }

  static deleteLiveClasses (params) {
    return BaseRequests.delete(`${API}/classroom/${params.institution_id}/tutor/${params.opt === 1 ? 'lessons' : 'rooms'}/${params.opt === 1 ? params.id : params.room}`, {})
      .then(response => {
        return response
      })
  }

  static createStudentAttendance (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution_id}/attendance/${params.lesson_id}`, params)
      .then(response => {
        return response
      })
  }

  static updateStudentAttendance (params) {
    return BaseRequests.put(`${API}/classroom/${params.institution_id}/attendance/${params.lesson_id}/${params.user_id}`, params)
      .then(response => {
        return response
      })
  }

  static bulkAttendance (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution_id}/attendance/${params.lesson_id}/${params.classroom}`, params)
      .then(response => {
        return response
      })
  }

  static getLessonAttendance (params) {
    return BaseRequests.get(`${API}/classroom/${params.institution_id}/attendance-report/${params.lesson_id}`)
      .then((response) => {
        return response
      })
  }

  static setMassAttendance (params) {
    return BaseRequests.post(`${API}/classroom/${params.institution_id}/bulk-attendance/${params.lesson_id}`, {
      students: params.students
    }).then((response) => {
      return response
    })
  }
}

export default LiveClassRequests
