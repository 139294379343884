import { extendObservable } from 'mobx'

export default class LessonPageModel {
  exercise = undefined

  constructor (data) {
    if (!data) return
    this.id = data.id
    this.type = data.type
    this.category = data && data.category ? data.category : null
    data.exercise && (data.exercise.category = data && data.category ? data.category : null)
    this.comps = data.comps.map(comp => new Comp(comp))
    if (data.exercise) this.exercise = new Exercise(data.exercise)
  }
}

class Exercise {
  hadFirstResponse = false

  // verifica se já teve a primeira resposta para não enviar novamente ao server.
  canRedo = true

  // verifica se pode refazer atividade após responder.
  jump = true // verifica se pode pular atividade e não responder.

  userAnswers = undefined

  index = undefined // indice da atividade na página.

  hits = 0

  errors = 0 // acertos e erros.

  constructor (data) {
    console.log(data)
    if (!data) return
    this.id = data.id
    this.type = data.type
    this.label = data.label
    this.answers = data.answers.map(answer => new Answer(answer))
    this.comment = data.comment ? new Comment(data.comment) : null
    this.category = data.category || null
    extendObservable(this, {
      verified: false
    })
  }

  // retorna a média da nota por atividade.
  get average () {
    const total = this.hits + this.errors
    return this.hits / total
  }
}

class Comment {
  constructor (data) {
    if (!data) return
    this.id = data.id
    this.exerciseId = data.exercise_id
    this.comps = data.components.map(comp => new Comp(comp))
  }
}

class Answer {
  constructor (data) {
    if (!data) return
    this.id = data.id
    this.correct = data.correct
    this.feedback = data.feedback
    this.margin = data.emargin // para atividades numéricas
    this.value = data.value // para atividades numéricas.
    this.comps = data.comps.map(comp => new Comp(comp))
    extendObservable(this, {
      checked: false,
      userAnswer: undefined
    })
  }

  isUserAnwerCorrect () {
    return this.correct === this.userAnswer
  }
}

export class Comp {
  // parametros que gravam o quanto o usuário consumiu um determinado recurso.
  arraySeconds = []

  // array que grava todos os segundos visualizados pelo usuário.
  totalSeconds = 0

  // tempo total que o usuário ficou com o player tocando.
  resourceConsumed = false // flag que grava se recurso foi consumido ou não.

  constructor (data) {
    if (!data) return
    this.id = data.id
    this.type = data.type
    this.media_id = data.media_id
    this.page_id = data.page_id
    this.reference = data.reference
    this.show_reference = data.show_reference
    this.size = data.size
    this.text = data.text
    this.title = data.title
    this.url = data.url
    this.transcoding_url = data.transcoding_url ? data.transcoding_url : undefined
    this.transcoding_url_thumb = data.transcoding_url_thumb ? data.transcoding_url_thumb : undefined
    this.transcoding_status = data.transcoding_status ? data.transcoding_status : undefined
    this.media = data.media
    this.avatar = data.avatar ? data.avatar : undefined
    this.file_name = data.file_name
    this.file_size = data.file_size
    this.hidden = data.hidden
  }
}
