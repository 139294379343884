import { extendObservable } from 'mobx'
import ConquestModel from '../models/ConquestModel'
import DisciplineModel from '../models/DisciplineModel'
import KnowareaModel from '../models/KnowareaModel'
import LessonPageModel from '../models/LessonPageModel'
import LessonsModel from '../models/LessonsModel'
import RankingModel from '../models/RankingModel'
import ResourceModel from '../models/ResourceModel'
import SupportMaterialModel from '../models/SupportMaterialModel'
import VirtualRoomConfigModel from '../models/VirtualRoomConfigModel'
import VirtualRoomModel from '../models/VirtualRoomModel'
import VirtualRoomRequest from '../services/requests/VirtualRoomRequest'
import { removeDuplicates, shuffleArray, themeConfig } from '../Util'
import AuthStore from './AuthStore'
import GamificationStore from './GamificationStore'
import RoadBarStore from './RoadBarStore'
import { STUDENT, TUTOR } from './UsersStore'
import _ from 'lodash'
import TrailsRequests from '../services/requests/TrailsRequests'

class VirtualRoomStore {
  // media de referência para calculo de alunos acima ou abaixo da média
  referenceAverage = 50

  pageRemoved = false

  /*************************************************************************************************************/
  /* synchronized data *****************************************************************************************/

  /*************************************************************************************************************/

  constructor () {
    this.state = { scormPoints: 0 }
    extendObservable(this, {
      lessons: [],
      indexPage: 0,
      araryIndices: [],
      discipline: undefined,
      panelClasses: undefined,
      ranking: undefined,
      reports: undefined,
      achievements: undefined,
      pages: undefined,
      materials: undefined,
      loading: false,
      resource: undefined,
      errors: 0,
      errorsByCategory: [],
      gameOver: false,
      tentativas: 0,
      limit: 0,
      unlimited: true,
      comments: { show: false, guides: false, finish: false },
      active_configs: false,
      config: undefined,
      configs: undefined,
      filter: '',
      courseFilter: '',
      classNameKnowCourses: 'navfilter',
      knowareas: [],
      trails: [],
      selectedArea: 0,
      selectedCourse: undefined,
      courses: [],
      categories: [],
      disciplines: [],
      selectedCategory: [],
      progressDisciplines: {},
      pagesCount: 0,
      showLessonContentPage: false,
      trailId: null,
      queryTrailId: '',
      currentDisciplineBYTrail: {},
      disciplinesTrail: {}
    })

    const query = new URLSearchParams(window.location.search)
    this.trailId = query.get('trail')
    this.queryTrailId = !_.isEmpty(this.trailId) ? `?trail=${this.trailId}` : ''
  }

  // retorna a página atual.
  get currentPage () {
    return this.pages[this.araryIndices[this.indexPage]]
  }

  get simulatedFinish () {
    return this.comments.finish
  }

  set simulatedFinish (value) {
    this.comments.finish = value
  }

  get showGuides () {
    return this.comments.guides
  }

  /*************************************************************************************************************/
  /* Configs  **************************************************************************************************/

  /*************************************************************************************************************/

  set showGuides (value) {
    this.comments.guides = value
  }

  get showComment () {
    return this.comments.show
  }

  set showComment (value) {
    this.comments.show = value
  }

  getLessons (class_id, discipline_id) {
    this.loading = true
    return VirtualRoomRequest.getLessons(discipline_id)
      .then(data => {
        this.discipline = new DisciplineModel(data.discipline)
        this.lessons = data.lessons ? data.lessons.map(lesson => new LessonsModel(lesson)) : undefined
        this.discipline.lessons_group.lessonsModel = this.lessons
      })
      .then(() =>
        this.getIndexPage(
          AuthStore.institution_current,
          AuthStore.user,
          this.discipline,
          class_id,
          this.lessons
        )
      )
      .then(() => RoadBarStore.update())
      .then(() =>
        VirtualRoomRequest.create_base_lessons(
          AuthStore.institution_current,
          AuthStore.user,
          discipline_id,
          class_id,
          this.lessons
        )
      )
      .finally(() => (this.loading = false))
  }

  getTutorsClasses (remove_expired = true) {
    return this.getClasses(
      VirtualRoomRequest.getTutorClasses(
        AuthStore.institution_current.id,
        remove_expired
      )
    )
  }

  getStudentsClasses (remove_expired = false) {
    return this.getClasses(
      VirtualRoomRequest.getStudentClasses(
        AuthStore.institution_current.id,
        remove_expired
      )
    )
  }

  getKnowareasCategories (tutor = false, student = false, remove_expired = false, limit = null, category_id = null, limitDiscipline = false, isTrail = false) {
    return this.getClassesKnowareasCategories(
      VirtualRoomRequest.getKnowareaCategory(
        AuthStore.institution_current.id,
        tutor,
        student,
        remove_expired,
        limit,
        category_id,
        limitDiscipline,
        isTrail
      )
    )
  }

  getClassesKnowareasCategories (promiseClasses) {
    this.loading = true
    this.knowareas = []
    return promiseClasses
      .then(response => {
        if (response.data) {
          this.knowareas = response.data.knowareas.map(
            knowarea => new KnowareaModel(knowarea)
          )
          this.categories = this.knowareas.flatMap(knowarea => knowarea.categories)
          this.disciplines = this.categories.flatMap(category => category.disciplines)
          this.trails = response.data.trails

          this.trails.forEach(trail => {
            VirtualRoomRequest.getPercentageCompleteDiscipline(
              AuthStore.institution_current,
              AuthStore.user,
              trail.disciplines,
              true
            ).then(data => {
              this.disciplinesTrail = trail.disciplines
              trail.disciplines.forEach(discipline => {
                const percent = data[discipline.discipline]
                if (percent >= 0) discipline.progress = percent
              })
            })
          })

          return VirtualRoomRequest.getPercentageCompleteDiscipline(
            AuthStore.institution_current,
            AuthStore.user,
            this.disciplines
          ).then(data => {
            this.disciplines.forEach(discipline => {
              const percent = data[discipline.id]
              if (percent) discipline.progress = percent
            })
          })
        }
      })
      .finally(() => (this.loading = false))
  }

  getClasses (promiseClasses) {
    this.loading = true
    this.panelClasses = []
    return promiseClasses
      .then(panelClasses => {
        if (panelClasses) {
          this.panelClasses = panelClasses.map(
            panel => new VirtualRoomModel(panel)
          )
          return VirtualRoomRequest.getPercentageCompleteDiscipline(
            AuthStore.institution_current,
            AuthStore.user,
            panelClasses
          ).then(data =>
            this.panelClasses.forEach(panelClass => {
              const percent = data[panelClass.discipline.id]
              if (percent) panelClass.discipline.progress = percent
            })
          )
        }
      })
      .finally(() => (this.loading = false))
  }

  // Chama o request para buscar as páginas desejadas.
  async getContentPages (class_id, discipline_id, lesson_id, resource_id, role) {
    this.loading = true
    this.pages = undefined
    this.resource = undefined
    this.materials = undefined
    this.indexPage = 0

    return Promise.all([
      // Promise 1: Pode chamar ou não. Havendo lessons não precisa chamar.
      this.lessons.length === 0
        ? await this.getLessons(class_id, discipline_id)
        : null,

      // Promise 2: apenas para chamar o loading novamente
      (this.loading = true),
      // Promise 3: Sempre vai chamar para buscar as páginas e se for tutor busca materiais de apoio
      await VirtualRoomRequest.getContentPages(resource_id)
        .then(data => {
          // Remove páginas que não devem ser exibidas
          data.pages = data.pages.filter(p => p.type !== 'EX.QD')
          this.configPages(data)
        })
        .then(() => this.getSupportMaterial(role)),

      // promise 4: Se for atividades vai buscar as que foram feitas.
      await this.getActivities(class_id, discipline_id, lesson_id, resource_id, {}, { dontSetGameOver: false }),

      // promise 5: fecha o loading
      (this.loading = false)
    ])
  }

  resetConfigs () {
    this.vr_config = undefined
    this.configs = undefined
    this.active_configs = undefined
    if (this.isSimulated()) {
      this.showComment = false
      this.showGuides = false
      this.simulatedFinish = false
    }
  }

  /*************************************************************************************************************/
  /* methods utils *********************************************************************************************/
  /*************************************************************************************************************/

  getMediaVideoStatus (mediaId) {
    this.loading = true
    const data = { media_id: mediaId }
    return VirtualRoomRequest.getMediaVideoStatus(data)
      .then(response => (response))
      .finally(() => (this.loading = false))
  }

  /** Cria uma nova configuração */
  createNewConfig (name) {
    this.loading = true
    const data = { institution: AuthStore.institution_current.id, name }
    return VirtualRoomRequest.createNewConfig(data)
      .then(() => this.getTypeConfigs())
      .finally(() => (this.loading = false))
  }

  /** busca configuração geral da instituição, se possui ou não configuração para sala de aula */
  getConfig () {
    this.loading = true
    const data = {
      institution: AuthStore.institution_current.id,
      key: 'active'
    }
    return VirtualRoomRequest.getConfig(data)
      .then(data => (this.active_configs = data.config))
      .finally(() => (this.loading = false))
  }

  /** Atualiza configuração geral da instituição, se possui ou não configuração para sala de aula */
  setConfig (status) {
    this.loading = true
    const data = {
      institution: AuthStore.institution_current.id,
      key: 'active',
      value: status
    }
    return VirtualRoomRequest.setConfig(data)
      .then(() => this.getConfig())
      .finally(() => (this.loading = false))
  }

  /** busca configurações criadas para a sala virtual, todas ou uma em específico */
  getTypeConfigs (id = undefined) {
    this.loading = true
    const data = { institution: AuthStore.institution_current.id }
    if (id) data.id = id
    return VirtualRoomRequest.getTypeConfigs(data)
      .then(data => {
        if (id) {
          this.vr_config = new VirtualRoomConfigModel(data.vr_config)
        } else {
          this.configs = data.vr_configs.map(
            config => new VirtualRoomConfigModel(config)
          )
        }
        return true
      })
      .finally(() => (this.loading = false))
  }

  /** Atualiza uma configuração (name, active, modalities) */
  updateConfig (id, name, value) {
    this.loading = true
    const data = { institution: AuthStore.institution_current.id, id }
    data[name] = value
    return VirtualRoomRequest.updateConfig(data)
      .then(() => this.getTypeConfigs())
      .finally(() => (this.loading = false))
  }

  /** Deleta uma configuração. */
  deleteConfig (id) {
    this.loading = true
    const data = { institution: AuthStore.institution_current.id, id }
    return VirtualRoomRequest.deleteConfig(data)
      .then(() => this.getTypeConfigs())
      .finally(() => (this.loading = false))
  }

  /** Atualiza configurações de notificação do forum de uma configuração da VR */
  updateForumNotify = (id, key, value) => {
    const data = {
      institution: AuthStore.institution_current.id,
      id,
      items: {}
    }
    data.items[key] = value
    return VirtualRoomRequest.updateForumNotify(data).finally(
      () => (this.loading = false)
    )
  }

  /** Atualiza configurações do forum de uma configuração da VR */
  updateForumConfig = (id, key, value) => {
    const data = {
      institution: AuthStore.institution_current.id,
      id,
      items: {}
    }
    data.items[key] = value
    return VirtualRoomRequest.updateForumConfig(data).finally(
      () => (this.loading = false)
    )
  }

  async getExternalVideoManifest (videoUrl) {
    const response = await VirtualRoomRequest.getExternalVideoManifest(videoUrl)
    return response
  }

  removePage () {
    this.araryIndices = this.araryIndices.filter(
      (item, index) => this.indexPage !== index
    )
    this.pageRemoved = false
  }

  resetPage () {
    this.indexPage = 0
    this.errors = 0
    this.araryIndices = []
    this.gameOver = false
    this.showComment = false
    this.showGuides = false
    this.simulatedFinish = false
    this.showLessonContentPage = false
  }

  lesson (id) {
    // eslint-disable-next-line
    return this.lessons.find(lesson => lesson.id == id)
  }

  // chama página anterior
  prev () {
    const result = this.hasPrev()
    if (this.pageRemoved) {
      this.removePage()
    }
    if (result) {
      this.indexPage--
    } else {
      this.indexPage = this.araryIndices
        ? this.araryIndices[this.araryIndices.length - 1]
        : 0
    }
    this.pageRemoved = false
    if (this.isSimulated()) this.showComment = false
  }

  // chama próxima página.
  next () {
    const hasNext = this.hasNext()
    if (!this.pageRemoved) {
      if (hasNext) {
        this.indexPage++
      } else {
        this.indexPage = 0
      }
    } else {
      this.removePage()
      if (!hasNext) this.indexPage = 0
    }
    if (this.isSimulated()) {
      this.showComment = false
      this.showGuides = false
      if (this.simulatedFinish) this.gameOver = true
    }
    this.pageRemoved = false
  }

  // verifica se tem próximo
  hasNext () {
    if (!this.isValid()) return false
    return this.indexPage + 1 < this.araryIndices.length
  }

  // verifica se tem anterior
  hasPrev () {
    if (!this.isValid()) return false
    return this.indexPage > 0
  }

  /*************************************************************************************************************/
  /* Support Material*******************************************************************************************/

  // verifica se o conteúdo é válido.
  isValid () {
    // eslint-disable-next-line
    return this.pages != []
  }

  // verifica se esta na ultima pagina de atividades
  isLastPage () {
    const _araryIndices = this.araryIndices || []
    if (!this.isValid()) return false
    return this.indexPage + 1 === _araryIndices.length
  }

  // verifica se todas as atividades foram respondidas (se o usuario clicou no botão verificar no final de cada questão)
  hasUndoneLesson () {
    const _pages = this.pages || []
    return (
      _pages.filter(page => page.exercise.verified).length !== _pages.length
    )
  }

  /***************************************************************************************************************/
  /* Conquistas ***************************************************************************************************/

  /*************************************************************************************************************/

  getKnowareas (panelClasses) {
    const array = panelClasses.reduce((acc, panelClass) => {
      panelClass.discipline.knowareas.forEach(knowarea => {
        const item = acc.find(a => knowarea.id === a.id)
        if (item && knowarea.courses) {
          item.children.push(
            ...knowarea.courses.map(c => ({ id: c.id, name: c.name }))
          )
          item.children = removeDuplicates(item.children, 'id')
        } else {
          acc.push({
            id: knowarea.id,
            name: knowarea.name,
            children: knowarea.courses.map(
              c => ({ id: c.id, name: c.name } || [])
            )
          })
        }
      })
      return acc
    }, [])
    return array
  }

  /**
  * @function filterKnowareasCourses Returns courses of a selected knowArea.
  * @param {Array} panelClasses list of panelClasses structure
  * @param {String} knowArea know area filter string
  */
  filterKnowareasCourses (panelClasses, knowArea) {
    if (knowArea === '') return panelClasses.filter(item => item.discipline.knowareas.length > 0)
    return panelClasses.filter(panelClass => {
      if (!panelClass.discipline.knowareas.length) return false
      if (panelClass.discipline.knowareas.some(disciplineKnowArea => disciplineKnowArea.name === knowArea)) return true
      else return false
    })
  }

  /*************************************************************************************************************/
  isSupportMaterial (resource) {
    return resource.type === 'MA'
  }

  /***************************************************************************************************************/
  /* Ranking ***************************************************************************************************/

  /*************************************************************************************************************/

  getSupportMaterial (role = STUDENT) {
    this.loading = true
    return VirtualRoomRequest.getSupportMaterials()
      .then(materials => {
        if (
          this.pages &&
          this.pages[this.araryIndices[this.indexPage]].comps &&
          role !== STUDENT
        ) {
          const array = materials.filter(
            material =>
              !this.pages[this.araryIndices[this.indexPage]].comps.some(
                comp => material.url === comp.url
              )
          )
          this.materials = array.map(
            material => new SupportMaterialModel(material)
          )
        } else {
          this.materials = materials.map(
            material => new SupportMaterialModel(material)
          )
        }
      })
      .finally(() => (this.loading = false))
  }

  updateMaterial (value, name, material) {
    this.loading = true
    const data = { material_id: material.id }
    data[name] = value
    return VirtualRoomRequest.updateSupportMaterial(data)
      .then(() => this.getSupportMaterial(TUTOR))
      .finally(() => (this.loading = false))
  }

  /*************************************************************************************************************/
  /* activity **************************************************************************************************/
  /*************************************************************************************************************/

  removeMaterialResource (resource, comp) {
    this.loading = true
    return VirtualRoomRequest.getSupportMaterials()
      .then(materials => materials.find(material => comp.url === material.url))
      .then(material => {
        const data = {
          resource_id: resource.id,
          component_id: comp.id,
          material_id: material.id
        }
        return VirtualRoomRequest.removeSupportMaterial(data)
      })
      .finally(() => (this.loading = false))
  }

  createBaseConquest () {
    this.loading = true
    return GamificationStore.getConfig(AuthStore.institution_current.id)
      .then(() =>
        VirtualRoomRequest.createBaseConquest(
          AuthStore.institution_current,
          AuthStore.user,
          GamificationStore.config.conquest.groups
        )
      )
      .finally(() => (this.loading = false))
  }

  getConquests () {
    this.loading = true
    return VirtualRoomRequest.getConquests(
      AuthStore.institution_current,
      AuthStore.user
    )
      .then(
        achievements => (this.achievements = new ConquestModel(achievements))
      )
      .then(() => GamificationStore.getConfig(AuthStore.institution_current.id))
      .finally(() => (this.loading = false))
  }

  getCertificate (discipline) {
    return VirtualRoomRequest.getCertificate(AuthStore.institution_current.id, discipline)
  }

  validateCertificate (certificate_key) {
    return VirtualRoomRequest.validateCertificate(certificate_key)
  }

  getNewConquests () {

    // return VirtualRoomRequest.getNewConquests(AuthStore.institution_current, AuthStore.user)
    //   .then(data => {
    //     if (data.new) {
    //       data.awards.forEach(conquest => {
    //         NotificationStore.sendNotification(null, `Você tem uma nova conquista: ${conquest.description}, ${conquest.points} ponto(s).`);
    //       })
    //     }
    //   });
  }

  createRankingStructure () {
    this.loading = true
    return GamificationStore.getConfig(AuthStore.institution_current.id)
      .then(() =>
        VirtualRoomRequest.createRankingStructure(
          AuthStore.institution_current,
          AuthStore.user,
          GamificationStore.config.ranking.resources
        )
      )
      .finally(() => (this.loading = false))
  }

  async getClassesRanking () {
    this.loading = true
    try {
      const panelClasses = await VirtualRoomRequest.getStudentClasses(AuthStore.institution_current.id)
      if (panelClasses) {
        this.panelClasses = panelClasses.map(panel => new VirtualRoomModel(panel))
        const ranking = await VirtualRoomRequest.getRanking(AuthStore.institution_current, AuthStore.user, this.panelClasses)
        this.ranking = new RankingModel(ranking)
      }
    } catch (error) {
    } finally {
      this.loading = false
    }
  }

  // verifica se possui tempo para acabar atividade
  time () {
    if (
      !this.resource ||
      !this.resource.config ||
      !this.resource.config.time_control ||
      !this.resource.config.time_control.on ||
      !this.resource.config.time_control.limit
    ) {
      return 0
    }
    return this.resource.config.time_control.limit
  }

  lifes () {
    if (
      !this.resource ||
      !this.resource.config ||
      !this.resource.config.what_attr ||
      !this.resource.config.what_attr.lifes
    ) {
      return 0
    }
    return parseInt(this.resource.config.what_attr.lifes)
  }

  hasComment (page) {
    if (!this.isSimulated()) return false
    if (page && page.exercise && page.exercise.comment) {
      return page.exercise.comment.comps.some(
        comp => comp.text || comp.url || comp.avatar
      )
    }
    return false
  }

  isGame () {
    return this.resource && this.resource.type === 'GA'
  }

  isTest () {
    return this.resource && this.resource.type === 'TE'
  }

  isListActivities () {
    return this.resource && this.resource.type === 'AC'
  }

  isSimulated () {
    return this.resource && this.resource.type === 'SI'
  }

  isActivities () {
    return (
      this.resource &&
      (this.isGame() ||
        this.isTest() ||
        this.isListActivities() ||
        this.isSimulated())
    )
  }

  // retorna o index contador de cada atividade.
  getIndexActivity () {
    //  let cont = 0;
    //   if(this.pages && this.araryIndices.length > 0) for (let i = 0; i <= this.indexPage; i++) if (this.pages[i].exercise) cont++;
    //   return this.araryIndices ? this.araryIndices[this.indexPage] : 0;
    let cont = 0
    if (this.pages && this.pages.length > 0) {
      for (let i = 0; i <= this.araryIndices[this.indexPage]; i++) {
        if (this.pages[i].exercise) cont++
      }
    }
    return cont
  }

  // configuração das páginas de conteúdo.
  configPages (data) {
    let pages = data.pages
      ? data.pages.map(page => new LessonPageModel(page))
      : undefined
    this.resource = new ResourceModel(data.resource)
    const config = this.resource.config
    if (config && config.randomize) pages = shuffleArray(pages)

    const ar = []
    for (let i = 0; i < pages.length; i++) ar.push(i)
    this.araryIndices = [].concat(ar)
    this.pages = pages
  }

  // Salva a atividade feita no backend.
  async setActivities (class_id, discipline_id, lesson_id, resource_id, tempo_gasto, is_scorm, scorm_points = 0) {
    this.loading = true

    let typeLog = ''
    switch (this.resource.type) {
    case 'GA':
      typeLog = 'gameficada'
      break
    case 'TE':
      typeLog = 'avaliacao'
      break
      // Comentado porque não existe tipo simulado no firebase. Quando houver é só descomentar. Se deixar da erro na request.
    case 'SI': typeLog = 'simulado'
      break
    default:
      typeLog = 'lista_atividade'
    }

    let acertos = 0
    const questoes = this.pages.map(page => {
      if (page.exercise && page.exercise.average === 1) acertos++
      const nota = () => {
        if (is_scorm) {
          return scorm_points
        } else {
          return page.exercise.average ? parseInt(page.exercise.average) : 0
        }
      }

      if (is_scorm) {
        return {
          id: page.id,
          respondida: true,
          nota: nota()
        }
      } else {
        return {
          id: (page.exercise.id) || page.id,
          respondida: page.exercise.verified,
          nota: nota()
        }
      }
    })
    const life = this.errors

    VirtualRoomRequest.setActivities(
      AuthStore.institution_current,
      AuthStore.user,
      class_id,
      discipline_id,
      lesson_id,
      resource_id,
      questoes,
      acertos,
      this.tentativas,
      life,
      tempo_gasto
    )
      .then(() => this.setLog(resource_id, class_id, discipline_id, typeLog))
      .then(() => {
        if (this.tentativas === 0 && acertos === questoes.length) {
          this.setLog(
            resource_id,
            class_id,
            discipline_id,
            `${typeLog}_correta`
          )
        }
      })
      .then(() => {
        // RUIM: Chamar várias vezes o mesmo recurso passando apenas um valor diferente.
        if (this.tentativas === 0) {
          questoes.forEach(questao => {
            const id_target = `${resource_id}_${questao.id}`
            this.setLog(
              id_target,
              class_id,
              discipline_id,
              `${typeLog}_questao`
            )
          })
        }
      })
      .then(() => {
        this.getActivities(class_id, discipline_id, lesson_id, resource_id, {
          dontSetGameOver: true
        }).then()
      })
      .finally(() => {
        this.getActivities(class_id, discipline_id, lesson_id, resource_id, {
          dontSetGameOver: true
        })
        this.loading = false
      })
  }

  // metodo que recebe método e valores para validar salvamento de log, progressos e desempenhos do usuário.
  saveValues (method, role, values) {
    // método criado para filtar permissões de salvar do usuário.
    method = method.bind(this)
    method(...values)
  }

  async getCurrentTrail (trailId) {
    return TrailsRequests.getTrail(trailId).then(response => {
      return response
    })
  }

  getActivities (class_id, discipline_id, lesson_id, resource_id, options = {}) {
    return VirtualRoomRequest.getActivities(
      AuthStore.institution_current,
      AuthStore.user,
      class_id,
      discipline_id,
      lesson_id,
      resource_id
    )
      .then(result => {
        this.resultados = result.data.resultados
        if (this.resultados && !_.isEmpty(this.trailId)) {
          const lastIndex = this.resultados.length - 1
          const lastResult = this.resultados[lastIndex]
          const disciplineTrail = this.disciplinesTrail.find(discipline => discipline.discipline === parseInt(discipline_id))
          if (!_.isEmpty(disciplineTrail)) {
            if (!disciplineTrail.user_progress.available && resource_id === disciplineTrail.config.resource_id) {
              if (lastResult.porcentagem_nota >= disciplineTrail.config.percentage) {
                const endDate = new Date((Date.now()))
                this.putUserProgressDiscipline(endDate, disciplineTrail.user_progress.id, disciplineTrail.discipline)
              }
            }
          }
        }

        this.tentativas = result.data.tentativas
        this.showLessonContentPage = true
      })
      .then((data) => {
        if (this.resource) {
          const config = this.resource.config
          // limit_repeat
          if (config && config.limit_repeat && config.limit_repeat.on) {
            this.unlimited = false
            this.limit = parseInt(config.limit_repeat.limit, 10)
            if (
              options &&
                            !options.dontSetGameOver &&
                            this.tentativas > this.limit
            ) {
              this.gameOver = true
            }
          } else {
            this.unlimited = true
            this.limit = 0
          }
        }
      })
  }

  setIndexPage (institution, user, discipline, class_id, lesson, resource) {
    // verificação para ver se está na ultima pagina dos recursos
    const isScorm = resource.type === 'PS'

    const currentScormPage = (window.API_1484_11 && parseInt(window.API_1484_11.cmi.location)) || 0
    const scormViewedPage = resource.pages_viewed
    const scormTotalPage = resource.pages_count
    if (isScorm && window.API_1484_11.cmi.progress_measure) { resource.progress_measure = window.API_1484_11.cmi.progress_measure }
    const condition = () => {
      if (isScorm) {
        return (scormViewedPage > scormTotalPage) || scormViewedPage === currentScormPage || resource.progress_measure
      } else {
        return currentPage + 1 > resource.pages_viewed
      }
    }
    const currentPage = isScorm ? (scormViewedPage) : this.araryIndices[this.indexPage]
    // const scormPage = resource.pages_viewed < resource.pages_count

    if (condition()) {
      if (isScorm && window.API_1484_11.cmi.completion_status === 'incomplete') {
        resource.pages_count = currentScormPage + 2
      } else {
        resource.pages_count = currentScormPage + 1
      }

      // resource.pages_viewed = this.araryIndices[this.indexPage] + 1
      const id_target = `${resource.id}_${resource.pages_viewed}`
      return VirtualRoomRequest.setIndexPage(
        institution,
        user,
        discipline,
        class_id,
        lesson,
        resource,
        currentPage
      )
        .then(() => this.setLog(id_target, class_id, discipline.id, 'pagina'))
        .then(() => {
          // na última pagina grava que viu todo o conteúdo
          if (
            resource.type === 'CO' &&
            resource.pages_viewed === resource.pages_count
          ) {
            this.setLog(resource.id, class_id, discipline.id, 'conteudo')
          }
        })
    } else {
      if (this.gameOver) {
        // verificação pra quando tiver vidas e tempo esgotados, ele salva o progresso como 100%
        const id_target = `${resource.id}_${resource.pages_viewed}`

        return VirtualRoomRequest.setIndexPage(
          institution,
          user,
          discipline,
          class_id,
          lesson,
          resource,
          // quando for necessario mudar no front, back e mobile, setar apenas resource.pages_count
          resource.pages_count - 1
        )
          .then(() => this.setLog(id_target, class_id, discipline.id, 'pagina'))
          .then(() => {
            // na última pagina grava que viu todo o conteúdo
            if (
              resource.type === 'CO' &&
              resource.pages_viewed === resource.pages_count
            ) {
              this.setLog(resource.id, class_id, discipline.id, 'conteudo')
            }
          })
      }
    }
  }

  postUserProgressDiscipline (discipline_id) {
    return VirtualRoomRequest.postUserProgressDiscipline(discipline_id, parseInt(this.trailId)).then(response => {
      return response.data
    })
  }

  putUserProgressDiscipline (endDate, progressDisciplinesId, disciplineId) {
    return VirtualRoomRequest.putUserProgressDiscipline(endDate, progressDisciplinesId, disciplineId).then(response => {
      return response.data
    })
  }

  calculateDisciplineProgress (institution, student, disciplines, trailId, discipline_id, isFromSave) {
    return VirtualRoomRequest.calculateDisciplineProgress(
      institution, student, disciplines, trailId
    ).then(response => {
      if (isFromSave && !_.isEmpty(response) && !_.isEmpty(this.trailId) && !_.isEmpty(this.disciplinesTrail)) {
        const disciplineTrail = this.disciplinesTrail.find(discipline => discipline.discipline === parseInt(discipline_id))
        if (disciplineTrail.advance_rule !== 'E') {
          const endDate = new Date((Date.now()))
          if (response[discipline_id] >= disciplineTrail.config.percentage) {
            this.putUserProgressDiscipline(endDate, disciplineTrail.user_progress.id, disciplineTrail.discipline)
          }
        }
      }
      return response
    })
  }

  getIndexPage (institution, user, discipline, class_id, lessons) {
    return VirtualRoomRequest.getIndexPage(
      institution,
      user,
      discipline,
      class_id
    ).then(response =>
      lessons.forEach(lesson =>
        lesson.resources.forEach(
          res => {
            if (response.data[res.id]) {
              res.pages_viewed = response.data[res.id].paginas_consumidas
              if (res.type === 'PS') {
                // Scorm-again doesn't handle total of pages, we rely on the last viewed page by the user as the total
                res.pages_count = response.data[res.id].paginas_consumidas
              }
            }
          }

        )
      )
    )
  }

  jumpPage () {
    if (
      !this.resource ||
      !this.resource.config ||
      !this.pages
    // !this.araryIndices
    ) {
      return false
    }

    return !this.resource.config.jump_activitie
      ? !!this.pages[this.pages.length - this.araryIndices.length].exercise
        .verified
      : true
  }

  setLog (target_id, class_id, discipline_id, type) {
    return VirtualRoomRequest.setLog(
      AuthStore.institution_current,
      AuthStore.user,
      target_id,
      class_id,
      discipline_id,
      type
    )
  }

  /*************************************************************************************************************/
  /* Reports ***************************************************************************************************/

  /*************************************************************************************************************/
  /** Pega as informações de progresso e desempenho do aluno nas aulas de uma disciplina */
  getStudentByDiscipline () {
    this.loading = true
    return VirtualRoomRequest.getStudentsByDiscipline(
      AuthStore.institution_current,
      AuthStore.user,
      this.reports.discipline.id,
      this.reports.classe.id,
      [this.reports.student.id]
    )
      .then(result => {
        const values = result.alunos[this.reports.student.id]
        if (values) {
          this.reports.student.lessons_progress_performance = values.aulas
          this.reports.student.progress = values.progresso
          this.reports.student.performance = values.desempenho
        }
      })
      .finally(() => (this.loading = false))
  }

  /** Pega as informações de progresso e desempenho dos alunos nas aulas de uma disciplina e turma */
  getStudentsByDiscipline () {
    this.loading = true
    return VirtualRoomRequest.getStudentsByDiscipline(
      AuthStore.institution_current,
      AuthStore.user,
      this.reports.discipline.id,
      this.reports.classe.id,
      this.reports.classe.students.map(student => student.id)
    )
      .then(result => {
        this.reports.classe.progress = result.turma.progresso
        this.reports.classe.performance = result.turma.desempenho
        this.reports.classe.students.forEach(student => {
          if (result.alunos[student.id]) {
            student.lessons_progress_performance =
              result.alunos[student.id].aulas
            student.progress = result.alunos[student.id].progresso
            student.performance = result.alunos[student.id].desempenho
          }
        })
        if (this.reports.lessons) {
          this.reports.lessons.forEach(lesson => {
            if (result.turma.aulas[lesson.id]) {
              lesson.progress_performance = result.turma.aulas[lesson.id]
            }
          })
        }
        return result
      })
      .finally(() => (this.loading = false))
  }

  getStudentProgressPerformanceDiscipline () {
    this.loading = true
    return this.getKnowareasCategories(false, true, true)
      .then(() => {
        return this.disciplines.map(discipline => ({
          id_turma: discipline.classes[0].id,
          id_disciplina: discipline.id,
          alunos: [AuthStore.user.id]
        }))
      })
      .then(list_turma_disciplina =>
        VirtualRoomRequest.getProgressPerformanceDiscipline(
          AuthStore.institution_current,
          AuthStore.user,
          list_turma_disciplina,
          AuthStore.user.id
        )
      )
      .then(data =>
        this.disciplines.forEach(discipline => {
          // Toni mudar aqui para pegar por turma após correção data[panelClass.class.id]
          const progressPerformance = data[discipline.id]
          if (progressPerformance) {
            discipline.classes[0].progress = progressPerformance.progresso_turma
            discipline.classes[0].performance = progressPerformance.desempenho_turma
          }
        })
      )
      .finally(() => (this.loading = false))
  }

  /* Retorna dados de progresso e desempenho da disciplina. */
  getTutorProgressPerformanceDiscipline (classes, class_id = undefined) {
    this.loading = true
    return this.getKnowareasCategories(true, false, false)
      .then(() => classes.map(classe => classe.id))
      .then(listIds =>
        this.disciplines.filter(
          discipline => listIds.indexOf(discipline.classes[0].id) !== -1
        )
      )
      .then(disciplines => (this.disciplines = disciplines))
      .then(() =>
        this.disciplines.map(discipline => ({
          id_turma: discipline.classes[0].id,
          id_disciplina: discipline.id,
          alunos: classes
            .find(classe => classe.id === discipline.classes[0].id)
            .students.map(student => student.id)
        }))
      )
      .then(list_class_disc =>
        VirtualRoomRequest.getProgressPerformanceDiscipline(
          AuthStore.institution_current,
          AuthStore.user,
          [list_class_disc[0]]
        )
      )
      .then(data => {
        this.disciplines.forEach(discipline => {
          // Toni mudar aqui para pegar por turma após correção data[panelClass.class.id]
          const progressPerformance = data[discipline.id]
          if (progressPerformance) {
            discipline.classes[0].progress = progressPerformance.progresso_turma
            discipline.classes[0].performance = progressPerformance.desempenho_turma
          }
        })
      })
      .finally(() => (this.loading = false))
  }

  /**
   * Retorna o grupo de aulas de uma determina disciplina. */
  getLessonsReports (discipline_id) {
    this.loading = true
    return VirtualRoomRequest.getLessons(discipline_id)
      .then(data => data.lessons.map(lesson => new LessonsModel(lesson)))
      .finally(() => (this.loading = false))
  }

  // retorna a porcentagem de alunos acima da média da disciplina
  getPercentageStudentsAboveAverage () {
    const studentsAboveAverage = this.reports.classe.students.reduce(
      (acc, student) => {
        if (student.performance >= this.referenceAverage) return acc + 1
        return acc
      },
      0
    )
    // se não for válido então retorna 0% de alunos acima da média
    if (!studentsAboveAverage) {
      return {
        percentage: 0,
        studentsAboveAverage: 0,
        students: this.reports.classe.students
      }
    }
    // calcula média de aluno.
    return {
      percentage:
        (studentsAboveAverage * 100) / this.reports.classe.students.length,
      studentsAboveAverage: studentsAboveAverage,
      students: this.reports.classe.students
    }
  }

  // retorna a porcentagem de alunos abaixo da média da aula
  getPercentageStudentsBelowAverageLesson (lesson) {
    const studentsBelowAverage = this.reports.classe.students
    // pega apenas alunos com a lesson informada
      .filter(
        student =>
          student.lessons_progress_performance &&
          student.lessons_progress_performance[lesson.id]
      )
      // filtro para obter apenas alunos com desempenho abaixo de 50%
      .filter(student => {
        let performance =
          student.lessons_progress_performance[lesson.id].desempenho
        if (!performance) performance = 0
        return performance < this.referenceAverage
      })
    // se não for válido então retorna vazio pois não existem alunos abaixo da média
    if (!studentsBelowAverage) {
      return {
        percentage: 0,
        studentsBelowAverage: [],
        students: this.reports.classe.students
      }
    }
    // calcula média de aluno.
    return {
      percentage:
        (studentsBelowAverage.length * 100) /
        this.reports.classe.students.length,
      studentsBelowAverage: studentsBelowAverage,
      students: this.reports.classe.students
    }
  }

  // verifica se lesson possui atividade comum que contam desempenho.
  containsAC (lesson) {
    return lesson.resources.find(res => res.type === 'AC')
  }

  /*************************************************************************************************************/
  /* roadbar ***************************************************************************************************/

  /*************************************************************************************************************/

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) {
      // Nível 1
      return themeConfig.terms.disciplinasC
    }
    if (pathList.length === 2) {
      // Nível 2
      if (pathList[1] === 'trails') return 'Trilhas'
      if (pathList[1] === 'ranking') return 'Ranking'
      if (pathList[1] === 'conquest') return 'Conquistas'
      if (pathList[1] === 'reports') return 'Relatórios'
      if (pathList[1] === 'liveclass') return 'Aula ao vivo'
      if (pathList[1] === 'taskdelivery') return 'Trabalhos'
      if (pathList[1] === 'announcements') return 'Comunicados'
      return 'Disciplinas'
    }
    if (pathList.length === 3) {
      // Nível 3
      if (pathList[1] === 'ranking') {
        return `${
          this.ranking && this.ranking.studentRoom
            ? this.ranking.studentRoom.discipline.name
            : ''
        }`
      }
      if (pathList[1] === 'reports') {
        return `${
          this.reports && this.reports.discipline
            ? this.reports.discipline.name
            : ''
        }`
      }

      if (pathList[1] === 'liveclass') {
        return `Aula ao vivo`
      }

      if (pathList[1] === 'taskdelivery') {
        return `Trabalhos`
      }

      return `${this.discipline ? this.discipline.name : ''}`
    }
    if (pathList.length === 4) {
      // Nível 4
      if (pathList[1] === 'reports') {
        return `${
          this.reports && this.reports.classe ? this.reports.classe.name : ''
        }`
      } else if (pathList[1] === 'ranking') {
        return `${
          this.ranking && this.ranking.studentRoom
            ? this.ranking.studentRoom.discipline.name
            : ''
        }`
      } else if (pathList[1] === 'liveclass') {
        return `Aula ao vivo`
      } else if (pathList[1] === 'taskdelivery') {
        return `Trabalhos`
      }
    }
    if (pathList.length === 5) {
      // Nível 5
      if (pathList[1] === 'reports') {
        return `${
          this.reports && this.reports.student ? this.reports.student.name : ''
        }`
      }

      if (pathList[1] === 'liveclass') {
        return 'Aula ao vivo'
      }

      if (pathList[1] === 'taskdelivery') {
        return `Trabalhos`
      }

      if (this.lessons.length === 0) return ''
      for (let x = 0; x < this.lessons.length; x++) {
        for (let y = 0; y < this.lessons[x].resources.length; y++) {
          if (
            this.lessons[x].resources[y].id.toString() === pathList[3] ||
            pathList[3] === '0'
          ) {
            return `${this.lessons[x].name}: ${this.lessons[x].resources[y].name}`
          }
        }
      }
    }
    if (pathList.length === 6) {
      return 'Aula ao Vivo'
    }
    if (pathList.length === 7) {
      return 'Trabalhos'
    }
    throw new Error(
      `${VirtualRoomStore.name} não está configurado para o caminho ${window.location.pathname}`
    )
  }

  getLinks () {
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    const queryTrailId = !_.isEmpty(trailId) ? `?trail=${trailId}` : ''

    const pathList = window.location.pathname.split('/').filter(x => x)

    if (pathList.length === 1) {
      // Nível 1
      return [{ title: 'Sala Virtual', address: `/${pathList[0]}/` }]
    }
    if (pathList.length === 2) {
      // Nível 2
      if (pathList[1] === 'ranking') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Ranking', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'conquest') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Conquistas', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'reports') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Relatórios', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'liveclass') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Aula ao vivo', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'trails') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Trilhas', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }

      if (pathList[1] === 'help') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Ajuda', address: `/${pathList[0]}/${pathList[1]}/` }
        ]
      }

      if (pathList[1] === 'taskdelivery') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Trabalhos', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'announcements') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Comunicados', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      }

      return [
        { title: themeConfig.terms.disciplinasC,
          address: `/${pathList[0]}/${queryTrailId}`
        },
        { title: themeConfig.terms.turmasC,
          address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}`
        }
      ]
    }
    if (pathList.length === 3) {
      // Nível 3

      if (pathList[1] === 'forum') {
        return [

          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Fórum', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: `${this.discipline ? this.discipline.name : ''}`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          }
        ]
      }

      if (pathList[1] === 'ranking') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Ranking', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: `${
              this.ranking && this.ranking.studentRoom
                ? this.ranking.studentRoom.discipline.name
                : 'Disciplinas'
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          }
        ]
      }
      if (pathList[1] === 'reports') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Relatórios', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: `${
              this.reports && this.reports.discipline
                ? this.reports.discipline.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          }
        ]
      }
      if (pathList[1] === 'liveclass') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Aula ao vivo', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${queryTrailId}` },
          { title: '', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}` },
          { title: '', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${pathList[4]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'taskdelivery') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Trabalhos', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          { title: '', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}` },
          { title: '', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${pathList[4]}/${queryTrailId}` }
        ]
      }
      return [
        { title: 'Sala Virtual', address: `/${pathList[0]}/` },
        {
          title: `${this.discipline ? this.discipline.name : ''}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
        }
      ]
    }
    if (pathList.length === 4) {
      // Nível 4
      if (pathList[1] === 'reports') {
        if (this.reports && this.reports.role === TUTOR) {
          return [
            { title: 'Sala Virtual', address: `/${pathList[0]}/` },
            { title: 'Relatórios', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
            {
              title: `${
                this.reports && this.reports.discipline
                  ? this.reports.discipline.name
                  : ''
              }`,
              address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
            },
            {
              title: `${
                this.reports && this.reports.classe
                  ? this.reports.classe.name
                  : ''
              }`,
              address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${
                pathList[3]
              }/${queryTrailId}`
            }
          ]
        }
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Relatórios', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: `${
              this.reports && this.reports.classe
                ? this.reports.classe.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${
              pathList[3]
            }/${queryTrailId}`
          }
        ]
      }

      if (pathList[1] === 'ranking') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Ranking', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: themeConfig.terms.disciplinasC,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          },
          {
            title: `${
              this.ranking && this.ranking.studentRoom
                ? this.ranking.studentRoom.discipline.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          }
        ]
      }

      if (pathList[1] === 'liveclass') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Aula ao vivo', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${queryTrailId}` }
        ]
      }
      if (pathList[1] === 'taskdelivery') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Trabalhos', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${queryTrailId}` }
        ]
      }
    }
    if (pathList.length === 6) {
      if (pathList[1] === 'liveclass') {
        return [
          { title: 'Aula ao vivo', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${queryTrailId}` }
        ]
      }
    }
    if (pathList.length === 7) {
      if (pathList[1] === 'taskdelivery') {
        return [
          { title: 'Trabalhos', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}` }
        ]
      }
    }

    if (pathList.length === 5) {
      // Nível 3
      if (pathList[1] === 'reports') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Relatórios', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` },
          {
            title: `${
              this.reports && this.reports.discipline
                ? this.reports.discipline.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${queryTrailId}`
          },
          {
            title: `${
              this.reports && this.reports.classe
                ? this.reports.classe.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${
              pathList[3]
            }/${queryTrailId}`
          },
          {
            title: `${
              this.reports && this.reports.student
                ? this.reports.student.name
                : ''
            }`,
            address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${
              pathList[3]
            }/${pathList[4]}/${queryTrailId}`
          }
        ]
      } if (pathList[1] === 'liveclass') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Aula ao vivo', address: `/${pathList[0]}/${pathList[1]}/${pathList[3]}/${pathList[4]}/${queryTrailId}` }
        ]
      } else if (pathList[1] === 'taskdelivery') {
        return [
          { title: 'Sala Virtual', address: `/${pathList[0]}/` },
          { title: 'Trabalhos', address: `/${pathList[0]}/${pathList[1]}/${queryTrailId}` }
        ]
      } else {
        if (this.lessons.length === 0) return []
        for (let x = 0; x < this.lessons.length; x++) {
          for (let y = 0; y < this.lessons[x].resources.length; y++) {
            if (
              this.lessons[x].resources[y].id.toString() === pathList[3] ||
              pathList[3] === '0'
            ) {
              return [
                { title: 'Sala Virtual', address: `/${pathList[0]}/` },
                {
                  title: `${this.discipline.name}`,
                  address: `/${pathList.slice(0, 3).join('/')}/${queryTrailId}`
                },
                {
                  title: `${this.lessons[x].name}: ${this.lessons[x].resources[y].name}`,
                  address: `/${pathList.join('/')}/${queryTrailId}`
                }
              ]
            }
          }
        }
      }
    }
    throw new Error(
      `${VirtualRoomStore.name} não está configurado para o caminho ${window.location.pathname}`
    )
  }
}

export default new VirtualRoomStore()
