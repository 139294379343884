import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  formDataRequest: ['params'],
  formDataSuccess: ['data'],
  formDataFailure: ['error'],
  getRoomRequest: ['params'],
  getRoomSuccess: ['data'],
  getRoomFailure: ['error'],
  allRequest: ['params'],
  allSuccess: ['data'],
  allFailure: ['error'],
  createRequest: ['data'],
  createSuccess: ['success'],
  createFailure: ['error'],
  getRequest: ['params'],
  getSuccess: ['data'],
  getFailure: ['error'],
  updateRequest: ['data'],
  updateSuccess: ['success'],
  updateFailure: ['error'],
  deleteRequest: ['data'],
  deleteSuccess: ['success'],
  deleteFailure: ['error'],
  getLessonAttendanceRequest: ['data'],
  getLessonAttendanceSuccess: ['success'],
  getLessonAttendanceFailure: ['error'],
  massAttendanceRequest: ['data'],
  massAttendanceSuccess: ['success'],
  massAttendanceFailure: ['error']
})

const INITIAL_STATE = {
  isLoading: false,
  error: false
}

// Get form data
export const formDataRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const formDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  classesData: action.data
})
export const formDataFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Create Liveclass
export const createRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const createSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  class: [],
  classesData: []
  // data: { classesData: action.data }
})
export const createFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Get All Liveclasses
export const allRequest = (state = INITIAL_STATE) => ({ ...state, class: [], massAttendance: null, room: null, isLoading: true })
export const allSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isLoading: false,
  classes: action.data
})
export const allFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Get Liveclass
export const getRequest = (state = INITIAL_STATE) => ({ ...state, class: [], isLoading: true })
export const getSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    class: action.data

  }
}
export const getFailure = (state = INITIAL_STATE, action) => (state.isLoading = false)

// Get Room
export const getRoomRequest = (state = INITIAL_STATE) => ({ ...state, room: [], isLoading: true })
export const getRoomSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    room: action.data
  }
}
export const getRoomFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Update LiveClass
export const updateRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const updateSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false
  }
}
export const updateFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

// Delete LiveClass
export const deleteRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const deleteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false
  }
}
export const deleteFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

export const getLessonAttendanceRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const getLessonAttendanceSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    lessonAttendance: action.success
  }
}

export const getLessonAttendanceFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

export const massAttendanceRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true })
export const massAttendanceSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    massAttendance: action.success
  }
}

export const massAttendanceFailure = (state = INITIAL_STATE, action) => (state.isLoading = true)

export default createReducer(INITIAL_STATE, {
  [Types.FORM_DATA_REQUEST]: formDataRequest,
  [Types.FORM_DATA_SUCCESS]: formDataSuccess,
  [Types.FORM_DATA_FAILURE]: formDataFailure,
  [Types.CREATE_REQUEST]: createRequest,
  [Types.CREATE_SUCCESS]: createSuccess,
  [Types.CREATE_FAILURE]: createFailure,
  [Types.ALL_REQUEST]: allRequest,
  [Types.ALL_SUCCESS]: allSuccess,
  [Types.ALL_FAILURE]: allFailure,
  [Types.GET_REQUEST]: getRequest,
  [Types.GET_SUCCESS]: getSuccess,
  [Types.GET_FAILURE]: getFailure,
  [Types.UPDATE_REQUEST]: updateRequest,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_FAILURE]: updateFailure,
  [Types.GET_ROOM_REQUEST]: getRoomRequest,
  [Types.GET_ROOM_SUCCESS]: getRoomSuccess,
  [Types.GET_ROOM_FAILURE]: getRoomFailure,
  [Types.DELETE_REQUEST]: deleteRequest,
  [Types.DELETE_SUCCESS]: deleteSuccess,
  [Types.DELETE_FAILURE]: deleteFailure,
  [Types.GET_LESSON_ATTENDANCE_REQUEST]: getLessonAttendanceRequest,
  [Types.GET_LESSON_ATTENDANCE_SUCCESS]: getLessonAttendanceSuccess,
  [Types.GET_LESSON_ATTENDANCE_FAILURE]: getLessonAttendanceFailure,
  [Types.MASS_ATTENDANCE_REQUEST]: massAttendanceRequest,
  [Types.MASS_ATTENDANCE_SUCCESS]: massAttendanceSuccess,
  [Types.MASS_ATTENDANCE_FAILURE]: massAttendanceFailure
})
