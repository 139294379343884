import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import { Router, Route, Switch } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'
import App from './App'
import Crop from './tools/Crop'
import './config/Reactotron/ReactotronConfig'
import { Provider } from 'react-redux'
import store from './redux/store'

import history from './services/history'

const MaintenanceComponent = Loadable({
  loader: () => import('./components/MaintenanseComponent'),
  loading: () => <div/>
})

ReactDOM.render(
  (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path='/maintenanse/' render={() => <MaintenanceComponent/>}/>
          <Route path='/tools/crop' component={Crop}/>
          <Route path='/' component={App}/>
        </Switch>
      </Router>
    </Provider>
  ),
  document.getElementById('root')
)
registerServiceWorker()
