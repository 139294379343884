import BaseRequests, { URLS } from './BaseRequests'

class InstitutionConfigStore {
  /**
   * Obtém a configuração
   * @param {Integer} page Página da lista
   */
  static async getConfigList (page, limit) {
    try {
      const response = await BaseRequests.get(URLS.GET_INSTITUTION_CONFIG, { page, limit })
      return response.data
    } catch (error) {
      return { error }
    }
  }

  /**
   *
   * @param {Integer} institution ID da instituição
   */
  static async getIndividualConfig (institution) {
    try {
      const response = await BaseRequests.get(URLS.GET_INSTITUTION_CONFIG, { institution })
      return response.data
    } catch (error) {
      return { error }
    }
  }

  /**
   * Atualiza a configuração de integração de uma instituição
   * @param {Object} values Keys necessárias para a configuração (institution_id - Integer & activate - Boolean)
   */
  static update (values) {
    return BaseRequests.post(URLS.UPDATE_INTEGRATION_CONFIG, values)
  }
}

export default InstitutionConfigStore
