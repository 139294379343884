import { put, all, call, takeLatest } from 'redux-saga/effects'
import { Types, Creators } from './index'
import DisciplineRequests from '../../../services/requests/DisciplineRequests'

function * getDisciplines (action) {
  try {
    const { data } = yield DisciplineRequests.get(action.params.id, false, false, action.params.page, action.params.limit, action.params.search, true, false, true)
    yield put(Creators.getDisciplinesSuccess(data))
  } catch (error) {
    yield put(Creators.getDisciplinesFailure(error))
  }
}

export default all([
  takeLatest(Types.GET_DISCIPLINES_REQUEST, getDisciplines)
])
