export default class EnrollmentModel {
  constructor (data) {
    this.registry = data.registry
    this.interests = data.interests
    this.is_active = data.is_active
    this.roles = data.roles
  }

  update (data) {
    this.registry = data.registry
    this.interests = data.interests
    this.is_active = data.is_active
    this.roles = data.roles
  }
}
