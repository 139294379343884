/**
 * Item individual da configuração de integração
 * @attribute {Integer} id ID da instituição
 * @attribute {String} tradingName Nome da instituição
 * @attribute {Boolean} canIntegrate Valor da configuração de integração para a instituição
 */
export default class InstitutionItemConfigModel {
  constructor (data) {
    this.id = data.id
    this.tradingName = data.trading_name
    this.canIntegrate = data.can_integrate
    this.hasCertificate = data.has_certificate
  }
}
