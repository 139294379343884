import { extendObservable } from 'mobx'

import DomainModel from './DomainModel'
import LessonsGroupModel from './LessonsGroupModel'
// import { Type } from './CuratorshipConfigModel'
import VirtualRoomConfigModel from './VirtualRoomConfigModel'
import ClassModel from './ClassModel'

export default class DisciplineModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.about = data.about
    this.keywords = data.keywords
    this.lessons_group = data.lessons_group ? new LessonsGroupModel(data.lessons_group) : null
    this.author_institution = data.author_institution || ''
    this.author = data.author || ''
    this.content_manager = data.content_manager
    this.has_certificate = data.has_certificate || false
    this.workload = data.workload
    this.suggested_time = data.suggested_time
    this.classes = data.classes ? data.classes.map(cls => new ClassModel(cls)) : null
    this.producer = data.producer || ''

    // this.modality = data.modality
    // this.category = data.category
    this.modalities = data.modalities ? data.modalities.map(modality => new DomainModel(modality)) : []
    this.min_progress_certificate = data.min_progress_certificate
    // this.categories = data.categories ? data.categories.map(category => new DomainModel(category)) : []
    this.vr_config = data.vr_config ? new VirtualRoomConfigModel(data.vr_config) : undefined
    // this.classes_count = data.classes_count
    // this.curator = data.curator
    // this.curatorship_type = data.curatorship_type ? new Type(data.curatorship_type) : null
    // this.education_plan = data.education_plan
    // this.institution = data.institution
    // this.knowareas = data.knowareas ? data.knowareas : []

    extendObservable(this, {
      progress: 0,
      performance: 0,
      teachingPlan: data.teaching_plan ? new TeachingPlan(data.teaching_plan) : data.teaching_plan,
      technicalSheet: data.technical_sheet ? new TechnicalSheet(data.technical_sheet) : data.technical_sheet,
      objective: data.objective
    })
  }

  // get percentage_complete () {
  //   return this.lessons_group ? this.lessons_group.percentage_complete : this.progress
  // }

  update (values) {
    this.name = values.name
    this.workload = values.workload
    this.keywords = values.keywords
    this.modality = values.modality
    this.content_manager = { id: values.content_manager ? parseInt(values.content_manager, 10) : 0 }
    this.lessons_group = { id: values.lessons_group ? parseInt(values.lessons_group, 10) : 0 }
    this.curatorship_type = { id: values.curatorship_type ? parseInt(values.curatorship_type, 10) : 0 }
    this.curator = { id: values.curator ? parseInt(values.curator, 10) : 0 }
    this.about = values.about
    this.author = values.author || ''
    this.author_institution = values.author_institution || ''
    this.producer = values.producer || ''
    this.has_certificate = values.has_certificate || false
    this.suggested_time = values.suggested_time || ''
  }
}

export class TeachingPlan {
  constructor (data) {
    extendObservable(this, {
      title: data.title,
      url: data.url,
      filename: data.filename,
      is_uploaded: data.is_uploaded
    })
  }
}

export class TechnicalSheet {
  constructor (data) {
    extendObservable(this, {
      title: data.title,
      url: data.url,
      filename: data.filename,
      is_uploaded: data.is_uploaded
    })
  }
}
