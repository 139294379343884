import BaseRequests, { API, URLS } from './BaseRequests'
import FirebaseRequest from './FirebaseRequest'

export default class VirtualRoomRequest {
  /** *************REQUESTS Comuns ********************/
  static async getMediaVideoStatus (data) {
    const response = await BaseRequests.post(URLS.GET_MEDIA_VIDEO_STATUS, data)
    return response.data
  }

  static async saveActivityResult (data) {
    const response = await BaseRequests.post(URLS.SET_ACTIVITY_RESULT, data)
    return response.data
  }

  static async getExternalVideoManifest (videoUrl) {
    const response = await BaseRequests.customPost(URLS.GET_EXTERNAL_VIDEO_URL, { url: videoUrl })
    return response.data
  }

  static updateForumConfig (data) {
    return (BaseRequests.post(URLS.UPDATE_FORUM_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail update forum config')
      })
  }

  static updateForumNotify (data) {
    return (BaseRequests.post(URLS.UPDATE_FORUM_NOTIFY, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail update forum notify')
      })
  }

  static updateConfig (data) {
    return (BaseRequests.post(URLS.UPDATE_VR_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail update config virtualroom')
      })
  }

  static getConfig (data) {
    return (BaseRequests.post(URLS.GET_VIRTUALROOM_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail get config virtualroom')
      })
  }

  static deleteConfig (data) {
    return (BaseRequests.post(URLS.DELETE_VR_CONFIG, data))
      .then((response) => {
        if (response.data.success) return true
        throw response.data.message
      })
  }

  static setConfig (data) {
    return (BaseRequests.post(URLS.SET_VIRTUALROOM_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail set config virtualroom')
      })
  }

  static getTypeConfigs (data) {
    return (BaseRequests.post(URLS.GET_VR_CONFIGS_TYPES, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail get config virtualroom')
      })
  }

  static createNewConfig (data) {
    return (BaseRequests.post(URLS.NEW_VIRTUALROOM_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return true
        }
        throw new Error('Fail create new VirtualRoom Config')
      })
  }

  static getModalitiesVrConfig (data) {
    return (BaseRequests.post(URLS.GET_MODALITIES_VR_CONFIG, data))
      .then(response => {
        if (response.data.success) {
          return response.data
        }
        throw new Error('Fail get modalities config virtualroom')
      })
  }

  static removeSupportMaterial (data) {
    return (BaseRequests.post(URLS.DELETE_SUPPORT_MATERIAL, data)
      .then(response => {
        if (response.data.success) {
          return true
        }
        throw new Error('Fail delete Support Material, try again')
      }))
  }

  static updateSupportMaterial (data) {
    return (BaseRequests.post(URLS.UPDATE_SUPPORT_MATERIAL, data)
      .then(response => {
        if (response.data.success) {
          return true
        }
        throw new Error('Fail to update Support Material, try again')
      }))
  }

  static getSupportMaterials () {
    return (BaseRequests.post(URLS.GET_SUPPORT_MATERIAL)
      .then(response => {
        if (response.data.success) {
          return response.data.materials
        } else {
          throw new Error('Fail Get Support Materials, try again')
        }
      }))
  }

  static getContentPages (resource_id) {
    const data = { resource_id }
    return (BaseRequests.post(URLS.GET_PAGES, data)
      .then(response => response.data)
    )
  }

  static getLessons (discipline_id) {
    const data = { discipline_id }
    return (BaseRequests.post(URLS.GET_LESSONS, data)
      .then(response => response.data)
    )
  }

  static getKnowareaCategory (institution, tutor = false, student = false, remove_expired = false, limit, category_id, limitDiscipline, isTrail) {
    let data = {}

    if (tutor) data = { institution, tutor }
    else data = { institution, student }

    if (student) {
      if (remove_expired) {
        data.remove_expired = remove_expired
      }
    }

    if (limit) data.limit = limit
    if (limitDiscipline) data.limit_discipline = limitDiscipline

    if (!isTrail) {
      if (category_id) data.category_id = category_id
    } else {
      if (category_id) data.trail_id = category_id
    }

    return BaseRequests.post(URLS.GET_KNOWAREAS_CATEGORIES, data)
  }

  static postUserProgressDiscipline (disciplineId, trailId) {
    const data = { trail: trailId, discipline: disciplineId, available: false, start_date: '' }
    return BaseRequests.post(`${API}/trails/disciplines/${disciplineId}/user_progress_disciplines`, data)
  }

  static putUserProgressDiscipline (endDate, progressDisciplinesId, disciplineId) {
    const data = { available: true, end_date: endDate }
    return BaseRequests.put(`${API}/trails/disciplines/${disciplineId}/user_progress_disciplines/${progressDisciplinesId}/`, data)
  }

  /** *************REQUESTS de Students ********************/
  /** get-student-classes */

  static getStudentClasses (institution, remove_expired = false) {
    const data = { institution }

    if (remove_expired) {
      data.remove_expired = remove_expired
    }

    return BaseRequests.post(URLS.GET_STUDENT_CLASSES, data)
      .then(response => response.data.objs)
  }

  /** **************REQUESTS de Tutors ********************/
  /** get-tutor-classes */
  static getTutorClasses (institution, remove_expired = true) {
    const data = { institution }

    // if(remove_expired) {
    //  data.remove_expired = remove_expired
    // }

    return BaseRequests.post(URLS.GET_TUTOR_CLASSES, data)
      .then(response => response.data.objs)
  }

  /******************************************************/
  /** *********************FIREBASE***********************/

  /******************************************************/
  static create_base_lessons (institution, user, id_discipline, id_turma, lessons) {
    const tempLessons = [...lessons]
    // remove o resource FO que não pertence para a contagem de páginas de acesso.
    tempLessons.forEach(l => { l.resources = l.resources.filter(res => res.type !== 'FO') })
    return FirebaseRequest.create_base_lessons(institution, user, id_discipline, id_turma, tempLessons)
  }

  // altera o indice da página atual.
  static setIndexPage (institution, user, discipline, class_id, lesson, resource, page) {
    return FirebaseRequest.setPaginasConsumidas(institution, user, discipline, class_id, lesson, resource, page)
  }

  static calculateDisciplineProgress (institution, student, disciplines, trailId) {
    return FirebaseRequest.calculateDisciplineProgress(institution, student, disciplines, trailId)
  }

  static getIndexPage (institution, user, discipline, class_id) {
    return FirebaseRequest.getPaginasConsumidas(institution, user, discipline, class_id)
  }

  static getPercentageCompleteDiscipline (institution, user, disciplines, trailId) {
    return FirebaseRequest.getPercentageCompleteDiscipline(institution, user, disciplines, trailId)
  }

  static getProgressPerformanceDiscipline (institution, user, list_turma_disciplina, id_student) {
    return FirebaseRequest.getProgressPerformanceDiscipline(institution, user, list_turma_disciplina, id_student)
  }

  static getStudentsByDiscipline (institution, user, id_discipline, id_class, students) {
    return FirebaseRequest.getStudentsByDiscipline(institution, user, id_discipline, id_class, students)
  }

  static getRanking (institution, user, panelClasses) {
    return FirebaseRequest.getRanking(institution, user, panelClasses)
  }

  static createRankingStructure (institution, user, rankingResources) {
    return FirebaseRequest.createRankingStructure(institution, user, rankingResources)
  }

  static getConquests (institution, user) {
    return FirebaseRequest.getConquests(institution, user)
  }

  static getNewConquests (institution, user) {
    return FirebaseRequest.getNewConquests(institution, user)
  }

  static createBaseConquest (institution, user, conquestGroups) {
    return FirebaseRequest.createBaseConquest(institution, user, conquestGroups)
  }

  // salva lista de exercícios feita.
  static setActivities (institution, user, class_id, discipline_id, lesson_id, resource_id, questoes, acertos, tentativa, life, tempo_gasto) {
    return FirebaseRequest.setActivities(institution, user, class_id, discipline_id, lesson_id, resource_id, questoes, acertos, tentativa, life, tempo_gasto)
  }

  // retorna as atividades salvas
  static getActivities (institution, user, class_id, discipline_id, lesson_id, resource_id) {
    return FirebaseRequest.getActivities(institution, user, class_id, discipline_id, lesson_id, resource_id)
  }

  // envia log de gatilhos disparados para ganhar conquistas.
  static setLog (institution, user, target_id, class_id, discipline_id, type) {
    return FirebaseRequest.setLog(institution, user, target_id, class_id, discipline_id, type)
  }

  // Envia mensagem para um contato.
  static sendMessage (institution, userSender, userReceiver, classId, message, timestamp, flow) {
    return FirebaseRequest.sendMessage(institution, userSender, userReceiver, classId, message, timestamp, flow)
  }

  // Atualiza todas as mensagens como lidas.
  static readMessages (institutionId, sender, receiver, classId) {
    return FirebaseRequest.readMessages(institutionId, sender, receiver, classId)
  }

  // Atualiza mensagem como lida.
  static readMessage (institutionId, sender, receiver, classId, keyMessage) {
    return FirebaseRequest.readMessage(institutionId, sender, receiver, classId, keyMessage)
  }

  // Lista as mensagens entre usuários.
  static listMessages (institutionId, user1, user2, classId, callback) {
    return FirebaseRequest.listMessages(institutionId, user1, user2, classId, callback)
  }

  // Envia mensagems para todos alunos de uma turma
  static sendMessageClass (institutionId, user, classId, receivers, message) {
    return FirebaseRequest.sendMessageClass(institutionId, user, classId, receivers, message)
  }

  // Remove listeners que monitoram novas mensagens.
  static removeListenerMessages () {
    return FirebaseRequest.removeListenerMessages()
  }

  /**
   * @function getCertificate Create a certificate or if it already exists just return your data
   * @param {integer} institution
   * @param {integer} dicipline
   */
  static getCertificate (institution, discipline) {
    const data = { institution, discipline }
    return BaseRequests.post(URLS.GET_CERTIFICATE, data).then(response => response.data)
  }

  /**
   * @function validateCertificate Query for validation of a certificate
   * @param {string} certificate_key
   */
  static validateCertificate (certificate_key) {
    const data = { certificate_key }
    return BaseRequests.post(URLS.VALIDATE_CERTIFICATE, data).then(response => response.data)
  }
}
