import { extendObservable } from 'mobx'
import AuthStore from './AuthStore'
import CourseRequests from '../services/requests/CourseRequest'
import CourseModel from '../models/CourseModel'
import DomainModel from '../models/DomainModel'
import { themeConfig } from '../Util'

class CourseStore {
  constructor () {
    extendObservable(this, {
      course: undefined,
      courses: [],
      domains: undefined,
      loading: false,
      filter: ''
    })
  }

  getDomains () {
    return CourseRequests.getDomains(AuthStore.institution_current.id).then(response => {
      if (response.data.success) {
        this.domains = response.data.domains.map(domain => new DomainModel(domain))
      }
      return response
    })
  }

  getCourses (institution = undefined) {
    const inst = institution || AuthStore.institution_current.id
    return CourseRequests.getCourses(inst)
      .then(response => {
        if (response.data.success) {
          this.courses = response.data.courses
            .map(course => new CourseModel(course))
        }
        return response
      })
  }

  get (course_id, disciplines = true, skipLoad = false) {
    if (!skipLoad) this.loading = true
    return CourseRequests.get(AuthStore.institution_current.id, course_id, disciplines)
      .then((response) => {
        if (response.success) {
          this.course = new CourseModel(response.course)
        }
      })
      .finally(() => { if (!skipLoad) this.loading = false })
  }

  addDiscipline (discipline_id) {
    this.loading = true
    return CourseRequests.update(AuthStore.institution_current.id, {
      add: true,
      id: this.course.id,
      disciplines: [discipline_id]
    }).then(() => this.get(this.course.id))
      .finally(() => this.loading = false)
  }

  removeDiscipline (discipline_id) {
    this.loading = true
    return CourseRequests.update(AuthStore.institution_current.id, {
      remove: true,
      id: this.course.id,
      disciplines: [discipline_id]
    }).then(() => this.get(this.course.id))
      .finally(() => this.loading = false)
  }

  save (name, knowarea) {
    return CourseRequests.save(AuthStore.institution_current.id, name, knowarea)
  }

  update (values) {
    return CourseRequests.update(AuthStore.institution_current.id, values)
  }

  delete (id) {
    return CourseRequests.delete(AuthStore.institution_current.id, id)
  }

  filteredCourses () {
    return this.courses.filter(course =>
      course.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().indexOf(
        this.filter.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) !== -1
    )
  }

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) { // Nível 1
      return 'Gestão de Cursos'
    }
    if (pathList.length === 2) { // Nível 2
      return this.course ? this.course.name : ''
    }
    throw new Error(`${CourseStore.name} não está configurado para o caminho ${window.location.pathname}`)
  }

  getLinks (links) {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) { // Nível 1
      return [
        { title: 'Cursos', address: `/${pathList[0]}/${window.location.search}` }
      ]
    }
    if (pathList.length === 2) { // Nível 2
      return [
        { title: 'Cursos', address: links[0] ? links[0].address : `/${pathList[0]}/` },
        { title: `${this.course ? this.course.name : themeConfig.terms.curso}`, address: `/${pathList[0]}/${pathList[1]}/${window.location.search}` }
      ]
    }
  }
}

export default new CourseStore()
