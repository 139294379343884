import { combineReducers } from 'redux'
import liveClasses from './ducks/liveclass'
import activation from './ducks/activation'
import taskdelivery from './ducks/taskdelivery'
import discipline from './ducks/discipline'

export default combineReducers({
  liveClasses,
  activation,
  discipline,
  taskdelivery
})
