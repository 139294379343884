import BaseRequests, { URLS } from './BaseRequests'

class UserRequests {
  static login (email, password) {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)
    return BaseRequests.post(URLS.LOGIN, formData)
  }

  static logout () {
    return BaseRequests.getWithoutCookies(URLS.LOGOUT)
  }

  static signup (name, email, password) {
    return BaseRequests.post(URLS.SIGNUP, { name, email, password })
  }

  static loginGuest () {
    return BaseRequests.getWithoutCookies(URLS.LOGIN_GUEST)
  }

  static passwordReset (email) {
    return BaseRequests.post(URLS.PASSWORD_RESET, { email })
  }

  static passwordChange (old_password, new_password1, new_password2) {
    return BaseRequests.post(URLS.PASSWORD_CHANGE, { old_password, new_password1, new_password2 })
  }

  static setCpf (cpf) {
    return BaseRequests.put(URLS.SET_CPF, { cpf })
  }

  static getCpf () {
    return BaseRequests.get(URLS.SET_CPF)
  }

  static getInfo () {
    return BaseRequests.post(URLS.USER_INFO)
      .then(response => response.data.success && response.data)
  }

  static setName (name) {
    return BaseRequests.post(URLS.SET_USER_NAME, { name })
  }

  static getPermissions (institution) {
    return BaseRequests.post(URLS.USER_PERMISSIONS, { institution })
      .then(response => response.data)
  }

  static getCurrentInstitution () {
    const data = { default: null } // Return null if not set server side
    return BaseRequests.post(URLS.GET_CURRENT_INSTITUTION, data)
      .then(response => response.data.value)
  }

  static setCurrentInstitution (id) {
    const data = { value: id }
    return BaseRequests.post(URLS.SET_CURRENT_INSTITUTION, data)
      .then(response => response.data.success)
  }

  static getInstitutions () {
    return BaseRequests.post(URLS.USER_INSTITUTIONS)
      .then(response => response.data.institutions)
  }
}

export default UserRequests
