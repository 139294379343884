import BaseRequests, { URLS } from './BaseRequests'

class DisciplineRequests {
  static new (institution, name) {
    const data = { institution, name }
    return BaseRequests.post(URLS.NEW_DISCIPLINE, data).then(
      response => response.data
    )
  }

  static get (institution, discipline_id = false, to_attach = false, page = 1, limit = 11, search = undefined, full = false, trail, all = false) {
    const data = { institution, page, limit }

    if (search) data.search = search
    if (discipline_id) data.id = discipline_id
    if (to_attach) data.to_attach = true
    if (trail) data.trail = trail
    if (all) data.all = true

    return BaseRequests.post(URLS.GET_DISCIPLINES, data).then(
      response => {
        if (full) {
          return response
        } else {
          return discipline_id ? response.data.discipline : response.data.disciplines
        }
      }
    )
  }

  static getLessonsGroup (institution) {
    const data = { institution }
    return BaseRequests.post(URLS.GET_LESSONS_GROUP, data).then(
      response => response.data.objs
    )
  }

  static setLessonGroup (institution, discipline_id, lessons_group_id) {
    const data = { institution, discipline_id, lessons_group_id }
    return BaseRequests.post(URLS.SET_LESSONS_GROUP, data).then(
      response => response.data
    )
  }

  static update (institution, discipline) {
    const data = this.formatData(institution, discipline)
    return BaseRequests.post(URLS.UPDATE_DISCIPLINE, data).then(
      response => response.data
    )
  }

  static delete (institution, discipline_id) {
    const data = { institution, id: discipline_id }
    return BaseRequests.post(URLS.DELETE_DISCIPLINE, data).then(
      response => response.data
    )
  }

  static formatData (institution, discipline) {
    const data = { ...discipline }
    data.institution = institution
    if (data.content_manager) data.content_manager = data.content_manager.id
    if (data.lessons_group) data.lessons_group = data.lessons_group.id
    return data
  }
}

export default DisciplineRequests
