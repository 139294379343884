import { extendObservable } from 'mobx'
import ForumModel, { Comment } from '../models/ForumModel'
import LessonsModel from '../models/LessonsModel'
import ForumRequests from '../services/requests/ForumRequests'
import VirtualRoomStore from '../stores/VirtualRoomStore'
import { dateToText, themeConfig } from '../Util'
import AuthStore from './AuthStore'
import { STUDENT } from './UsersStore'
import _ from 'lodash'
import TrailStore from './TrailStore'

class ForumStore {
  editingComment = undefined;

  constructor () {
    extendObservable(this, {
      filter: '',
      hidden: false, // loading
      pending_comments: [],
      reported_comments: [],
      forums: [],
      components: [],
      forum: undefined,
      classes: [],
      lessons: [],
      comments: [],
      disciplines: [],
      discipline: undefined,
      group_id: 0
    })
  }

  /******************************************************************************************************************************/
  /* DUCKING TYPE STORE *********************************************************************************************************/

  /******************************************************************************************************************************/

  get user () {
    return AuthStore.user
  }

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 2) { // Nível 1
      return 'Fórum'
    }
    if (pathList.length === 3) { // Nível 2
      return themeConfig.terms.turmasC
    }
    if (pathList.length === 4) { // Nível 4
      return 'Tópicos'
    }
    if (pathList.length === 5) {
      if (pathList[4] === 'new') {
        return 'Novo Tópico'
      }
      return 'Tópico'
    }
    if (pathList.length === 6) {
      if (pathList[5] === 'edit') {
        return 'Agendando'
      }
    }
    throw new Error(`${ForumStore.name} não está configurado para o caminho ${window.location.pathname}`)
  }

  /******************************************************************************************************************************/
  /* REQUESTS *******************************************************************************************************************/

  /******************************************************************************************************************************/

  getLinks () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    this.queryTrailId = !_.isEmpty(trailId) ? `?trail=${trailId}` : ''

    if (pathList.length === 2) { // Nível 2
      return [
        { title: `Fórum`, address: `/${pathList[0]}/forum/${this.queryTrailId}` }
      ]
    }
    if (pathList.length === 3) { // Nível 3
      return [
        { title: 'Sala Virtual',
          address: `/${pathList[0]}/${this.queryTrailId}`
        },
        { title: 'Fórum',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}` },
        {
          title: `${this.panelClass ? this.panelClass.discipline.name : 'Turmas'}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}`
        }
      ]
    }
    if (pathList.length === 4) { // Nível 4
      return [
        { title: 'Sala Virtual',
          address: `/${pathList[0]}/`
        },
        { title: 'Fórum', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}` },
        {
          title: `${this.panelClass ? this.panelClass.discipline.name : 'Turma'}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}`
        },
        { title: 'Tópicos', address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${this.queryTrailId}` }
      ]
    }
    if (pathList.length === 5) {
      return [
        { title: 'Sala Virtual',
          address: `/${pathList[0]}/`
        },
        { title: 'Fórum',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}` },
        {
          title: `${this.panelClass ? this.panelClass.discipline.name : 'Turmas'}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}`
        },
        { title: 'Tópicos',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${this.queryTrailId}`
        },
        { title: 'Tópico',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${pathList[4]}/${this.queryTrailId}`
        }
      ]
    }
    if (pathList.length === 6) {
      return [
        { title: 'Fórum',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/`
        },
        {
          title: `${this.panelClass ? this.panelClass.discipline.name : 'Turmas'}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${this.queryTrailId}`
        },
        { title: 'Tópicos',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${this.queryTrailId}`
        },
        {
          title: 'Agendando',
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${pathList[3]}/${pathList[4]}/${pathList[5]}/${this.queryTrailId}`
        }
      ]
    }
    throw new Error(`${ForumStore.name} não está configurado para o caminho ${window.location.pathname}`)
  }

  newTopic (data) {
    this.hidden = false
    return ForumRequests.newTopic(data)
      .finally(() => { this.hidden = true })
  }

  editTopic (data) {
    this.hidden = false
    data.forum_id = this.forum.id
    return ForumRequests.updateTopic(data)
      .finally(() => { this.hidden = true })
  }

  // retorna as disciplinas
  async getDisciplines (role) {
    this.hidden = false
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    let promise = null

    if (_.isEmpty(this.queryTrailId)) {
      if (role === STUDENT) {
        promise = VirtualRoomStore.getKnowareasCategories(false, true, true)
      } else {
        promise = VirtualRoomStore.getKnowareasCategories(true, false, false)
      }
      return promise
        .then(() => { this.disciplines = VirtualRoomStore.disciplines })
        .finally(() => { this.hidden = true })
    } else {
      promise = TrailStore.fetchTrail(trailId)
      return promise
        .then((response) => { this.disciplines = response.data.disciplines })
        .finally(() => { this.hidden = true })
    }
  }

  // retorna os foruns em que o usuário está envolvido.
  async getForums (role, discipline_id, classId) {
    discipline_id = parseInt(discipline_id, 10)

    this.hidden = false
    const { id_institution, id_user } = this.getIdsAuths()
    const promise = null

    if (this.disciplines.length) {
      this.discipline = _.isEmpty(this.queryTrailId)
        ? this.disciplines.find(discipline => discipline.id === discipline_id)
        : this.disciplines.find(discipline => discipline.discipline === discipline_id)

      if (!_.isEmpty(this.discipline)) {
        if (role === STUDENT) {
          await ForumRequests.getForumsStudent(id_institution, id_user,
            _.isEmpty(this.queryTrailId) ? this.discipline.lessons_group.id : this.discipline.discipline_id.lessons_group.id)
            .then(response => {
              this.forums = response.forums.map(forum => new ForumModel(forum))
            }).finally(() => { this.hidden = true })
        } else {
          await ForumRequests.getForumsTutor(id_institution, id_user,
            _.isEmpty(this.queryTrailId) ? this.discipline.lessons_group.id : this.discipline.discipline_id.lessons_group.id,
            _.isEmpty(this.queryTrailId) ? this.discipline.id : this.discipline.discipline,
            classId)
            .then(response => {
              this.classes = response.classes
              this.forums = this.classes.reduce((acc, clas) => [...acc, ...clas.forums], []).map(forum => new ForumModel(forum))
              // this.components = this.forums.reduce((acc, forum) => [...acc, ...forum.components], []).map(component => new ForumModel(component))
              this.reported_comments = response.reported_comments.map(comment => new Comment(comment, this.getForumById(comment.forum_id)))
              this.pending_comments = response.pending_comments.map(comment => new Comment(comment, this.getForumById(comment.forum_id)))
              // organiza do mais recente ao mais antigo.
              this.forums.forEach(forum => this.orderCommentsForum(forum.comments))
            }).finally(() => { this.hidden = true })
        }
      }
    }
  }

  getLessons () {
    if (!this.discipline) throw Error('Discipline is not valid')
    this.hidden = false
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    return ForumRequests.getLessons(_.isEmpty(trailId) ? this.discipline.id : this.discipline.discipline)
      .then(data => {
        this.group_id = data.group.id
        this.lessons = data.lessons.map(lesson => new LessonsModel(lesson))
      })
      .catch(console.log)
      .finally(() => { this.hidden = true })
  }

  getComponents (forum_id) {
    this.hidden = false
    this.components = this.forums.find(f => forum_id === f.id)
    this.hidden = true
  }

  // retorna as turmas da disciplina selecionada.
  classesDiscipline (discipline_id) {
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    if (!this.discipline) throw Error('Discipline is not valid')
    this.hidden = false
    const disciplines = _.isEmpty(trailId) ? this.disciplines.filter(discipline => discipline.id === parseInt(discipline_id))
      : this.disciplines.filter(discipline => discipline.discipline === parseInt(discipline_id))
    this.classes = disciplines.map(discipline => discipline.classes)
  }

  // retorna os comentários relacionados ao parent.

  // retorna forum informado
  getForum (forum_id) {
    forum_id = parseInt(forum_id)
    this.hidden = false
    this.forum = this.forums.find(f => forum_id === f.id)
    this.hidden = true
  }

  // comentários de tópicos, ou comentários de comentáios.
  getComments (parent) {
    if (!parent) parent = this.forum

    // ou envia o forum_id ou o parent_id
    const forum_id = parent === this.forum ? parent.id : undefined
    const parent_id = parent !== this.forum ? parent.id : undefined
    const orderForum = parent === this.forum

    this.hidden = false
    return ForumRequests.getComments(forum_id, parent_id)
      .then(data => { parent.comments = data.comments.map(comment => new Comment(comment, parent)) })
      .then(() => orderForum && this.orderCommentsForum(parent.comments))
      .then(() => !orderForum && this.orderComments(parent.comments))
      .catch(console.log)
      .finally(() => { this.hidden = true })
  }

  // cria comentário no tópico do fórum ou nos comentários.
  newComment (content, parent, attachments, role) {
    const isCommentAnwear = !!parent
    if (!parent) parent = this.forum
    // quando é filho direto do próprio tópico não precisa evidenciar quem é o pai.
    const parent_id = parent !== this.forum ? parent.id : null

    this.hidden = false
    return ForumRequests.newComment(this.forum.id, parent_id, content, attachments, role, this.getIdsAuths().id_institution)
      .then(response =>
        this.getComments(parent)
          .then(() => response.comment.id))
      .then(id => {
        if (isCommentAnwear) return parent.comments_count++
        return parent.comments.find(c => id === c.id)
      })
      .finally(() => (this.hidden = true))
  }

  // envia para server atualização do comentário.
  editComment (comment) {
    const attachments = comment.attachments.map(attach => attach.id)
    return ForumRequests.editComment(comment.id, comment.content, attachments)
      .finally(() => { this.hidden = true })
  }

  // remove um comentário da lista.
  deleteComment (comment, list, removeFromList = false) {
    this.hidden = false
    return ForumRequests.deleteComment(comment.id, AuthStore.user.id)
      .then((res) => {
        if (removeFromList) this.removeFromList(comment, list)
        else comment.removed = true; comment.removed_by = res.removed_by
      })
      .catch(console.log)
      .finally(() => { this.hidden = true })
  }

  reproveComment (comment, list, removeFromList = false) {
    this.hidden = false
    return ForumRequests.reproveComment(comment.id, AuthStore.user.id)
      .then((res) => {
        if (removeFromList) this.removeFromList(comment, list)
        else comment.removed = true; comment.removed_by = res.removed_by
      })
      .finally(() => { this.hidden = true })
  }

  recoverComment (comment, list) {
    this.hidden = false
    return ForumRequests.recoverComment(comment.id)
      .then(() => { comment.removed = false })
      .finally(() => { this.hidden = true })
  }

  likeComment (comment) {
    this.hidden = false
    return ForumRequests.likeComment(comment.id, this.getIdsAuths().id_institution)
      .then(data => {
        comment.liked = data.comment.liked
        comment.likes = data.comment.likes
      })
      .finally(() => { this.hidden = true })
  }

  starComment (comment) {
    this.hidden = false
    return ForumRequests.starComment(comment.id, !comment.starred, this.getIdsAuths().id_institution)
      .then(() => { comment.starred = !comment.starred })
      .finally(() => { this.hidden = true })
  }

  scheduleForum (start_date, end_date) {
    // yyyy-mm-dd
    this.hidden = false
    start_date = start_date && dateToText(start_date)
    end_date = end_date && dateToText(end_date)

    return ForumRequests.scheduleForum(this.forum.id, start_date, end_date)
      .then(() => {
        this.forum.start_date = start_date
        this.forum.end_date = end_date
      })
      .finally(() => { this.hidden = true })
  }

  validateComment (comment, list) {
    this.hidden = false
    return ForumRequests.validateComment(comment.id)
      .then(() => { comment.reported = false })
      .then(() => this.removeFromList(comment, list))
      .finally(() => { this.hidden = true })
  }

  reportComment (comment, list) {
    return ForumRequests.reportComment(comment.id, this.getIdsAuths().id_institution)
      .then(() => this.getComments())
      .finally(() => { this.hidden = true })
  }

  approveComment (comment, list) {
    this.hidden = false
    return ForumRequests.approveComment(comment.id)
      .then(() => { comment.approved = true })
      .then(() => this.removeFromList(comment, list))
      .finally(() => { this.hidden = true })
  }

  /******************************************************************************************************************************/
  /* UTILS **********************************************************************************************************************/

  /******************************************************************************************************************************/

  setActiveTopic (forum_id) {
    const forum = this.getForumById(forum_id)
    return ForumRequests.setActiveTopic(forum.id, !forum.active)
      .then(() => { forum.active = !forum.active })
      .finally(() => { this.hidden = true })
  }

  // ordena comentários de fórum.
  orderCommentsForum (comments) {
    comments.sort(function (a, b) {
      if (a.created < b.created) {
        return 1
      }
      if (a.created > b.created) {
        return -1
      }
      return 0
    })
    const highlight = (this.forum && this.forum.highlight_starred) ? this.forum.highlight_starred : undefined
    highlight && comments.sort(function (a, b) {
      if (!a.starred && b.starred) {
        return 1
      }
      if (a.starred && !b.starred) {
        return -1
      }
      return 0
    })
  }

  // ordena comentários de comentários.
  orderComments (comments) {
    comments.sort(function (a, b) {
      if (a.created > b.created) {
        return 1
      }
      if (a.created < b.created) {
        return -1
      }
      return 0
    })
  }

  getPendencies () {
    return this.reported_comments.length + this.pending_comments.length
  }

  getIdsAuths () {
    return { id_institution: AuthStore.institution_current.id, id_user: AuthStore.user.id }
  }

  resetForum () {
    this.classes = []
    this.forums = []
    this.reported_comments = []
    this.pending_comments = []
  }

  getForumById (id) {
    return this.forums.find(f => f.id === id)
  }

  removeFromList (comment, list) {
    if (list && comment) {
      comment = list.find(item => item.id === comment.id)
      const index = list.indexOf(comment)
      if (index !== -1) {
        list.splice(index, 1)
      }
    }
  }

  isTutorCreadedTopic (ownerId) {
    return AuthStore.user.id === ownerId
  }
}

export default new ForumStore()
