import { put, all, call, takeLatest } from 'redux-saga/effects'
import { Types, Creators } from './index'
import LiveClassRequests from '../../../services/requests/LiveClassRequests'

function * getLiveClassData (action) {
  try {
    const { data } = yield LiveClassRequests.getData(action.params)
    const { results: classesData } = data
    yield put(Creators.formDataSuccess(classesData))
  } catch (error) {
    yield put(Creators.formDataFailure(error))
  }
}

function * createLiveClass (action) {
  try {
    const { data } = yield LiveClassRequests.createLiveClass(action.data)
    yield put(Creators.createSuccess(data))
  } catch (error) {
    yield put(Creators.createFailure(error))
  }
}

function * getLiveClasses (action) {
  try {
    const { data } = yield LiveClassRequests.getLiveClasses(action.params)
    yield put(Creators.allSuccess(data))
  } catch (error) {
    yield put(Creators.allFailure(error))
  }
}

function * getLiveClass (action) {
  try {
    const { data } = yield LiveClassRequests.getLiveClass(action.params)
    yield put(Creators.getSuccess(data))
    if (action.params.tutor) { yield call(getRoom, action, data.room) }
  } catch (error) {
    yield put(Creators.getFailure(error))
  }
}

function * getRoom (action, room) {
  try {
    const { data } = yield LiveClassRequests.getRoom(action.params, room)
    yield put(Creators.getRoomSuccess(data))
  } catch (error) {
    yield put(Creators.getRoomFailure(error))
  }
}

function * update (action) {
  try {
    const { data } = yield LiveClassRequests.updateLiveClasses(action.data)
    yield put(Creators.createSuccess(data))
  } catch (error) {
    yield put(Creators.createFailure(error))
  }
}

function * deleteLiveClass (action) {
  try {
    const { data } = yield LiveClassRequests.deleteLiveClasses(action.data)
    yield put(Creators.createSuccess(data))
  } catch (error) {
    console.log(error)
    yield put(Creators.createFailure(error))
  }
}

function * getLessonAttendance (action) {
  try {
    const { data } = yield LiveClassRequests.getLessonAttendance(action.data)
    yield put(Creators.getLessonAttendanceSuccess(data))
  } catch (error) {
    yield put(Creators.getLessonAttendanceFailure(error))
  }
}

function * massAttendance (action) {
  try {
    const { data } = yield LiveClassRequests.setMassAttendance(action.data)
    yield put(Creators.massAttendanceSuccess(data))
  } catch (error) {
    yield put(Creators.massAttendanceFailure(error))
  }
}

export default all([
  takeLatest(Types.FORM_DATA_REQUEST, getLiveClassData),
  takeLatest(Types.CREATE_REQUEST, createLiveClass),
  takeLatest(Types.ALL_REQUEST, getLiveClasses),
  takeLatest(Types.GET_REQUEST, getLiveClass),
  takeLatest(Types.UPDATE_REQUEST, update),
  takeLatest(Types.DELETE_REQUEST, deleteLiveClass),
  takeLatest(Types.GET_LESSON_ATTENDANCE_REQUEST, getLessonAttendance),
  takeLatest(Types.MASS_ATTENDANCE_REQUEST, massAttendance)
])
