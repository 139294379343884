import { put, all, takeLatest } from 'redux-saga/effects'
import { Types, Creators } from './index'
import ActivationRequests from '../../../services/requests/ActivationRequests'

function * createCode (action) {
  try {
    const { data } = yield ActivationRequests.createCode(action.params)
    yield put(Creators.createCodeSuccess(data))
  } catch (error) {
    yield put(Creators.createCodeFailure(error))
  }
}

function * getCode (action) {
  try {
    const { data } = yield ActivationRequests.getCode(action.params)
    yield put(Creators.getCodeSuccess(data))
  } catch (error) {
    yield put(Creators.getCodeFailure(error))
  }
}

function * createExternalUser (action) {
  try {
    const { data } = yield ActivationRequests.createExternalUser(action.params)
    yield put(Creators.createExternalUserSuccess(data))
  } catch (error) {
    yield put(Creators.createExternalUserFailure(error))
  }
}

export default all([
  takeLatest(Types.CREATE_CODE_REQUEST, createCode),
  takeLatest(Types.GET_CODE_REQUEST, getCode),
  takeLatest(Types.CREATE_EXTERNAL_USER_REQUEST, createExternalUser)
])
