import { extendObservable } from 'mobx'

import AuthStore from './AuthStore'
import CourseStore from './CourseStore'
import DisciplineStore from './DisciplineStore'
import ClassStore from './ClassStore'

import UsersRequests from '../services/requests/UsersRequests'

import UserModel from '../models/UserModel'
import UserAndInviteModel from '../models/UserAndInviteModel'

export const ADMIN = 1
export const MANAGER = 2
export const CONTENT_MANAGER = 3
export const TUTOR = 4
export const CURATOR = 5
export const STUDENT = 6
export const SECRETARY = 7

class UsersStore {
  constructor () {
    extendObservable(this, {
      user: undefined,
      users: undefined,
      admins: undefined,
      managers: undefined,
      content_managers: undefined,
      tutors: undefined,
      tutors_in_class: undefined,
      currentTutorsInClassPage: 0,
      curators: undefined,
      students: undefined,
      students_in_class: undefined,
      currentStudentsInClassPage: 0,
      users_and_invites: undefined,
      loading: false,
      filter: '',
      studentLinks: [],
      currentPage: 1,
      pageCount: 1
    })
  }

  /**
   * Retorna a lista de cursos carregados da store de cursos
   */
  get courses () {
    return CourseStore.courses
  }

  /**
   * Retorna o curso carregado da store de cursos
   */
  get course () {
    return CourseStore.course
  }

  /**
   * Retorna a disciplina carregada da store de disciplinas
   */
  get discipline () {
    return DisciplineStore.discipline
  }

  /**
   * Retorna a lista de turmas carregadas da store de turmas
   */
  get classes () {
    return ClassStore.classes
  }

  /**
   * Retorna a turma carregada da store de turmas
   */
  get clas () {
    return ClassStore.clas
  }

  get institutionId () {
    return this.institutionCurrent.id
  }

  get institutionCurrent () {
    return AuthStore.institution_current
  }

  get permissions () {
    return AuthStore.permissions
  }

  get currentUser () {
    return AuthStore.user.id
  }

  reset () {
    this.user = undefined
    this.users = undefined
    this.admins = undefined
    this.managers = undefined
    this.content_managers = undefined
    this.tutors = undefined
    this.tutors_in_class = undefined
    this.curators = undefined
    this.students = undefined
    this.students_in_class = undefined
    this.users_and_invites = undefined
    this.loading = false
    this.filter = ''
  }

  /**
   * Carrega lista de cursos da store de cursos
   */
  getCourses () {
    return CourseStore.getCourses()
  }

  /**
   * Carrega um curso da store de cursos
   * @param {Integer} course_id ID do curso
   * @param {Boolean} disciplines Opcional, incluir disciplinas
   */
  getCourse (course_id, disciplines = true) {
    return CourseStore.get(course_id, disciplines)
  }

  /**
   * Carrega uma disciplina da store de disciplinas
   */
  getDiscipline (disciplineId) {
    return DisciplineStore.get(disciplineId)
  }

  /**
   * Carrega lista de turmas da store de turmas
   * @param {Integer} disciplineId ID da disciplina a buscar turmas
   */
  getClasses (disciplineId = undefined, remove_expired = false) {
    return ClassStore.getList(disciplineId, remove_expired)
  }

  /**
   * Carrega uma turma da store de turmas
   * @param {Integer} class_id ID do turma
   */
  getClass (class_id) {
    return ClassStore.get(class_id)
  }

  hasPermission (value) {
    return AuthStore.hasPermission(value)
  }

  invite (name, email, roles, classes = [], re_invite = false, institution = undefined, registry = undefined, trails = []) {
    this.loading = true
    if (!institution) {
      institution = this.institutionId
    }
    return UsersRequests.invite(institution, name, email, roles, re_invite, classes, registry, trails)
      .catch(res => {
        return res
      })
      .finally(this.loading = false)
  }

  /**
   * @function updateUserStatus Updates a user activation status
   * @param {Object} payload Information about the user and the updating data
   * @param {Integer} payload.institutionId The updating user institution ID
   * @param {Integer} payload.userId The updating user ID
   * @param {Boolean} payload.active The user's new activation status
   * @param {Integer} payload.currentPage Current page for returning request response in a specific page
   * @param {Array} payload.roles Array of user roles for returning request response with specific user roles
   * @param {Boolean} payload.preventReload Prevents updating the store list of users
   */
  updateUserStatus ({ institutionId, userId, active, currentPage = 1, roles = [STUDENT], preventReload = false }) {
    this.loading = true

    return UsersRequests.updateUserStatus(institutionId, userId, active)
      .then(response => {
        if (response.data.success) if (!preventReload) this.getUsersAndInvites(roles, currentPage)
      })
      .finally(this.loading = false)
  }

  getUser (id, staff_call = false) {
    this.loading = true
    const institution = staff_call ? undefined : this.institutionId
    return UsersRequests.getUser(institution, id)
      .then(user => this.user = new UserModel(user))
      .finally(this.loading = false)
  }

  updateUser (id, update) {
    this.loading = true
    return UsersRequests.updateUser(AuthStore.institution_current.id, id, update)
      .finally(this.loading = false)
  }

  updateInvite (id, update, trails) {
    this.loading = true
    return UsersRequests.updateInvite(AuthStore.institution_current.id, id, update, trails)
      .finally(this.loading = false)
  }

  deleteUser (id, student_only) {
    this.loading = true
    return UsersRequests.delete(AuthStore.institution_current.id, id, student_only)
      .finally(this.loading = false)
  }

  deleteInvite (id) {
    this.loading = true
    return UsersRequests.deleteInvite(AuthStore.institution_current.id, id)
      .finally(this.loading = false)
  }

  filterItems (list) {
    return list && list.filter(item =>
      item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().indexOf(
        this.filter.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) !== -1 || (item.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().indexOf(
        this.filter.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) !== -1) || (item.registry !== null && item.registry.indexOf(this.filter) !== -1)
    )
  }

  getUsers (roles, staff_call = false) {
    this.loading = true
    const institution = staff_call ? undefined : this.institutionId
    return UsersRequests.getUsers(institution, roles)
      .then(users => this.users = users.map(user => new UserModel(user)))
      .finally(this.loading = false)
  }

  filterUsers () {
    return this.filterItems(this.users)
  }

  getUsersAndInvites (roles, pageNumber, filter, filterList = {}) {
    this.loading = true
    this.currentPage = pageNumber
    return UsersRequests.getUsersAndInvites(AuthStore.institution_current.id, roles, pageNumber, filter, filterList)
      .then(data => {
        this.users_and_invites = data.users.map(data => new UserAndInviteModel(data))
        this.pageCount = data.page.num_pages
      })
      .catch(() => this.loading = false)
      .finally(() => this.loading = false)
  }

  filterUsersAndInvites () {
    return this.filterItems(this.users_and_invites)
  }

  loadAdmins () {
    this.loading = true
    return UsersRequests.getUsers(AuthStore.institution_current.id, [ADMIN])
      .then(users => this.admins = users.map(user => new UserModel(user)))
      .finally(this.loading = false)
  }

  filterAdmins () {
    return this.filterItems(this.admins)
  }

  loadManagers () {
    this.loading = true
    return UsersRequests.getUsers(AuthStore.institution_current.id, [MANAGER])
      .then(users => this.managers = users.map(user => new UserModel(user)))
      .finally(this.loading = false)
  }

  filterManagers () {
    return this.filterItems(this.managers)
  }

  loadContentManagers () {
    this.loading = true
    return UsersRequests.getUsers(AuthStore.institution_current.id, [CONTENT_MANAGER])
      .then(users => this.content_managers = users.map(user => new UserModel(user)))
      .finally(this.loading = false)
  }

  filterContentManagers () {
    return this.filterItems(this.content_managers)
  }

  loadTutors ({ class_id, not_in_discipline, not_in_class, skipLoad = false, search = '', page = 1, full_data = false }) {
    if (!skipLoad) this.loading = true
    if (class_id) {
      return UsersRequests.getUsersClass(AuthStore.institution_current.id, class_id, true, search, page)
        .then(data => {
          this.tutors_in_class = data.users.map(user => new UserModel(user))
          this.currentTutorsInClassPage = 0
          this.page = data.page
        })
        .finally(() => { if (!skipLoad) this.loading = false })
    } else {
      return UsersRequests.getUsers(AuthStore.institution_current.id, [TUTOR], not_in_discipline, not_in_class, search, page, full_data)
        .then(data => {
          if (full_data) {
            this.tutors = data.users.map(user => new UserModel(user))
            this.page = data.page
            return
          }
          this.tutors = data.map(user => new UserModel(user))
          this.page = data.page
        })
        .finally(() => { if (!skipLoad) this.loading = false })
    }
  }

  studentsInPage (itemsPerPage) {
    const min = (this.currentStudentsInClassPage + 0) * (itemsPerPage - 1) // index min
    const max = (this.currentStudentsInClassPage + 1) * (itemsPerPage - 1) // index max
    return this.filterStudentsInClass().slice(min, max)
  }

  tutorsInPage (itemsPerPage) {
    const min = (this.currentTutorsInClassPage + 0) * (itemsPerPage - 1) // index min
    const max = (this.currentTutorsInClassPage + 1) * (itemsPerPage - 1) // index max
    return this.filterTutorsInClass().slice(min, max)
  }

  filterTutors () {
    return this.filterItems(this.tutors)
  }

  filterTutorsInClass () {
    return this.filterItems(this.tutors_in_class)
  }

  loadCurators () {
    this.loading = true
    return UsersRequests.getUsers(AuthStore.institution_current.id, [CURATOR])
      .then(users => this.curators = users.map(user => new UserModel(user)))
      .finally(this.loading = false)
  }

  filterCurators () {
    return this.filterItems(this.curators)
  }

  loadStudents ({ class_id, not_in_discipline, not_in_class, skipLoad = false, search = '', page = 1, full_data = false }) {
    if (!skipLoad) this.loading = true
    if (class_id) {
      return UsersRequests.getUsersClass(AuthStore.institution_current.id, class_id, false, search, page)
        .then(data => {
          this.students_in_class = data.users && data.users.map(user => new UserModel(user))
          this.currentStudentsInClassPage = 0
          this.page = data.page
        })
        .finally(() => { if (!skipLoad) this.loading = false })
    } else {
      return UsersRequests.getUsers(AuthStore.institution_current.id, [STUDENT], not_in_discipline, not_in_class, search, page, full_data)
        .then(data => {
          if (full_data) {
            this.students = data.users.map(user => new UserModel(user))
            this.page = data.page
            return
          }
          this.students = data.map(user => new UserModel(user))
          this.page = data.page
        })
        .finally(() => { if (!skipLoad) this.loading = false })
    }
  }

  filterStudents () {
    return this.filterItems(this.students)
  }

  filterStudentsInClass () {
    return this.filterItems(this.students_in_class)
  }

  getStudentLinks (studentId, invite = false) {
    this.loading = true
    let linkPromise

    const user = this.users_and_invites.find(user => user.id === studentId && user.invite === invite)

    if (user && user.invite) {
      linkPromise = UsersRequests.getinviteLinks(AuthStore.institution_current.id, studentId)
    } else {
      linkPromise = UsersRequests.getStudentLinks(AuthStore.institution_current.id, studentId)
    }

    return linkPromise
      .then(res => {
        this.studentLinks = {
          classes: res.data.classes,
          trails: res.data.trails
        }
      })
      .finally(() => { this.loading = false })
  }

  deleteStudent (id, studentOnly, currentPage = 1) {
    let promiseDelete
    if (this.users_and_invites.find(user => user.id === id).invite) {
      promiseDelete = this.deleteInvite(id)
    } else {
      promiseDelete = this.deleteUser(id, studentOnly)
    }
    return promiseDelete
      .then(response => {
        if (response.data.success) {
          this.getUsersAndInvites([STUDENT], currentPage)
        }
        return response.data
      })
  }

  /**
   * @function updateStudent Updates partially or completelly a student
   * @param {Integer} id Updating student id
   * @param {Object} data Playload with the student's properties to update
   */
  updateStudent (id, data) {
    let promiseUpdate

    if (this.users_and_invites.find(user => user.id === id).invite) {
      promiseUpdate = this.updateInvite(id, data)
    } else {
      promiseUpdate = this.updateUser(id, data)
    }
    return promiseUpdate
  }

  addStudentLinks (studentId, classes, trails) {
    let addPromise
    if (this.users_and_invites.find(user => user.id === studentId).invite) {
      addPromise = this.updateInvite(studentId, classes, trails)
    } else {
      addPromise = UsersRequests.addStudentLinks(AuthStore.institution_current.id, studentId, classes, trails)
    }
    return addPromise.then(_ => this.getStudentLinks(studentId))
  }

  deleteStudentLinks (studentId, classes, trails) {
    let deletePromise
    if (this.users_and_invites.find(user => user.id === studentId).invite) {
      // addPromise = this.updateInvite(studentId, classes)
    } else {
      deletePromise = UsersRequests.deleteStudentLinks(AuthStore.institution_current.id, studentId, classes, trails)
    }
    return deletePromise.then(_ => this.getStudentLinks(studentId))
  }

  async validateStudentData (email, registry) {
    const response = await UsersRequests.validateStudentData(this.institutionId, email, registry)
    return response
  }

  handleClickUpdateStatus (userId, status) {

  }
}

export default new UsersStore()
