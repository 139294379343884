import React from 'react'
import { observer } from 'mobx-react'
import Loadable from 'react-loadable'
import TagManager from 'react-gtm-module'

import { withRouter, Route } from 'react-router-dom'
import { extendObservable } from 'mobx'
import { getThemeConfig, getThemeDomain } from './Util'
import { tagManagerArgs } from './settings'

import AuthStore from './stores/AuthStore'
import BaseRequests from './services/requests/BaseRequests'

import './include/bootstrap'
import LoaderComponent from './components/LoaderComponent'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import VLibras from '@djpfs/react-vlibras'

const LoadingFullScreen = Loadable({
  loader: () => import('./components/LoadingFullScreen'),
  loading: () => <div />
})

const LoginPage = Loadable({
  loader: () => import('./pages/LoginPage'),
  loading: () => <LoaderComponent />
})

const ActivationPage = Loadable({
  loader: () => import('./pages/ActivationPage'),
  loading: () => <LoaderComponent />
})

const ActivateCode = Loadable({
  loader: () => import('./pages/ActivateCode'),
  loading: () => <div/>
})

const MobileClassroom = Loadable({
  loader: () => import('./pages/MobileClassroom'),
  loading: () => <div/>
})

const MobileScorm = Loadable({
  loader: () => import('./pages/MobileScorm'),
  loading: () => <div/>
})

const HomePage = Loadable({
  loader: () => import('./pages/HomePage'),
  loading: () => <LoaderComponent/>
})

const RedirectToManagement = (route) => {
  AuthStore.reset()
  window.location = route
}
const isActivationUrl = window.location.pathname === '/ativacao'
const isActivateCodeUrl = window.location.pathname === '/ativacao-por-codigo'

// Logic behind the links that doesn't need the webplataform login
const mobileLinks = ['/ies2-jitsi', '/scorm']
const isPage = mobileLinks.find(link => link === window.location.pathname)

const event = { change: undefined }
class App extends React.Component {
  constructor (props) {
    super(props)
    TagManager.initialize(tagManagerArgs)
    this.importTheme()
    this.themeConfig = getThemeConfig()

    extendObservable(this, {
      cssLoaded: false
    })
    this.state = { whatsapp: true }
  }

  importTheme () {
    import(`./styles/css/theme_${this.domain}.css`)
      .then(() => {
        this.cssLoaded = true
        AuthStore.updateInfo()
      })
  }

  get index () {
    return <Helmet
      link={[
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${process.env.PUBLIC_URL}/themes/${this.themeConfig.path}/icons/apple-touch-icon.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${process.env.PUBLIC_URL}/themes/${this.themeConfig.path}/icons/favicon-32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${process.env.PUBLIC_URL}/themes/${this.themeConfig.path}/icons/favicon-16x16.png`
        },
        {
          rel: 'mask-icon',
          color: '#e04579',
          href: `${process.env.PUBLIC_URL}/themes/${this.themeConfig.path}/icons/safari-pinned-tab.svg`
        },
        {
          rel: 'manifest',
          href: `${process.env.PUBLIC_URL}/themes/${this.themeConfig.path}/site.webmanifest`
        }
      ]}
      {...(AuthStore.is_authenticated ? { ...this.themeConfig.helmetLogin } : { ...this.themeConfig.helmetLogout })} />
  }

  get domain () {
    return getThemeDomain()
  }

  render () {
    return (
      <React.Fragment>
        <VLibras />
        {this.index}
        <LoadingFullScreen cssLoaded={this.cssLoaded} show={AuthStore.is_authenticated === undefined} />
        {AuthStore.is_authenticated === false && !isActivationUrl && !isActivateCodeUrl && !isPage && <LoginPage />}

        <Route path='/ies2/management/' component={() => RedirectToManagement(BaseRequests.getFullURL('/ies2/management'))}/>
        <Route exact path='/ies2-jitsi/' render={() => <MobileClassroom/>}/>
        <Route exact path='/scorm/' render={() => <MobileScorm/>}/>
        <Route exact path='/ativacao/' render={() => <ActivationPage {...this.props}/>}/>
        <Route exact path='/ativacao-por-codigo/' render={() => <ActivateCode/>}/>

        {AuthStore.is_authenticated === true && <HomePage event={event} />}

        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
        />
        {/* Whatsapp Logo */}
        {AuthStore.is_authenticated && (AuthStore.permissions.helper || AuthStore.permissions.students) && this.state.whatsapp && <div style={{ position: 'fixed', bottom: '20px', right: '20px', height: '75px' }}>
          <div style={{ opacity: '0.6', position: 'absolute', right: '-20px', top: '-5px', padding: '5px' }} onClick={() => this.setState({
            whatsapp: false
          })}>

            <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.353516 0.646409L20.3535 20.6464M20.3535 0.353516L0.353516 20.3535" stroke="black"/>
            </svg>

          </div>
          <a href="https://api.whatsapp.com/send?phone=5519971534404" target="_blank">
            <img className="h-100" src={`${process.env.PUBLIC_URL}/defaults/icons/whatsapp.png`}/>
          </a>
        </div>}

      </React.Fragment>
    )
  }
}

export default withRouter(observer(App))
