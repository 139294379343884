import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../rootReducer'
import rootSaga from '../rootSaga'

const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null
const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

const enhancer = process.env.NODE_ENV === 'development' ? compose(
  console.tron.createEnhancer(),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
) : applyMiddleware(sagaMiddleware)

const store = createStore(
  rootReducer,
  enhancer
)

sagaMiddleware.run(rootSaga)

export default store
