import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  createCodeRequest: ['params'],
  createCodeSuccess: ['data'],
  createCodeFailure: ['error'],
  getCodeRequest: ['params'],
  getCodeSuccess: ['data'],
  getCodeFailure: ['error'],
  createExternalUserRequest: ['params'],
  createExternalUserSuccess: ['data'],
  createExternalUserFailure: ['error'],
  cleanState: null
})

const INITIAL_STATE = {
  isLoading: false,
  error: false
}

// Create a new code
export const cleanState = (state = INITIAL_STATE) => ({ ...state, error: false, code: [] })
export const createCodeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false })
export const createCodeSuccess = (state = INITIAL_STATE, action) => {
  if (action.data.success === false) {
    return { ...state, isLoading: false, error: true }
  } else {
    return {
      ...state,
      isLoading: false,
      error: false,
      code: action.data
    }
  }
}
export const createCodeFailure = (state = INITIAL_STATE, action) => ({ ...state, isLoading: false, error: true })

// Get a code
export const getCodeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, code: [], error: false })
export const getCodeSuccess = (state = INITIAL_STATE, action) => {
  if (action.data.success === false) {
    return { ...state, isLoading: false, error: true }
  } else {
    return {
      ...state,
      isLoading: false,
      error: false,
      code: action.data
    }
  }
}
export const getCodeFailure = (state = INITIAL_STATE, action) => ({ ...state, isLoading: false, error: true })

// Create a new user via email on plataform
export const createExternalUserRequest = (state = INITIAL_STATE) => ({ ...state, user: [], isLoading: true, error: false })
export const createExternalUserSuccess = (state = INITIAL_STATE, action) => {
  if (action.data.success === false) {
    return { ...state, isLoading: false, error: true }
  } else {
    return {
      ...state,
      isLoading: false,
      error: false,
      user: action.data
    }
  }
}
export const createExternalUserFailure = (state = INITIAL_STATE, action) => ({ ...state, isLoading: false, error: true })

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_CODE_REQUEST]: createCodeRequest,
  [Types.CREATE_CODE_SUCCESS]: createCodeSuccess,
  [Types.CREATE_CODE_FAILURE]: createCodeFailure,
  [Types.GET_CODE_REQUEST]: getCodeRequest,
  [Types.GET_CODE_SUCCESS]: getCodeSuccess,
  [Types.GET_CODE_FAILURE]: getCodeFailure,
  [Types.CREATE_EXTERNAL_USER_REQUEST]: createExternalUserRequest,
  [Types.CREATE_EXTERNAL_USER_SUCCESS]: createExternalUserSuccess,
  [Types.CREATE_EXTERNAL_USER_FAILURE]: createExternalUserFailure,
  [Types.CLEAN_STATE]: cleanState
})
