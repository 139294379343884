import BaseRequests, { API } from './BaseRequests'
class ActivationRequests {
  static createCode (params) {
    return BaseRequests.post(`${API}/integrator/external-purchases`, params)
      .then(response => {
        return response
      })
  }

  static getCode (params) {
    return BaseRequests.get(`${API}/integrator/external-purchases/${params.code}`)
      .then(response => {
        return response
      })
  }

  static createExternalUser (params) {
    return BaseRequests.post(`${API}/integrator/external-customer`, params)
      .then(response => {
        return response
      })
  }
}

export default ActivationRequests
