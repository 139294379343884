export default class UserAndInviteModel {
  constructor (data) {
    this.id = data.id
    this._name = data.name
    this.email = data.email
    this.roles = data.roles
    this.invite = data.invite // True se for invite e False se for user
    this.pending = data.pending
    this.dateJoined = data.date_joined ? data.date_joined : null
    this.lastLogin = data.last_login ? data.last_login : null
    this.inviteDate = data.invite_date ? data.invite_date : null
    this.invitedBy = data.invited_by ? data.invited_by : null // <string> nome
    this.classes = data.classes
    this.trails = data.trails
    this.registry = data.registry
    this.allocatedBy = data.allocated_by
    this.activeEnrollment = data.active_enrollment
    this.isOwner = data.is_owner
  }

  get name () {
    if (!this._name) {
      return this.email.split('@', 1)[0]
    }
    return this._name
  };
}
