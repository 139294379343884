import BaseRequests, { URLS } from './BaseRequests'

class ReportRequests {
  static getStaffDashboardReport () {
    const data = {}
    return BaseRequests.post(URLS.GET_STAFF_DASHBOARD_REPORT, data)
      .then(response => {
        return response.data.dashboard
      })
  }

  /**
   * Retorna a URL para exportação de um report
   * @param {string} reportType Path da página de report a obter exportação dos dados
   * @param {Integer} institution ID da instituição
   * @param {Object} filters Objeto com filtros para exportação
   */
  static getExportationURL (reportType, institution, filters) {
    let filtersString = ''

    filtersString += filters.end_date__lt ? (`&end_date__lt=${filters.end_date__lt}`) : ''
    filtersString += filters.end_date__gte ? (`&end_date__gte=${filters.end_date__gte}`) : ''

    switch (reportType) {
    case '/staff-reports/institution/':
      return BaseRequests.getFullURL(URLS.EXPORT_INSTITUTION_REPORT)
    case '/staff-reports/curators/':
      // falls through
    case '/client-reports/curators/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_PEOPLE_REPORT)}?institution=${institution}&curators${filtersString}`
    case '/staff-reports/tutors/':
      // falls through
    case '/client-reports/tutors/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_PEOPLE_REPORT)}?institution=${institution}&tutors${filtersString}`
    case '/staff-reports/content-managers/':
      // falls through
    case '/client-reports/content-managers/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_PEOPLE_REPORT)}?institution=${institution}&content-managers${filtersString}`
    case '/staff-reports/students/':
      // falls through
    case '/client-reports/students/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_PEOPLE_REPORT)}?institution=${institution}&students${filtersString}`
    case '/staff-reports/disciplines/':
      // falls through
    case '/client-reports/disciplines/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_DISCIPLINES_REPORT)}?institution=${institution}${filtersString}`
    case '/staff-reports/lesson-groups/':
      // falls through
    case '/client-reports/lesson-groups/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_LESSON_GROUP_REPORT)}?institution=${institution}${filtersString}`
    case '/staff-reports/courses/':
      // falls through
    case '/staff-reports/classes/':
      // falls through
    case '/client-reports/classes/':
      return `${BaseRequests.getFullURL(URLS.EXPORT_CLASSES_REPORT)}?institution=${institution}${filtersString}`
    default:
      return false
    }
  }

  static getInstitutionReport (institution) {
    let data = {}
    if (institution !== null) {
      data = { institution }
    }
    return BaseRequests.post(URLS.GET_INSTITUTION_REPORT, data)
      .then(response => response.data.institution || response.data.institutions)
  }

  static getLessonGroupReport (data) {
    return BaseRequests.post(URLS.GET_LESSON_GROUP_REPORT, data)
      .then(response => response.data)
  }

  static getCourseReport (data) {
    return BaseRequests.post(URLS.GET_COURSE_REPORT, data)
      .then(response => response.data)
  }

  static getDisciplineReport (data) {
    return BaseRequests.post(URLS.GET_DISCIPLINES_REPORT, data)
      .then(response => response.data)
  }

  static getClassesReport (data) {
    return BaseRequests.post(URLS.GET_CLASSES_REPORT, data)
      .then(response => response.data)
  }

  static getPeopleReport (data) {
    return BaseRequests.post(URLS.GET_PEOPLE_REPORT, data)
      .then(response => response.data)
  }

  static getDashboardReport (institution) {
    return BaseRequests.post(URLS.GET_DASHBOARD_REPORT, { institution })
      .then(response => response.data)
  }
}

export default ReportRequests
