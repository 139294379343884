// import DisciplineModel from './DisciplineModel'
import UserModel from './UserModel'
import DisciplineModel from './DisciplineModel'
import { extendObservable } from 'mobx'

export default class ClassModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.start_date = data.start_date
    this.end_date = data.end_date
    this.remainingDays = data.remaining_days
    this.expires = data.expires
    this.is_trail = data.is_trail
    this.is_vinculated = data.is_vinculated

    // usado em gestão de turmas e disciplinas
    this.tutors = data.tutors ? data.tutors.map(tutor => new UserModel(tutor)) : null
    this.integration = data.integration ? data.integration : ''

    // usado em chat
    this.discipline = data.discipline ? new DisciplineModel(data.discipline) : null

    extendObservable(this, {
      progress: 0,
      performance: 0,

      // usado em gestão de turmas e disciplinas
      students: data.students ? data.students.map(student => new UserModel(student)) : null,
      students_count: data.students_count || 0,
      tutors_count: data.tutors_count || 0
    })
  }
}
