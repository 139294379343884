import { extendObservable } from 'mobx'

import authStore from './AuthStore'

import ClassRequests from '../services/requests/ClassRequests'
import ClassModel from '../models/ClassModel'
import InstitutionConfigStore from './InstitutionConfigStore'
import { themeConfig } from '../Util'
import _ from 'lodash'

class ClassStore {
  constructor () {
    extendObservable(this, {
      classes: undefined,
      clas: undefined,
      canIntegrate: false,
      loading: false,
      filter: ''
    })
  }

  reset () {
    this.classes = undefined
    this.clas = undefined
  }

  /**
   * Carrega configuração de integração da instituição atual
   */
  async loadIntegrationConfig () {
    this.loading = true
    const response = await InstitutionConfigStore.getIndividualConfig(authStore.institution_current.id)
    this.canIntegrate = response.can_integrate
    this.loading = false
  }

  new (mClass, discipline_id = undefined, preventReload = false) {
    this.loading = true
    return ClassRequests.new(authStore.institution_current.id, mClass)
      .then((classResponse) => {
        // erro de turma duplicada
        if (!classResponse.success && classResponse.error === 81) {
          if (classResponse.message.includes('name')) {
            classResponse.message = `Em ${themeConfig.terms.umaDisciplina}, não podem existir ${themeConfig.terms.duasTurmas} com os nomes iguais!`
            return classResponse
          } else {
            classResponse.message = `${themeConfig.terms.suaInstituicaoIC} já possui ${themeConfig.terms.umaTurma} com o mesmo código de integração!`
            return classResponse
          }
          // turma criada com sucesso
        } else {
          if (!preventReload) this.getList(discipline_id, false, false, true)
          return classResponse
        }
      })
      .finally(() => { this.loading = false })
  }

  get (class_id, skipLoad = false) {
    if (!skipLoad) this.loading = true
    return ClassRequests.get(authStore.institution_current.id, class_id, false, false, false, true)
      .then(mClass => { this.clas = new ClassModel(mClass) })
      .finally(() => { if (!skipLoad) this.loading = false })
  }

  getWithPeoples (class_id, with_students = false, with_tutors = false, student = undefined, tutor = undefined) {
    this.loading = true
    return ClassRequests.getWithPeoples(authStore.institution_current.id, class_id, with_students, with_tutors, student, tutor)
      .then(mClass => { this.clas = new ClassModel(mClass) })
      .finally(() => { this.loading = false })
  }

  update (mClass, preventReload = false, skipLoad = false) {
    if (!skipLoad) this.loading = true
    return ClassRequests.update(authStore.institution_current.id, mClass)
      .then((response) => {
        if (!response.success && response.error === 81) {
          if (response.message.includes('name')) {
            response.message = `Em ${themeConfig.terms.umaDisciplina}, não podem existir ${themeConfig.terms.duasTurmas} com os nomes iguais!`
            return response
          } else {
            response.message = `Sua ${themeConfig.terms.instituicao} já possui ${themeConfig.terms.umaTurma} com o mesmo código de integração!`
            return response
          }
        } else if (!response.success && response.error === 95) {
          return response
        } else {
          if (!preventReload) this.getList(mClass.discipline, false, false, true)
          return { success: true }
        }
      })
      .finally(() => { if (!skipLoad) this.loading = false })
  }

  delete (class_id, discipline_id = undefined) {
    this.loading = true
    return ClassRequests.delete(authStore.institution_current.id, class_id)
      .then(() => this.getList(discipline_id, false, false, true))
      .finally(() => { this.loading = false })
  }

  getList (discipline_id = undefined, remove_expired = false, trail, all = false, tutor = false, student = false) {
    this.loading = true
    return ClassRequests.get(authStore.institution_current.id, undefined, discipline_id, remove_expired, trail, all, tutor, student)
      .then(resClass => { this.classes = resClass.map(mClass => new ClassModel(mClass)) })
      .finally(() => { this.loading = false })
  }

  getListWithPeoples (discipline_id, students = false, tutors = false, student = undefined, tutor = undefined) {
    this.loading = true
    const query = new URLSearchParams(window.location.search)
    const trailId = query.get('trail')
    return ClassRequests.getList(authStore.institution_current.id, discipline_id, students, tutors, student, tutor, !_.isEmpty(trailId))
      .then(resClass => { this.classes = resClass.map(mClass => new ClassModel(mClass)) })
      .finally(() => { this.loading = false })
  }

  filterList () {
    return this.classes.filter(clas =>
      clas.name.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(
        this.filter.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) !== -1
    )
  }
}

export default new ClassStore()
