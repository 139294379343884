import BaseRequests, { URLS } from './BaseRequests'

class ClassRequests {
  static new (institution, mClass) {
    const data = { institution, ...mClass }
    return BaseRequests.post(URLS.NEW_CLASS, data)
      .then(response => response.data)
  }

  static getList (institution_id, discipline_id = undefined, with_students, with_tutors, student = undefined, owner_tutor = undefined, isTrail) {
    const data = {
      institution: institution_id,
      with_students: with_students,
      with_tutors: with_tutors
    }
    if (discipline_id) data.disciplines = [discipline_id]
    if (student) data.student = student
    if (owner_tutor) data.owner_tutor = owner_tutor
    if (isTrail) data.trail = isTrail

    return BaseRequests.post(URLS.GET_CLASS, data)
      .then(response => response.data.classes)
  }

  static getWithPeoples (institution_id, class_id = undefined, with_students, with_tutors, student = undefined, tutor = undefined) {
    const data = {
      institution: institution_id,
      with_students: with_students,
      with_tutors: with_tutors
    }
    if (class_id) data.id = class_id
    if (student) data.student = student
    if (tutor) data.tutor = tutor
    return BaseRequests.post(URLS.GET_CLASS, data)
      .then(response => response.data.class)
  }

  static get (institution_id, class_id = undefined, discipline_id = undefined, remove_expired = false, trail, all = false, tutor = false, student = false) {
    const data = { institution: institution_id }
    if (remove_expired) data.remove_expired = true
    if (all) data.all = true
    if (discipline_id) data.disciplines = [discipline_id]
    if (trail) data.trail = trail
    if (tutor) data.tutor = tutor
    if (student) data.student = student
    if (class_id) {
      data.id = class_id
      return BaseRequests.post(URLS.GET_CLASS, data)
        .then(response => response.data.class)
    } else {
      return BaseRequests.post(URLS.GET_CLASS, data)
        .then(response => response.data.classes)
    }
  }

  static update (institution, mClass) {
    // let cloning = true
    if ((!mClass.add && !mClass.remove && !mClass.edit) || mClass.cloning) {
      delete mClass.tutors
      delete mClass.students
    }

    const data = { institution, ...mClass }
    return BaseRequests.post(URLS.UPDATE_CLASS, data)
      .then(response => response.data)
  }

  static delete (institution_id, class_id) {
    const data = {
      institution: institution_id,
      id: class_id
    }
    return BaseRequests.post(URLS.DELETE_CLASS, data)
      .then(response => {
        if (response.data.not_deleted && response.data.not_deleted.length > 0) {
          throw (response.data.not_deleted)
        }
        return response.data.success
      })
  }
}

export default ClassRequests
