import { extendObservable } from 'mobx'
import ConfigActivitiesList from './ConfigActivitiesList'

export default class ResourceModel {
  constructor (data) {
    if (!data) return
    this.id = data.id
    this.name = data.name
    this.lesson_id = data.lesson_id
    this.pages_count = data.pages_count
    this.type = data.type
    this.status = data.status
    this.config = data.config ? new ConfigActivitiesList(data.config) : null
    extendObservable(this, { pages_viewed: 0 })
  }

  get percentage_complete () {
    return this.pages_count && this.pages_viewed ? (100 / this.pages_count) * this.pages_viewed : 0
  }
}
