import { extendObservable } from 'mobx'

import AuthStore from './AuthStore'
import RoadBarStore from './RoadBarStore'

import DisciplineRequests from '../services/requests/DisciplineRequests'
import ClassRequests from '../services/requests/ClassRequests'

import DisciplineModel from '../models/DisciplineModel'
import LessonsGroupModel from '../models/LessonsGroupModel'
import ClassModel from '../models/ClassModel'

import { filterList, themeConfig } from '../Util'

class DisciplineStore {
  constructor () {
    this.previous = null
    extendObservable(this, {
      disciplines: undefined,
      discipline: undefined,
      toAttachDisciplines: [],
      pagesDiscipline: {},
      clas: undefined,
      lessons_groups: undefined,
      loading: false,
      filter: ''
    })
  }

  reset () {
    this.disciplines = undefined
    this.discipline = undefined
    this.filter = ''
  }

  new (name) {
    this.loading = true
    return DisciplineRequests.new(AuthStore.institution_current.id, name)
      .then(res => (this.discipline = new DisciplineModel(res.discipline)))
      .finally(() => (this.loading = false))
  }

  get (discipline_id, all = false) {
    this.loading = true
    return DisciplineRequests.get(
      AuthStore.institution_current.id,
      discipline_id,
      false,
      1,
      11,
      undefined,
      false,
      undefined,
      all
    )
      .then(discipline => {
        this.discipline = new DisciplineModel(discipline)
        RoadBarStore.update()
        return discipline
      })
      .finally(() => (this.loading = false))
  }

  update (discipline) {
    this.loading = true
    return DisciplineRequests.update(
      AuthStore.institution_current.id,
      discipline
    )
      .then(data => {
        if (!data.success) {
          if (data.error === 100) return window.$('#modalInvalidCH').modal('toggle')
          if (data.error === 81) return window.$('#modalInvalidDisciplineName').modal('toggle')
        }

        this.get(discipline.id, true)
      })
      .finally(() => (this.loading = false))
  }

  delete (discipline_id) {
    this.loading = true
    return DisciplineRequests.delete(
      AuthStore.institution_current.id,
      discipline_id
    )
      .then(res => { this.getList(true); return res })
      .finally(() => (this.loading = false))
  }

  getList (all = false) {
    this.loading = true
    return DisciplineRequests.get(AuthStore.institution_current.id, false, false, 1, 11, undefined, false, undefined, all)
      .then(disciplines => {
        this.disciplines = disciplines.map(
          discipline => new DisciplineModel(discipline)
        )
      })
      .finally(() => (this.loading = false))
  }

  getToAttachList (search, page, all = false) {
    this.loading = true

    return DisciplineRequests.get(AuthStore.institution_current.id, false, true, page, 6, search, true, false, all)
      .then(response => {
        this.toAttachDisciplines = response.data.disciplines.map(
          discipline => new DisciplineModel(discipline)
        )
        this.pagesDiscipline = response.data.page
      })
      .finally(() => (this.loading = false))
  }

  getClass (class_id) {
    this.loading = true
    return ClassRequests.get(AuthStore.institution_current.id, class_id, false, false, false, true)
      .then(mClass => {
        this.clas = new ClassModel(mClass)
        RoadBarStore.update()
      })
      .finally((this.loading = false))
  }

  setLessonsGroup (lesson_group_id) {
    this.loading = true
    return DisciplineRequests.setLessonGroup(
      AuthStore.institution_current.id,
      this.discipline.id,
      lesson_group_id
    )
      .then(() => this.get(this.discipline.id, true))
      .finally(() => (this.loading = false))
  }

  getLessonsGroups () {
    this.loading = true
    return DisciplineRequests.getLessonsGroup(AuthStore.institution_current.id)
      .then(lessons_groups => {
        this.lessons_groups = lessons_groups.map(
          lessons => new LessonsGroupModel(lessons)
        )
      })
      .finally(() => (this.loading = false))
  }

  getTitle () {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) {
      // Nível 1
      return `Gestão ${themeConfig.terms.deDisciplinasC} e ${themeConfig.terms.turmasC}`
    }
    if (pathList.length === 2) {
      // Nível 2
      return this.discipline ? this.discipline.name : ''
    }
    if (pathList.length === 3) {
      // Nível 3
      return this.clas ? this.clas.name : ''
    }
    throw new Error(
      `${DisciplineStore.name} não está configurado para o caminho ${window.location.pathname}`
    )
  }

  getLinks (links) {
    const pathList = window.location.pathname.split('/').filter(x => x)
    if (pathList.length === 1) {
      // Nível 1
      return [{ title: themeConfig.terms.disciplinasC, address: `/${pathList[0]}/${window.location.search}` }]
    }
    if (pathList.length === 2) {
      // Nível 2
      return [
        { title: themeConfig.terms.disciplinasC, address: links[0] ? links[0].address : `/${pathList[0]}/` },
        {
          title: `${this.discipline ? this.discipline.name : themeConfig.terms.disciplinaC}`,
          address: `/${pathList[0]}/${pathList[1]}/${window.location.search}`
        }
      ]
    }
    if (pathList.length === 3) {
      // Nível 3
      return [
        { title: themeConfig.terms.disciplinasC, address: links[0] ? links[0].address : `/${pathList[0]}/` },
        {
          title: `${this.discipline ? this.discipline.name : themeConfig.terms.disciplinaC}`,
          address: links[1] ? links[1].address : `/${pathList[0]}/${pathList[1]}/`
        },
        {
          title: `${this.clas ? this.clas.name : 'Turma'}`,
          address: `/${pathList[0]}/${pathList[1]}/${pathList[2]}/${window.location.search}`
        }
      ]
    }
  }

  filterLessons () {
    if (this.lessons_groups) {
      return filterList(this.lessons_groups, this.filter, [
        'name',
        'owner.name'
      ])
    }
  }

  filterList (list = false) {
    list = list || this.disciplines
    return list.filter(
      discipline =>
        discipline.name
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            this.filter
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) !== -1
    )
  }

  isCuratorSelectable () {
    if (this.discipline) {
      const type = this.discipline.curatorship_type
      if (
        type &&
        type.active &&
        type.subTypes &&
        type.subTypes[0].active &&
        type.subTypes[0].style.style !== 'TE'
      ) {
        return true
      }
    }
    return false
  }
}

export default new DisciplineStore()
